import React, { useState, useEffect, useMemo, Fragment } from 'react'
import classNames from 'classnames'
// reactstrap components
import { Button, UncontrolledTooltip, Badge } from 'reactstrap'
import ReactTable from 'components/ReactTable/ReactTable.js'
import ProductTarget from './ProductTarget'
import apiProducts from 'services/api/Product'
import { useQueryClient } from 'react-query'
import useAlert from 'services/hooks/useAlert'
import { useHistory } from 'react-router-dom'
import { titleize, reactTableSortAndFilter } from 'services/Utils'

const ProductTargeting = ({ product }) => {
  const { addAlert } = useAlert()
  const queryClient = useQueryClient()
  const history = useHistory()

  const emptyProductTarget = {
    public_id: '',
    type: '',
    match_type: [
      {
        label: 'Exact',
        value: 'exact',
      },
      {
        label: 'Broad',
        value: 'broad',
      },
    ],
    target: '',
  }
  const [productTarget, setProductTarget] = useState(emptyProductTarget)
  const [showProductTargetForm, setShowProductTargetForm] = useState(false)

  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: 10,
  })
  const [productTargets, setProductTargets] = useState([])
  const [pages, setPages] = useState()
  const [total, setTotal] = useState()

  useEffect(() => {
    const [r, p, t] = reactTableSortAndFilter(
      product?.product_targets,
      queryParam
    )
    setProductTargets(r)
    setPages(p)
    setTotal(t)
  }, [product, queryParam])

  const deleteProductTarget = async (productTargetPublicId) => {
    // delete product target via api
    const response = await apiProducts
      .deleteSingleTarget(product.public_id, productTargetPublicId)
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      queryClient.invalidateQueries('GET/sellers/')
      addAlert('success', 'tim-icons icon-check-2', response.data.message, null)
    } else {
      if (response) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response.data.message,
          null
        )
      }
    }
  }

  const productTargetColumns = useMemo(
    () => [
      {
        Header: 'type',
        accessor: 'type',
        sortable: true,
        Cell: (props) => titleize(props.value),
      },
      {
        Header: 'target',
        accessor: 'target',
        sortable: true,
        gfilter: 1,
      },
      {
        Header: 'match type',
        accessor: 'match_type',
        sortable: false,
        Cell: (props) =>
          props.value && ['keyword'].includes(props.row.original['type'])
            ? props.value.split(',').map((p) => (
                <Badge
                  key={p}
                  className={`badge-pill-custom badge-primary-inverse`}
                  pill
                >
                  {p}
                </Badge>
              ))
            : ['category'].includes(props.row.original['type']) &&
              props.value?.includes('|')
            ? props.value
            : null,
      },
      {
        Header: 'actions',
        accessor: 'actions',
        sortable: false,
        Cell: (props) => (
          <Fragment>
            <div className="actions-right">
              <Button
                onClick={(e) => editClick(props.row, e)}
                id={'product_cost_table_edit_btn_' + props.row.index}
                color="warning"
                size="sm"
                className={classNames('btn-icon btn-link like', {
                  'btn-neutral': props.row.index < 0,
                })}
              >
                <i className="tim-icons icon-pencil" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target={'product_cost_table_edit_btn_' + props.row.index}
              >
                Edit
              </UncontrolledTooltip>{' '}
              <Button
                onClick={(e) =>
                  deleteProductTarget(props.row.original['public_id'])
                }
                id={'product_cost_table_delete_btn_' + props.row.index}
                color="danger"
                size="sm"
                className={classNames('btn-icon btn-link like', {
                  'btn-neutral': props.row.index < 0,
                })}
              >
                <i className="tim-icons icon-simple-remove" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target={'product_cost_table_delete_btn_' + props.row.index}
              >
                Delete
              </UncontrolledTooltip>
            </div>
          </Fragment>
        ),
      },
    ],
    []
  )

  const editClick = (row, e) => {
    if (!e.target.className.split(' ').includes('icon-simple-remove')) {
      // handle unintentional row click when clicking delete
      setProductTarget({
        public_id: row.original['public_id'], // note original
        type: {
          label: titleize(row.values['type']),
          value: row.values['type'],
        },
        match_type: row.values['match_type']
          ?.split(',')
          ?.map((mt) => ({ label: titleize(mt), value: mt })),
        target: row.values['target'],
      })
      setShowProductTargetForm(true)
    }
  }

  return (
    <>
      {showProductTargetForm ? (
        <ProductTarget
          productTarget={productTarget}
          productPublicId={product.public_id}
          setShowComponent={setShowProductTargetForm}
        />
      ) : (
        <Fragment>
          <p className="card-description mb-3">
            Optionally add keywords, competitor products and category targets to
            help Autron's AI learn faster. Start with less than ten core
            keywords for each product. Misspellings and plurals are not
            required.
          </p>
          {product.product_targets?.length > 0 ? (
            <ReactTable
              columns={productTargetColumns}
              data={productTargets}
              loading={false}
              total={total}
              pageCount={pages}
              queryParam={queryParam}
              setQueryParam={setQueryParam}
              onRowClick={(row, e) => editClick(row, e)}
            />
          ) : null}
          <Button
            className="btn-wd btn btn-just-icon"
            color="primary"
            onClick={(e) => {
              setProductTarget(emptyProductTarget)
              setShowProductTargetForm(true)
            }}
          >
            Add Target
          </Button>
          <Button
            color="primary"
            className={classNames('btn-simple')}
            onClick={(e) => history.push('/admin/products')}
          >
            Back
          </Button>
        </Fragment>
      )}
    </>
  )
}

export default ProductTargeting
