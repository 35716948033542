import React, { useState, useMemo, useEffect } from 'react'
import classNames from 'classnames'
import ReactBSAlert from 'react-bootstrap-sweetalert'
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Progress,
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  Spinner,
  UncontrolledTooltip,
  Row,
  Col,
} from 'reactstrap'
import ImageUpload from 'components/CustomUpload/ImageUpload.js'
import PhoneInput from 'react-phone-input-2'
import { inputFieldChange, sellerProfile } from 'services/Utils'
import apiAuth from 'services/api/Auth.js'
import apiUsers from 'services/api/User.js'
import { useQuery, useQueryClient } from 'react-query'
import useAlert from 'services/hooks/useAlert'
import { useHistory, Link } from 'react-router-dom'
import apiSellers from 'services/api/Seller'
import ReactTable from 'components/ReactTable/ReactTable.js'
import Billing from './Billing'
import { Fragment } from 'react'
import { copyTextToClipboard, managedSellerInviteLink } from 'services/Utils'
import { DateTime } from 'luxon'

const Account = () => {
  const queryClient = useQueryClient()
  const { data: user, isFetching: isLoading } = useQuery(
    'GET/auth/user',
    () => apiAuth.user(),
    {
      onSuccess: (data) => {
        setFirstname(user.firstname)
        setLastname(user.lastname)
        setCompany(user.company.name)
        setEmail(user.email)
        setPhone(user.phone)
        setPrimarySeller(user.primary_seller)
      },
    }
  )

  const { addAlert } = useAlert()
  const history = useHistory()

  const [accountTab, setAccountTab] = useState('')
  const [loading, setLoading] = useState(false)
  const [publicId] = useState(user.public_id)
  const [firstname, setFirstname] = useState(user.firstname)
  const [firstnameValidationState, setFirstnameValidationState] = useState(
    user.firstname ? 'has-success' : ''
  )
  const [lastname, setLastname] = useState(user.lastname)
  const [lastnameValidationState, setLastnameValidationState] = useState(
    user.lastname ? 'has-success' : ''
  )
  const [company, setCompany] = useState(user.company.name)
  const [companyValidationState, setCompanyValidationState] = useState(
    user.company ? 'has-success' : ''
  )
  const [email, setEmail] = useState(user.email)
  const [emailValidationState, setEmailValidationState] = useState(
    user.email ? 'has-success' : ''
  )
  const [phone, setPhone] = useState(user.phone)
  const [phoneValidationState, setPhoneValidationState] = useState(
    user.phone ? 'has-success' : ''
  )
  const [deleteSellerWarningAlert, setDeleteSellerWarningAlert] = useState(null)
  const [refreshSpinner, setRefreshSpinner] = useState(false)
  const [refreshSpinnerKey, setRefreshSpinnerKey] = useState()
  const [primarySeller, setPrimarySeller] = useState({})
  const [isCopied, setIsCopied] = useState(false)

  useEffect(() => {
    const tab = history.location.pathname.split('account/').pop().split('/')[0]
    if (tab === '') {
      setAccountTab('profile')
    } else {
      setAccountTab(tab)
    }
  }, [history.location.pathname])

  const saveClick = async (e) => {
    if (
      firstnameValidationState === 'has-success' &&
      lastnameValidationState === 'has-success' &&
      companyValidationState === 'has-success' &&
      emailValidationState === 'has-success' &&
      phoneValidationState === 'has-success'
    ) {
      setLoading(true)
      const user = {
        firstname: firstname,
        lastname: lastname,
        phone: phone,
      }
      // update user via api
      const response = await apiUsers
        .updateSingle(publicId, user)
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        queryClient.invalidateQueries('GET/auth/user')
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response.data.message,
            null
          )
        }
      }
      setLoading(false)
    } else {
      if (firstnameValidationState === '') {
        setFirstnameValidationState('has-danger')
      }
      if (lastnameValidationState === '') {
        setLastnameValidationState('has-danger')
      }
      if (companyValidationState === '') {
        setCompanyValidationState('has-danger')
      }
      if (emailValidationState === '') {
        setEmailValidationState('has-danger')
      }
      if (phoneValidationState === '') {
        setPhoneValidationState('has-danger')
      }
      e.preventDefault()
    }
  }

  const clickResetPassword = async (e) => {
    const response = await apiAuth
      .forgot_password(JSON.stringify({ email }))
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      addAlert('success', 'tim-icons icon-check-2', response.data.message, null)
    } else {
      if (response) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response.data.message,
          null
        )
      }
      e.preventDefault()
    }
  }

  const deleteSellerWarning = (seller) => {
    setDeleteSellerWarningAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => deleteSellerWarningConfirm(seller)}
        onCancel={() => deleteSellerWarningHideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        {'Deleting Amazon seller account "' +
          seller.name +
          '" cannot be undone.'}
      </ReactBSAlert>
    )
  }
  const deleteSellerWarningConfirm = async (seller) => {
    // delete seller via api
    const response = await apiSellers
      .deleteSingle(seller.public_id)
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      queryClient.invalidateQueries('GET/auth/user')
      addAlert('success', 'tim-icons icon-check-2', response.data.message, null)
    } else {
      if (response) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response.data.message,
          null
        )
      }
    }
    setDeleteSellerWarningAlert(null)
  }
  const deleteSellerWarningHideAlert = () => {
    setDeleteSellerWarningAlert(null)
  }

  const clickRefreshSeller = async (e, seller, k) => {
    setRefreshSpinner(true)
    setRefreshSpinnerKey(k)
    const response = await apiSellers
      .refresh(seller.public_id, {})
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      addAlert('success', 'tim-icons icon-check-2', response.data.message, null)
    } else {
      if (response) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response.data.message,
          null
        )
      }
      e.preventDefault()
    }
    setRefreshSpinner(false)
    setRefreshSpinnerKey(null)
  }

  const clickEditSeller = async (e, seller) => {
    if (seller.public_id === primarySeller.public_id) {
      history.push('/admin/onboarding/seller/' + seller.public_id)
    } else {
      const response = await apiSellers
        .primary(seller.public_id, seller)
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
        window.location.replace('/admin/onboarding/seller/' + seller.public_id) // ideally we would handle this with invalidateQueries
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response.data.message,
            null
          )
        }
        e.preventDefault()
      }
    }
  }

  const sellerCols = (bActions, bDate) => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        sortable: false,
      },
      {
        Header: 'Region',
        accessor: 'region',
        sortable: false,
      },
      {
        Header: 'Selling Partner',
        accessor: 'sp',
        sortable: false,
      },
      {
        Header: 'Amazon Ads',
        accessor: 'ads',
        sortable: false,
      },
      ...(bActions
        ? [
            {
              Header: 'Actions',
              accessor: 'actions',
              sortable: false,
            },
          ]
        : []),
      ...(bDate
        ? [
            {
              Header: 'Date Connected',
              accessor: 'ingest_completed_at',
              sortable: false,
              Cell: (props) =>
                props.value
                  ? new DateTime.fromISO(props.value)
                      .setLocale('en-US')
                      .toLocaleString(DateTime.DATE_MED)
                  : null,
            },
          ]
        : []),
    ]
  }

  const sellerColumns = useMemo(() => sellerCols(true, false), [])
  const managedSellerColumns = useMemo(() => sellerCols(false, true), [])

  const sellerData = (sellers) =>
    sellers.map((seller, key) => {
      return {
        id: key,
        name: seller.name,
        region: seller.marketplace_region,
        sp: seller?.spapi_access ? 'Connected' : 'Not Connected',
        ads: sellerProfile(seller)?.adapi_access
          ? 'Connected'
          : 'Not Connected',
        actions: (
          <div className="actions-right">
            <Button
              onClick={(e) => clickEditSeller(e, sellers[key])}
              id={'seller_table_edit_btn_' + key}
              color="warning"
              size="sm"
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': key < 0,
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target={'seller_table_edit_btn_' + key}
            >
              Edit
            </UncontrolledTooltip>
            {sellers[key]?.spapi_access ? (
              <Fragment>
                {' '}
                <Button
                  onClick={(e) => clickRefreshSeller(e, sellers[key], key)}
                  id={'seller_table_refresh_btn_' + key}
                  color="success"
                  size="sm"
                  className={classNames('btn-icon btn-link like', {
                    'btn-neutral': key < 0,
                    'icn-spinner': refreshSpinner && refreshSpinnerKey === key,
                  })}
                >
                  <i className="tim-icons icon-refresh-01" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={'seller_table_refresh_btn_' + key}
                >
                  Refresh
                </UncontrolledTooltip>
              </Fragment>
            ) : null}
            {!sellers[key]?.spapi_access ? (
              <Fragment>
                {' '}
                <Button
                  onClick={(e) => deleteSellerWarning(sellers[key])}
                  id={'seller_table_delete_btn_' + key}
                  color="danger"
                  size="sm"
                  className={classNames('btn-icon btn-link like', {
                    'btn-neutral': key < 0,
                  })}
                >
                  <i className="tim-icons icon-simple-remove" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={'seller_table_delete_btn_' + key}
                >
                  Delete
                </UncontrolledTooltip>
              </Fragment>
            ) : null}
          </div>
        ),
        ingest_completed_at: seller?.ingest_completed_at,
      }
    })

  const connectAccountClick = async (e) => {
    history.push('/admin/onboarding/seller/')
  }

  return (
    <>
      <div className="content">
        {deleteSellerWarningAlert}
        <Row>
          <Col lg="4" md="4">
            <Card>
              {/* User Information */}
              <CardHeader className="text-center">
                <ImageUpload avatar addBtnColor="default" />
                <h3 className="title">{firstname + ' ' + lastname}</h3>
              </CardHeader>
              {/* User Information */}
              {/* Account Sidebar */}
              <CardBody>
                <br />
                <Nav
                  className="nav-pills-account nav-pills-info flex-column"
                  pills
                >
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: accountTab === 'profile',
                      })}
                      tag={Link}
                      to="/admin/account"
                    >
                      <i className="tim-icons icon-single-02" />
                      Profile
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: accountTab === 'billing',
                      })}
                      tag={Link}
                      to="/admin/account/billing"
                    >
                      <i className="tim-icons icon-credit-card" />
                      Billing
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: accountTab === 'amazon-settings',
                      })}
                      tag={Link}
                      to="/admin/account/amazon-settings"
                    >
                      <i className="fab fa-amazon" />
                      Amazon Settings
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
            </Card>
          </Col>
          <Col className="ml-auto" lg="8" md="8">
            <TabContent activeTab={'account-' + accountTab}>
              <TabPane tabId="account-profile">
                <Form className="form-horizontal" id="TypeValidation">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h4">Profile</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Label sm="2">First Name</Label>
                        <Col sm="10">
                          <FormGroup className={firstnameValidationState}>
                            <Input
                              name="firstname"
                              type="text"
                              defaultValue={firstname}
                              onChange={(e) =>
                                inputFieldChange(
                                  e,
                                  setFirstname,
                                  setFirstnameValidationState,
                                  'length',
                                  1
                                )
                              }
                            />
                            {firstnameValidationState === 'has-danger' ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Last Name</Label>
                        <Col sm="10">
                          <FormGroup className={lastnameValidationState}>
                            <Input
                              name="lastname"
                              type="text"
                              defaultValue={lastname}
                              onChange={(e) =>
                                inputFieldChange(
                                  e,
                                  setLastname,
                                  setLastnameValidationState,
                                  'length',
                                  1
                                )
                              }
                            />
                            {lastnameValidationState === 'has-danger' ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Company</Label>
                        <Col sm="10">
                          <FormGroup className={companyValidationState}>
                            <Input
                              disabled
                              name="company"
                              type="text"
                              defaultValue={company}
                              onChange={(e) =>
                                inputFieldChange(
                                  e,
                                  setCompany,
                                  setCompanyValidationState,
                                  'length',
                                  1
                                )
                              }
                            />
                            {companyValidationState === 'has-danger' ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Email</Label>
                        <Col sm="10">
                          <FormGroup className={emailValidationState}>
                            <Input
                              disabled
                              name="email"
                              type="email"
                              defaultValue={email}
                              onChange={(e) =>
                                inputFieldChange(
                                  e,
                                  setEmail,
                                  setEmailValidationState,
                                  'email'
                                )
                              }
                            />
                            {emailValidationState === 'has-danger' ? (
                              <label className="error">
                                Please enter a valid email address.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Phone</Label>
                        <Col sm="10">
                          <FormGroup className={phoneValidationState}>
                            <PhoneInput
                              specialLabel=""
                              country={'us'}
                              value={phone}
                              onChange={(e) =>
                                inputFieldChange(
                                  '+' + e,
                                  setPhone,
                                  setPhoneValidationState,
                                  'phone',
                                  7
                                )
                              }
                            />
                            {phoneValidationState === 'has-danger' ? (
                              <label className="error">
                                This field is required.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Password</Label>
                        <Col sm="10">
                          <FormGroup>
                            <p
                              className="form-control-static div-link"
                              onClick={(e) => clickResetPassword(e)}
                            >
                              Reset your password
                            </p>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Label sm="2"></Label>
                        <Col sm="10">
                          <Button
                            color="primary"
                            className={classNames('btn-wd', {
                              disabled: loading,
                            })}
                            onClick={(e) => saveClick(e)}
                          >
                            {loading === true ? (
                              <Spinner size="sm" role="status"></Spinner>
                            ) : (
                              'Save Settings'
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Form>
              </TabPane>
              <TabPane tabId="account-billing">
                <Billing user={user} />
              </TabPane>
              <TabPane tabId="account-amazon-settings">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Amazon Seller Accounts</CardTitle>
                  </CardHeader>
                  <CardBody>
                    {user.company.sellers.length > 0 ? (
                      <ReactTable
                        columns={sellerColumns}
                        data={sellerData(user.company.sellers)}
                        loading={user ? false : true}
                        total={user.company.sellers.length}
                      />
                    ) : null}
                  </CardBody>
                  <CardFooter>
                    {user.company.sellers.length > 0 ? (
                      <div>
                        <Button
                          className="btn-wd btn-just-icon"
                          color="primary"
                          onClick={(e) => connectAccountClick(e)}
                        >
                          Add New
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <p className="mb-3">
                          Get started by connecting your Amazon account.
                        </p>
                        <Button
                          className="btn-wd btn-round btn-just-icon"
                          color="primary"
                          onClick={(e) => connectAccountClick(e)}
                        >
                          Connect Amazon Account
                        </Button>
                      </div>
                    )}
                  </CardFooter>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Managed Amazon Seller Accounts
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {user.company.managed_sellers.length > 0 ? (
                      <ReactTable
                        columns={managedSellerColumns}
                        data={sellerData(user.company.managed_sellers)}
                        loading={user ? false : true}
                        total={user.company.managed_sellers.length}
                      />
                    ) : null}
                  </CardBody>
                  <CardFooter>
                    {user.company.managed_sellers.length > 0 ? (
                      <div>
                        <Button
                          className="btn-wd btn-just-icon btn-simple"
                          color="primary"
                          onClick={(e) => {
                            copyTextToClipboard(
                              managedSellerInviteLink(
                                user.company.public_id,
                                user.company.name
                              )
                            )
                            setIsCopied(true)
                          }}
                        >
                          Copy Seller Invite Link
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <p className="mb-3">
                          Looking to manage someone else's Amazon Ad account
                          using Autron?
                        </p>
                        <Button
                          className="btn-wd btn-round btn-just-icon btn-simple"
                          color="primary"
                          onClick={(e) => {
                            copyTextToClipboard(
                              managedSellerInviteLink(
                                user.company.public_id,
                                user.company.name
                              )
                            )
                            setIsCopied(true)
                          }}
                        >
                          Copy Invite Link
                        </Button>
                        {/* <span>{isCopied ? ' Copied!' : null}</span> */}
                      </div>
                    )}
                  </CardFooter>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Account
