import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import Select from 'react-select'
// reactstrap components
import {
  Row,
  Col,
  CardTitle,
  InputGroup,
  Button,
  UncontrolledTooltip,
  Form,
  Label,
} from 'reactstrap'
import UploadThumbnailGallery from 'components/CustomUpload/UploadThumbnailGallery'
import { inputFieldChange } from 'services/Utils'

const BrandCreatives = ({
  brands,
  brandUpdate,
  uploadingLogo,
  updatingLogo,
}) => {
  const [brand, setBrand] = useState({ value: '', label: '' })
  const [brandValidation, setBrandValidation] = useState('')
  const [brandFocus, setBrandFocus] = useState(false)
  const [logos, setLogos] = useState({})

  const handleBrandChange = (selectedBrand) => {
    const brand = brands.find((b) => b.brand_entity_id === selectedBrand.value)
    setLogos(
      brand?.store_assets
        .filter((sa) => sa.media_type === 'brandLogo')
        .map((logo) => ({
          action: 'update_brand_logo',
          brand_entity_id: logo.brand_brand_entity_id,
          url: logo.url,
          picked: brand.logo_url === logo.url,
        }))
    )
  }

  useEffect(() => {
    if (brands?.length > 0) {
      const b = {
        value: brands[0]?.brand_entity_id,
        label: brands[0]?.brand_registry_name,
      }
      setBrand(b)
      setBrandValidation(b ? 'has-success' : 'has-danger')
      handleBrandChange(b)
    }
  }, [brands])

  const onPick = (asset) => {
    brandUpdate(asset)
  }

  const onUpload = (file) => {
    brandUpdate({
      action: 'upload_brand_logo',
      brand_entity_id: brand.value,
      file: file,
    })
  }

  return (
    <>
      <Row className="mb-3">
        <Col sm="8">
          <CardTitle tag="h4">Branding</CardTitle>
          <p className="card-description mb-2">
            Select a registered brand and add your awesome logo.
          </p>
        </Col>
      </Row>
      <Form className="form-horizontal">
        <Row className="align-items-center">
          <Label sm="3" className="pt-0 mt-n1">
            Brand
            <Button
              color="link"
              id="brand-label-tooltip"
              title=""
              type="button"
              className="p-0 m-0 mt-n1 pl-2"
            >
              <i className="fas fa-question-circle"></i>
            </Button>
            <UncontrolledTooltip delay={0} target="brand-label-tooltip">
              Your brand must be enrolled in Amazon Brand Registry for it to
              appear here.
            </UncontrolledTooltip>
          </Label>
          <Col sm="9" md="6">
            <InputGroup
              className={classNames(brandValidation, {
                'input-group-focus': brandFocus,
              })}
            >
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="brand"
                value={brand}
                placeholder=""
                onChange={(e) => {
                  inputFieldChange(
                    e,
                    setBrand,
                    setBrandValidation,
                    'select_length',
                    1
                  )
                  handleBrandChange(e)
                }}
                options={brands?.map((b) => ({
                  value: b.brand_entity_id,
                  label: b.brand_registry_name,
                }))}
                onFocus={(e) => setBrandFocus(true)}
                onBlur={(e) => setBrandFocus(false)}
              />
              {brandValidation === 'has-danger' ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </Col>
        </Row>
        {brand?.value ? (
          <Row className="align-items-center">
            <Label sm="3" className="pt-0 mt-n1">
              Logo
              <Button
                color="link"
                id="logo-label-tooltip"
                title=""
                type="button"
                className="p-0 m-0 mt-n1 pl-2"
              >
                <i className="fas fa-question-circle"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="logo-label-tooltip">
                <strong>Logo requirements</strong>
                <br />
                - Dimensions are at least 400 x 400 px
                <br />
                - File size is smaller than 1MB
                <br />
                - File format is PNG, JPEG, or GIF
                <br />- Logo needs to fill the image or have a white or
                transparent background
              </UncontrolledTooltip>
            </Label>
            <Col sm="9" md="6">
              <UploadThumbnailGallery
                assets={logos}
                assetType={'image'}
                onPick={onPick}
                onUpload={onUpload}
                uploading={uploadingLogo}
                updating={updatingLogo}
              />
            </Col>
          </Row>
        ) : null}
      </Form>
    </>
  )
}

export default BrandCreatives
