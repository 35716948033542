import React from 'react'
import ContentLoader from 'react-content-loader'

const GeneralLogoLoader = (props) => {
  const theme = localStorage.getItem('theme')
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={400}
      viewBox="-1000 -1000 2500 2500"
      backgroundOpacity={0}
      foregroundColor={`${theme === 'light' ? '#000000' : '#ffffff'}`}
      foregroundOpacity={0.1}
      {...props}
    >
      <path d="M503.4963,380.5046h-6.3369q-57.4061,0-114.8121.0062c-16.5915.0086-28.9763-7.0981-37.27-21.5251q-28.4509-49.4905-57.0483-98.8969c-.9009-1.5607-1.8691-3.0825-3.2121-5.29-1.2218,2.0377-2.1336,3.5109-3,5.01q-26.9457,46.6344-53.8707,93.2808c-10.3831,18.03-25.8877,26.9955-46.7019,26.9694q-55.6587-.07-111.3173.0838c-2.9777.0109-4.37-1.0319-5.7656-3.4614Q36.72,328.9117,9.0993,281.2452q-15.691-27.1922-.017-54.4334Q66.2344,127.4034,123.3906,27.9972c10.2636-17.8349,25.86-26.713,46.3721-26.7763,30.117-.0929,60.2346-.0168,90.352-.0268C276.5,1.1887,288.8464,8.18,297.06,22.4044Q398.8987,198.7783,500.7346,375.1537C501.551,376.5676,502.2378,378.0563,503.4963,380.5046ZM67.2419,127.8685c.8672,1.8321,1.33,3.018,1.9589,4.1084q34.2258,59.3576,68.4155,118.7358c1.5715,2.7384,3.4028,3.4453,6.36,3.44q67.8523-.1218,135.705-.0731c1.3543-.0006,2.7084-.2452,4.4241-.411-.7854-1.4457-1.2348-2.3192-1.7251-3.1691q-34.362-59.5578-68.6463-119.16c-1.7265-3.0141-3.7734-3.5191-6.8071-3.5148q-67.104.0956-134.2082.0438Z" />
    </ContentLoader>
  )
}

export default GeneralLogoLoader
