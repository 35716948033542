import React, {
  useState,
  useEffect,
  useMemo,
  Fragment,
  useRef,
  useCallback,
} from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import classNames from 'classnames'
import Select from 'react-select'
// reactstrap components
import {
  Row,
  Col,
  CardTitle,
  NavLink,
  Button,
  UncontrolledTooltip,
  Card,
  CardBody,
  Badge,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap'
import ReactTable from 'components/ReactTable/ReactTable'
import VideoMedia from './VideoMedia'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import useAlert from 'services/hooks/useAlert'

const VideoCreatives = ({
  brands,
  products,
  failedSpec,
  mediaUpdate,
  uploadingMedia,
  updatingMedia,
  failedSpecUpdate
}) => {
  const history = useHistory()
  const { addAlert } = useAlert()
  const params = useParams()

  const [video, setVideo] = useState()
  const [videoMeta, setVideoMeta] = useState({})
  const [allVideoData, setAllVideoData] = useState([])
  const [showVideoForm, setShowVideoForm] = useState(false)
  const [actionsCellWidth, setActionsCellWidth] = useState(0)

  const [total, setTotal] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: 5,
  })

  const [archiveVideoWarningAlert, setArchiveVideoWarningAlert] = useState(null)
  const [tableLoading, setTableLoading] = useState(false)

  useEffect(() => {
    setShowVideoForm(params.public_id ? true : false)
    if (params.public_id !== 'create') {
      setVideo(allVideoData.find((v) => v.public_id === params.public_id))
    }
  }, [params, allVideoData])

  const actionsCellRef = useCallback((domNode) => {
    if (domNode) {
      setActionsCellWidth(domNode.getBoundingClientRect().width)
    }
  }, [])

  const productsCell = (asins) => {
    const displayNProducts = 3
    const allASINs = products.map((p) => p.value)
    const intersection = allASINs.filter((e) => asins.includes(e))
    if (intersection.length === allASINs.length) {
      return ['all products']
    } else {
      if (intersection.length > displayNProducts) {
        return intersection
          .slice(0, displayNProducts)
          .concat([
            '+ ' +
            (intersection.length - displayNProducts).toString() +
            ' more',
          ])
      } else {
        return intersection
      }
    }
  }

  const archiveVideoWarning = (video) => {
    setArchiveVideoWarningAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => archiveVideoWarningConfirm(video)}
        onCancel={() => setArchiveVideoWarningAlert(null)}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, archive it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        {'Archiving video "' +
          video?.filename +
          '" cannot be undone. All ads using this video will be stopped.'}
      </ReactBSAlert>
    )
  }
  const archiveVideoWarningConfirm = (video) => {
    // archive video via api
    mediaUpdate({
      brand_entity_id: video?.brand_id,
      action: 'update_brand_media',
      media_id: video?.media_id,
      creative_asset_id: video?.creative_asset_id,
      state: 'archived',
    })
    setTableLoading(true)
    setArchiveVideoWarningAlert(null)
  }

  const editVideoClick = (row) => {
    if (videoData) {
      setVideo(videoData.find((v) => v.media_id === row.original['media_id']))
      history.push('/admin/creatives/video/' + row.original['public_id'])
    }
  }

  const videoCols = useMemo(
    () => [
      {
        Header: 'video',
        accessor: 'published_media_url',
        Cell: (props) => (
          <Card className="card-plain m-0">
            <CardBody className="p-0">
              <div className="video-container-16-9">
                {props.row.original['filename'].slice(-4) === '.mov' ? (
                  <div className="video-overlay text-center">
                    <label>
                      No preview
                      <br /> available for .mov
                    </label>
                  </div>
                ) : props.row.original['status'] !== 'Available' ? (
                  <div className="video-overlay text-center">
                    <label>
                      Preview available
                      <br /> once approved
                    </label>
                  </div>
                ) : null}
                <video
                  controls={true}
                  muted
                  autoPlay={false}
                  src={props.value}
                  className="video-player-table rounded-sm" //img-raised
                  controlsList="nodownload"
                  onLoadedMetadata={(e) => {
                    if (videoMeta.videoHeight === undefined) {
                      // seems dodge
                      setVideoMeta({
                        videoHeight: e.target.videoHeight,
                        videoWidth: e.target.videoWidth,
                        duration: e.target.duration,
                      })
                    }
                  }}
                />
              </div>
            </CardBody>
          </Card>
        ),
      },
      {
        Header: 'filename',
        accessor: 'filename',
        Cell: (props) => (
          <ul className="list-inline mb-0">
            <li className="text-table">{props.value}</li>
            {videoMeta.videoHeight ? (
              <li className="text-muted">
                {videoMeta.videoHeight}p ·{' '}
                {new Date(videoMeta.duration * 1000)
                  .toISOString()
                  .substring(15, 19)}
              </li>
            ) : null}
          </ul>
        ),
      },
      {
        Header: 'amazon approved',
        accessor: 'status',
        Cell: (props) => (
          <Fragment>
            <span
              className={classNames(
                'status_cell',
                props.value === 'Available'
                  ? 'status-success'
                  : props.value === 'Failed'
                    ? 'status-danger'
                    : 'status-warning'
              )}
            >
              {props.value === 'Available'
                ? 'Approved'
                : props.value === 'Failed'
                  ? 'Failed'
                  : 'Pending'}
            </span>
            {props.value === 'Failed' ? (
              <Fragment>
                <Button
                  color="link"
                  id={'media_amazon_approved_status_meta_' + props.row.index}
                  title=""
                  type="button"
                  className="p-0 m-0 mt-n1 pl-2"
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={
                    'media_amazon_approved_status_meta_' + props.row.index
                  }
                  className="no-pointer-events"
                  placement="bottom"
                >
                  Failure reason: {props.row.original['status_meta_code']}{' '}
                  {props.row.original['status_meta_message']}
                </UncontrolledTooltip>
              </Fragment>
            ) : null}
          </Fragment>
        ),
      },
      {
        Header: 'brand',
        accessor: 'brand',
      },
      {
        Header: 'associated products',
        accessor: 'product_asins',
        Cell: (props) =>
          productsCell(props.value)?.map((p, idx) => (
            <Badge
              key={idx}
              className={`badge-pill-custom badge-primary-${props.row.index % 2 ? 'states-' : ''
                }inverse`}
              pill
            >
              {p}
            </Badge>
          )),
      },
      {
        Header: 'actions',
        Cell: (props) => (
          <Fragment>
            <div ref={actionsCellRef} className="actions-right">
              {actionsCellWidth > 60 ? (
                <Fragment>
                  <Button
                    onClick={(e) => editVideoClick(props.row)}
                    id={'video_table_edit_btn_' + props.row.index}
                    color="warning"
                    size="sm"
                    className={classNames('btn-icon btn-link like', {
                      'btn-neutral': props.row.index < 0,
                    })}
                  >
                    <i className="tim-icons icon-pencil" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target={'video_table_edit_btn_' + props.row.index}
                  >
                    Edit
                  </UncontrolledTooltip>{' '}
                  <Button
                    onClick={(e) => archiveVideoWarning(props.row.original)}
                    id={'video_table_archive_btn_' + props.row.index}
                    color="danger"
                    size="sm"
                    className={classNames('btn-icon btn-link like', {
                      'btn-neutral': props.row.index < 0,
                    })}
                  >
                    <i className="tim-icons icon-simple-remove" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target={'video_table_archive_btn_' + props.row.index}
                  >
                    Archive
                  </UncontrolledTooltip>
                </Fragment>
              ) : (
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    className="btn-icon"
                    color="link"
                    data-toggle="dropdown"
                    type="button"
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      onClick={() => editVideoClick(props.row)}
                      className="very-compact"
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => archiveVideoWarning(props.row.original)}
                      className="very-compact"
                    >
                      Archive
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          </Fragment>
        ),
      },
    ],
    [allVideoData, videoMeta, products]
  )

  function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size)
  }

  const videoData = useMemo(() => {
    setTotal(allVideoData.length)
    setPageCount(Math.ceil(allVideoData?.length / queryParam.per_page))
    return paginate(allVideoData, queryParam.per_page, queryParam.page)
  }, [allVideoData, queryParam])

  useEffect(() => {
    if (brands) {
      const videos = brands.reduce(
        (results, brand) => [
          ...results,
          ...brand.media.map((m) => ({
            public_id: m.public_id,
            media_id: m.media_id,
            creative_asset_id: m.creative_asset_id,
            published_media_url: m.published_media_url,
            filename: m.filename,
            brand_id: brand.brand_entity_id,
            brand: brand.brand_registry_name,
            product_asins: m.product_asins,
            status: m.status,
            status_meta_code: m.status_meta_code,
            status_meta_message: m.status_meta_message,
          })),
        ],
        []
      )
      setAllVideoData(videos)
    }
  }, [brands])

  return (
    <>
      {archiveVideoWarningAlert}
      <Row className="mb-3">
        <Col sm="8">
          <CardTitle tag="h4">Video</CardTitle>
          <p className="card-description mb-2">
            Keep your videos brief and relevant. They will autoplay, so make
            sure the first 2 seconds are highly engaging!{' '}
            <NavLink
              className="d-inline p-0 div-link"
              tag={Link}
              to={{
                pathname:
                  'https://advertising.amazon.com/resources/ad-policy/sponsored-ads-policies#sbv',
              }}
              target="_blank"
            >
              See video approval tips.
            </NavLink>
          </p>
        </Col>
      </Row>
      {showVideoForm ? (
        <VideoMedia
          brands={brands}
          products={products}
          failedSpec={failedSpec}
          video={video}
          mediaUpdate={mediaUpdate}
          uploadingMedia={uploadingMedia}
          updatingMedia={updatingMedia}
          failedSpecUpdate={failedSpecUpdate}
        />
      ) : (
        <Fragment>
          <Button
            className="btn-wd btn btn-just-icon mb-5"
            color="primary"
            onClick={(e) => {
              setVideo({})
              history.push('/admin/creatives/video/create')
            }}
          >
            Add Video
          </Button>
          {videoData.length > 0 ? (
            <ReactTable
              columns={videoCols}
              data={videoData}
              loading={tableLoading}
              total={total}
              pageCount={pageCount}
              queryParam={queryParam}
              setQueryParam={setQueryParam}
              onRowClick={null} //{(row, e) => editVideoClick(row)}
              showGlobalSearch={false}
            />
          ) : null}
        </Fragment>
      )}
    </>
  )
}

export default VideoCreatives
