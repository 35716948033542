import React, { useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
// reactstrap components
import {
  Spinner,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardSubtitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Form,
  Col,
  Row,
} from 'reactstrap'
import apiAuth from 'services/api/Auth'
import { inputFieldChange } from 'services/Utils'
import { Link } from 'react-router-dom'
import useAlert from 'services/hooks/useAlert'

const ForgotPassword = () => {
  const { addAlert } = useAlert()
  const btnRef = useRef()

  const [email, setEmail] = useState('')
  const [emailFocus, setEmailFocus] = useState('')
  const [emailValidationState, setEmailValidationState] = useState('')
  const [loading, setLoading] = useState(false)

  const continueClick = async (e) => {
    if (emailValidationState === 'has-success') {
      const auth = { email }
      setLoading(true)
      const response = await apiAuth
        .forgot_password(JSON.stringify(auth))
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response.data.message,
            null
          )
        }
      }
      setLoading(false)
    } else {
      if (emailValidationState === '') {
        setEmailValidationState('has-danger')
      }
      e.preventDefault()
    }
  }

  useEffect(() => {
    document.body.classList.toggle('login-page')
    return () => {
      document.body.classList.toggle('login-page')
    }
  }, [])

  return (
    <>
      <div className="content">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form className="form form-auth">
                <Card className="card-lock card-white card-forgot-password">
                  <CardHeader>
                    <CardTitle tag="h3">Forgot your password?</CardTitle>
                    <CardSubtitle tag="p">
                      Enter the email address associated with your account, and
                      we'll send you a link to reset your password.
                    </CardSubtitle>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={classnames(emailValidationState, {
                        'input-group-focus': emailFocus,
                      })}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="email"
                        onFocus={(e) => setEmailFocus(true)}
                        onBlur={(e) => setEmailFocus(false)}
                        onChange={(e) =>
                          inputFieldChange(
                            e,
                            setEmail,
                            setEmailValidationState,
                            'email'
                          )
                        }
                      />
                      {emailValidationState === 'has-danger' ? (
                        <label className="error">
                          Please enter a valid email address.
                        </label>
                      ) : null}
                    </InputGroup>
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className={classnames('mb-3', { disabled: loading })}
                      color="primary"
                      onClick={(e) => continueClick(e)}
                      size="lg"
                      ref={btnRef}
                    >
                      {loading === true ? (
                        <Spinner size="sm" role="status"></Spinner>
                      ) : (
                        'Send Reset Link'
                      )}
                    </Button>
                    <div className="pull-left">
                      <h6>
                        <Link className="link footer-link" to="/auth/login">
                          Return to Login
                        </Link>
                      </h6>
                    </div>
                    <div className="pull-right">
                      <h6>
                        <Link className="link footer-link" to="/auth/register">
                          Create Account
                        </Link>
                      </h6>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ForgotPassword
