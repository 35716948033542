import React from 'react'
import { Link } from 'react-router-dom'

// reactstrap components
import { NavLink, Container, Row, Col, Card, CardBody } from 'reactstrap'

const getYear = () => {
  return new Date().getFullYear()
}

const MainFooter = (props) => {
  return (
    <>
      <footer className="footer text-left mt-5">
        <Container fluid={props.fluid ? true : false}>
          <Row>
            <Col md="3" xs="6" className="pl-0 pr-0">
              <Card className="bg-transparent shadow-none">
                <CardBody className="py-1 nav-item">
                  <h6 className="title">company</h6>
                  <NavLink
                    href={
                      process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/about'
                    }
                    className="card-category text-muted"
                  >
                    about us
                  </NavLink>
                </CardBody>
              </Card>
            </Col>
            <Col md="3" xs="6" className="pl-0 pr-0">
              <Card className="bg-transparent shadow-none">
                <CardBody className="py-1 nav-item">
                  <h6 className="title">resources</h6>
                  <NavLink
                    href={process.env.REACT_APP_HELP_CENTER_URL}
                    target="_blank"
                    className="card-category text-muted"
                  >
                    help center
                  </NavLink>
                  <NavLink
                    href={
                      process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/privacy'
                    }
                    className="card-category text-muted"
                  >
                    privacy policy
                  </NavLink>
                  <NavLink
                    href={
                      process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/terms'
                    }
                    className="card-category text-muted"
                  >
                    terms of service
                  </NavLink>
                </CardBody>
              </Card>
            </Col>
            <Col md="3" xs="6" className="pl-0 pr-0">
              <Card className="bg-transparent shadow-none">
                <CardBody className="py-1 nav-item">
                  <h6 className="title">follow us</h6>
                  <NavLink
                    href="https://www.facebook.com/autronio"
                    target="_blank"
                    className="card-category text-muted"
                  >
                    facebook
                  </NavLink>
                  <NavLink
                    href="https://twitter.com/autronio"
                    target="_blank"
                    className="card-category text-muted"
                  >
                    twitter
                  </NavLink>
                  <NavLink
                    href="https://www.linkedin.com/company/autron"
                    target="_blank"
                    className="card-category text-muted"
                  >
                    linkedin
                  </NavLink>
                </CardBody>
              </Card>
            </Col>
            <Col md="3" xs="6" className="pl-0 pr-0">
              <Card className="bg-transparent shadow-none">
                <CardBody className="py-1 nav-item">
                  <h6 className="title">contact</h6>
                  <NavLink
                    href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                    target="_blank"
                    className="card-category text-lowercase text-muted"
                  >
                    {process.env.REACT_APP_SUPPORT_EMAIL}
                  </NavLink>
                  <NavLink
                    href="#"
                    className="card-category text-capitalize display-linebreak text-muted"
                  >
                    {'100 California St\n San Francisco, CA\n94111'}
                  </NavLink>
                  <NavLink
                    href="#"
                    className="card-category text-capitalize text-muted"
                  >
                    © {getYear()} Autron, Inc.
                  </NavLink>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default MainFooter
