import React, { useEffect, useRef } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import MainNavbar from 'components/Navbars/MainNavbar.js'
import MainFooter from 'components/Footer/MainFooter'

import routes from 'routes.js'

const Pages = () => {
  const fullPages = useRef()

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }
  const getFullPageName = (routes) => {
    let pageName = getActiveRoute(routes)
    switch (pageName) {
      case 'Login':
        return 'login-page'
      case 'Register':
        return 'register-page'
      default:
        return 'Default Brand Text'
    }
  }
  useEffect(() => {
    document.documentElement.classList.remove('nav-open')
    return () => {
      document.documentElement.classList.remove('nav-open')
    }
  }, [])

  return (
    <>
      <MainNavbar />
      <div className="auth wrapper wrapper-full-page" ref={fullPages}>
        <div className={'full-page ' + getFullPageName(routes)}>
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/auth/login" />
          </Switch>
          <MainFooter />
        </div>
      </div>
    </>
  )
}

export default Pages
