import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import classnames from 'classnames'
// reactstrap components
import {
  Spinner,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Form,
  Col,
} from 'reactstrap'
import apiAuth from 'services/api/Auth'
import { inputFieldChange } from 'services/Utils'
import { setAuthToken } from 'variables/authToken'
import useAlert from 'services/hooks/useAlert'

const ForgotPassword = () => {
  const history = useHistory()
  const btnRef = useRef()
  const { addAlert } = useAlert()

  const [password, setPassword] = useState('')
  const [passwordFocus, setPasswordFocus] = useState('')
  const [passwordValidationState, setPasswordValidationState] = useState('')
  const [, setConfirmPassword] = useState('')
  const [confirmPasswordFocus, setConfirmPasswordFocus] = useState('')
  const [confirmPasswordValidationState, setConfirmPasswordValidationState] =
    useState('')
  const [loading, setLoading] = useState(false)
  const params = new URLSearchParams(useLocation().search)
  const [authToken] = useState(params.get('auth_token'))

  const continueClick = async (e) => {
    if (
      passwordValidationState === 'has-success' &&
      confirmPasswordValidationState === 'has-success' &&
      authToken
    ) {
      const auth = { password }
      setLoading(true)
      setAuthToken('Bearer ' + authToken)
      // login via api
      const response = await apiAuth
        .reset_password(JSON.stringify(auth))
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
        history.push('/auth/login')
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response.data.message,
            null
          )
        }
      }
      setLoading(false)
    } else {
      if (passwordValidationState === '') {
        setPasswordValidationState('has-danger')
      }
      if (confirmPasswordValidationState === '') {
        setConfirmPasswordValidationState('has-danger')
      }
      if (!authToken) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          'Please enter your new password directly from the link in the email.',
          null
        )
      }
      e.preventDefault()
    }
  }

  useEffect(() => {
    document.body.classList.toggle('login-page')
    return () => {
      document.body.classList.toggle('login-page')
    }
  }, [])

  return (
    <>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form form-auth">
              <Card className="card-lock card-white card-forgot-password">
                <CardHeader>
                  <CardTitle tag="h3">Enter a new password</CardTitle>
                </CardHeader>
                <CardBody>
                  <InputGroup
                    className={classnames(passwordValidationState, {
                      'input-group-focus': passwordFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="New password"
                      type="password"
                      autoComplete="new-password"
                      onFocus={(e) => setPasswordFocus(true)}
                      onBlur={(e) => setPasswordFocus(false)}
                      onChange={(e) =>
                        inputFieldChange(
                          e,
                          setPassword,
                          setPasswordValidationState,
                          'password',
                          10
                        )
                      }
                    />
                    {passwordValidationState === 'has-danger' ? (
                      <label className="error">
                        Please ensure password is at least 10 characters and
                        non-numeric.
                      </label>
                    ) : null}
                  </InputGroup>
                  <InputGroup
                    className={classnames(confirmPasswordValidationState, {
                      'input-group-focus': confirmPasswordFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Re-enter new password"
                      type="password"
                      onFocus={(e) => setConfirmPasswordFocus(true)}
                      onBlur={(e) => setConfirmPasswordFocus(false)}
                      onChange={(e) =>
                        inputFieldChange(
                          e,
                          setConfirmPassword,
                          setConfirmPasswordValidationState,
                          'equal',
                          password
                        )
                      }
                    />
                    {confirmPasswordValidationState === 'has-danger' ? (
                      <label className="error">
                        Please enter the same password.
                      </label>
                    ) : null}
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className={classnames('mb-3', { disabled: loading })}
                    color="primary"
                    onClick={(e) => continueClick(e)}
                    size="lg"
                    ref={btnRef}
                  >
                    {loading === true ? (
                      <Spinner size="sm" role="status"></Spinner>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  )
}

export default ForgotPassword
