import React, { useEffect, useState, useMemo } from 'react'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  usePagination,
  useExpanded,
  useRowSelect,
} from 'react-table'
import classNames from 'classnames'

// reactstrap components
import {
  Row,
  Col,
  Label,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap'

import Select from 'react-select'

const ReactTable = ({
  columns,
  data,
  loading,
  total,
  pageCount: controlledPageCount,
  queryParam,
  setQueryParam,
  expandRowsByDefault = false,
  onRowClick = null,
  showGlobalSearch = true,
  setSelectedRows,
  hiddenColumns = [],
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    toggleAllRowsExpanded,
    // Get the state from the instance
    state: { pageIndex, pageSize, filters, globalFilter, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: queryParam ? queryParam.page - 1 : 0,
        pageSize: queryParam ? queryParam.per_page : total,
        hiddenColumns,
      },
      manualFilters: true, // import to set these 4 to true if you server-side sorting/filtering/pagination
      manualGlobalFilter: true,
      manualSortBy: true,
      manualPagination: true,
      autoResetPage: false, // see https://react-table.tanstack.com/docs/api/usePagination#table-options
      pageCount: controlledPageCount,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  )

  useMemo(
    () => toggleAllRowsExpanded(expandRowsByDefault),
    [toggleAllRowsExpanded, expandRowsByDefault]
  )

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    // 'filter[asin][eq]': 'B07QDQDLZF',
    // 'filter[product_name][ilike]': '%cutlery%',
    const filter = []

    if (globalFilter) {
      const gfilter_key = 'gfilter['
        .concat(
          columns
            .filter((column) => Number.isFinite(column.gfilter))
            .sort(function (a, b) {
              return a.gfilter - b.gfilter
            })
            .map((col) => col.accessor)
            .toString()
        )
        .concat('][ilike]')
      var gfilter = [{ [gfilter_key]: '%'.concat(globalFilter).concat('%') }]
    }

    if (sortBy.length > 0) {
      var sorts = sortBy.map((field) => {
        let key = 'sort['.concat(field.id).concat(']')
        return { [key]: field.desc ? 'desc' : 'asc' }
      })
    }

    if (setQueryParam) {
      setQueryParam(
        Object.assign(
          {},
          {
            page: pageIndex + 1, // +1 due to flask being 1 indexed
            per_page: pageSize,
          },
          ...filter,
          ...(gfilter ? gfilter : []),
          ...(sorts ? sorts : [])
        )
      )
    }
  }, [
    columns,
    pageIndex,
    pageSize,
    filters,
    globalFilter,
    sortBy,
    setQueryParam,
  ])

  useEffect(() => {
    if (setSelectedRows) {
      setSelectedRows(selectedFlatRows.map((row) => row.original))
    }
  }, [selectedFlatRows, setSelectedRows])

  // Define a default UI for filtering
  function GlobalFilter({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = useState(globalFilter)
    const [searchFocus, setSearchFocus] = useState(false)
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined)
    }, 500)

    return (
      <Row>
        <Col sm="3">
          <InputGroup
            className={classNames({
              'input-group-focus': searchFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-zoom-split" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={value || ''}
              onChange={(e) => {
                setValue(e.target.value)
                onChange(e.target.value)
              }}
              onFocus={(e) => setSearchFocus(true)}
              onBlur={(e) => setSearchFocus(false)}
              placeholder={`Search`}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                {value ? (
                  <i
                    className="tim-icons icon-simple-remove opacity-8 search-reset-icon"
                    onClick={(e) => {
                      setValue('')
                      onChange('')
                    }}
                  />
                ) : null}
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    )
  }

  const results = () => {
    let start = (pageIndex + 1) * pageSize - (pageSize - 1)
    let finish = Math.min((pageIndex + 1) * pageSize, total)
    return (
      (finish === 0 ? '0' : start + (start === finish ? '' : '-' + finish)) +
      ' of ' +
      total +
      ' results'
    )
  }

  return (
    <>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageCount,
              canNextPage,
              canPreviousPage,
              filters,
              globalFilter,
              sortBy
            },
            null,
            2
          )}
        </code>
      </pre> */}
      {queryParam && showGlobalSearch ? (
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      ) : null}
      <Table
        hover={onRowClick !== null ? true : false}
        responsive
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  {...(column.sortable
                    ? column.getHeaderProps(column.getSortByToggleProps())
                    : column.getHeaderProps())}
                >
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  {column.sortable && column.canSort ? (
                    <span
                      className={classNames(
                        'sortby-arrows',
                        column.isSorted
                          ? column.isSortedDesc
                            ? 'desc'
                            : 'asc'
                          : ''
                      )}
                    />
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                onClick={(e) => (onRowClick ? onRowClick(row, e) : {})}
              >
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      {queryParam ? (
        <Row>
          <Col md="3" lg="3">
            <FormGroup row>
              <Label className="pagination-label">
                {loading ? (
                  // Use our custom loading state to show a loading indicator
                  <span>
                    <Spinner size="sm" role="status" /> Loading...
                  </span>
                ) : (
                  results()
                )}
              </Label>
              <Col sm="6">
                {/* 
                  Pagination can be built however you'd like. 
                  This is just a very basic UI implementation:
                */}
                <Pagination>
                  <PaginationItem disabled={!canPreviousPage}>
                    <PaginationLink
                      aria-label="First"
                      onClick={() => gotoPage(0)}
                    >
                      <span aria-hidden={true}>
                        <i
                          aria-hidden={true}
                          className="tim-icons icon-double-left"
                        />
                      </span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem disabled={!canPreviousPage}>
                    <PaginationLink
                      aria-label="Previous"
                      onClick={() => previousPage()}
                    >
                      <span aria-hidden={true}>
                        <i
                          aria-hidden={true}
                          className="tim-icons icon-minimal-left"
                        />
                      </span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem disabled={!canNextPage}>
                    <PaginationLink
                      aria-label="Next"
                      onClick={() => nextPage()}
                    >
                      <span aria-hidden={true}>
                        <i
                          aria-hidden={true}
                          className="tim-icons icon-minimal-right"
                        />
                      </span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem disabled={!canNextPage}>
                    <PaginationLink
                      aria-label="Last"
                      onClick={() => gotoPage(pageCount - 1)}
                    >
                      <span aria-hidden={true}>
                        <i
                          aria-hidden={true}
                          className="tim-icons icon-double-right"
                        />
                      </span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </FormGroup>
          </Col>
          <Col md="6" lg="4">
            <FormGroup row>
              <Label className="pagination-label">Rows per page:</Label>
              <Col md="6">
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  name="page_size"
                  defaultValue={{ value: pageSize, label: pageSize }}
                  value={{ value: pageSize, label: pageSize }}
                  placeholder="Rows..."
                  onChange={(e) => setPageSize(e.value)}
                  options={[
                    { value: 10, label: 10 },
                    { value: 25, label: 25 },
                    { value: 50, label: 50 },
                    { value: 100, label: 100 },
                    { value: 500, label: 500 },
                  ]}
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>
      ) : null}
    </>
  )
}

export default ReactTable
