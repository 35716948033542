import React, { useState, useEffect } from 'react'
// react plugin used to create a form with multiple steps
import ReactWizardMod from 'components/ReactWizardMod/ReactWizardMod.js'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

// reactstrap components
import { Col } from 'reactstrap'
import apiAuth from 'services/api/Auth.js'
import { sellerProfileId, sellerProfile } from 'services/Utils'

// wizard steps
import OnboardingSellerStep0 from './SellerSteps/OnboardingSellerStep0.js'
import OnboardingSellerStep1 from './SellerSteps/OnboardingSellerStep1.js'
import OnboardingSellerStep2 from './SellerSteps/OnboardingSellerStep2.js'
import OnboardingSellerStep3 from './SellerSteps/OnboardingSellerStep3.js'
import apiLeads from 'services/api/Leads.js'
import { getMarketplaceInfo } from 'services/Utils.js'

var steps = []
var startStep = 0

const getSeller = (sellers, publicId) => {
  if (sellers.length > 0) {
    const seller = sellers.filter(function (s) {
      return s.public_id === publicId
    })
    return seller.length > 0 ? seller[0] : null
  } else {
    return null
  }
}

const stepProps = (
  seller = null,
  companyPublicId,
  managingCompanyPublicId,
  email,
  name,
  region,
  marketplaceCountry,
  primaryMarketplaceId
) => {
  const boo = seller === null ? true : false
  const profileId = sellerProfileId(seller)
  return {
    email: boo ? email : seller.email === null ? '' : seller.email,
    name: boo ? name : seller.name === null ? '' : seller.name,
    region: boo
      ? region
      : seller.marketplace_region === null
      ? ''
      : seller.marketplace_region,
    marketplaceId: boo
      ? primaryMarketplaceId
      : seller.primary_marketplace_id === null
      ? ''
      : seller.primary_marketplace_id,
    marketplaceCountry: boo
      ? marketplaceCountry
      : seller.primary_marketplace_country === null
      ? ''
      : seller.primary_marketplace_country,
    marketplaceCountryCode: boo
      ? ''
      : seller.primary_marketplace_country_code === null
      ? ''
      : seller.primary_marketplace_country_code,
    publicId: boo ? '' : seller.public_id === null ? '' : seller.public_id,
    companyPublicId: boo
      ? companyPublicId
      : seller.company.public_id === null
      ? ''
      : seller.company.public_id,
    managingCompanyPublicId: boo
      ? managingCompanyPublicId
      : seller.company.managing_company?.public_id === null
      ? ''
      : seller.company.managing_company?.public_id,
    sellingPartnerId: boo
      ? ''
      : seller.selling_partner_id === seller.public_id
      ? ''
      : seller.selling_partner_id, // fudge
    mwsAccess: boo ? false : seller.mws_access,
    profileId: boo ? '' : profileId ? profileId : '',
    spapiAccess: boo ? false : seller.spapi_access,
  }
}

var createSteps = (
  seller,
  companyPublicId,
  managingCompanyPublicId,
  email,
  name,
  region,
  marketplaceCountry,
  marketplaceId
) => {
  return [
    {
      stepName: 'Seller Info',
      stepIcon: 'tim-icons icon-single-02',
      component: OnboardingSellerStep0,
      stepProps: stepProps(
        seller,
        companyPublicId,
        managingCompanyPublicId,
        email,
        name,
        region,
        marketplaceCountry,
        marketplaceId
      ),
    },
    {
      stepName: 'Authorize Selling Partner',
      stepIcon: 'tim-icons icon-settings-gear-63',
      component: OnboardingSellerStep1,
      stepProps: stepProps(seller, companyPublicId, managingCompanyPublicId),
    },
    {
      stepName: 'Connect Amazon Advertising',
      stepIcon: 'tim-icons icon-tap-02',
      component: OnboardingSellerStep2,
      stepProps: stepProps(seller, companyPublicId, managingCompanyPublicId),
    },
    {
      stepName: 'Completed',
      stepIcon: 'tim-icons icon-check-2',
      component: OnboardingSellerStep3,
    },
  ]
}

const getStartStep = (seller) => {
  const profile = sellerProfile(seller)
  if (seller.spapi_access && profile?.adapi_access) {
    return 3
  } else if (seller.spapi_access) {
    return 2
  } else if (seller.public_id) {
    return 1
  } else {
    return 0
  }
}

const OnboardingSeller = () => {
  const params = useParams()
  const [sellerPublicId, setSellerPublicId] = useState(params.public_id)
  const history = useHistory()

  useEffect(() => {
    setSellerPublicId(params.public_id)
  }, [params.public_id])

  const { data } = useQuery('GET/auth/user', () => apiAuth.user(), {
    staleTime: 0,
  })
  const [sellerEmail, setSellerEmail] = useState('')
  const [sellerName, setSellerName] = useState('')
  const [sellerRegion, setSellerRegion] = useState('')
  const [marketplaceCountry, setMarketplaceCountry] = useState('')
  const [primaryMarketplaceId, setPrimaryMarketplaceId] = useState('')
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (data && !params.public_id) {
      setLoading(true)
      apiLeads.matchLeads().then((res) => {
        if (res.data) {
          setSellerEmail(res.data.seller_email)
          setSellerName(res.data.seller_name)
          const marketplaceInfo = getMarketplaceInfo(
            res.data.primary_marketplace_id
          )
          setSellerRegion(marketplaceInfo.region)
          setPrimaryMarketplaceId(res.data.primary_marketplace_id)
          setMarketplaceCountry(marketplaceInfo.primary_marketplace)
        }
      })
      setLoading(false)
    }
  }, [data, params.public_id])

  const seller = getSeller(data.company.sellers, sellerPublicId)
  const companyPublicId = data.company.public_id
  const managingCompanyPublicId = data.company.managing_company?.public_id
  if (seller !== null && !loading) {
    steps = createSteps(
      seller,
      companyPublicId,
      managingCompanyPublicId,
      sellerEmail,
      sellerName,
      sellerRegion,
      marketplaceCountry,
      primaryMarketplaceId
    )
    startStep = getStartStep(seller)
  } else if (!loading) {
    steps = createSteps(
      null,
      companyPublicId,
      managingCompanyPublicId,
      sellerEmail,
      sellerName,
      sellerRegion,
      marketplaceCountry,
      primaryMarketplaceId
    )
    startStep = 0
  }

  const finishButtonClick = () => {
    history.push('/admin/dashboard')
  }

  return (
    <>
      <div className="content onboarding-seller-wizard">
        <Col className="mr-auto ml-auto" md="10">
          <ReactWizardMod
            steps={steps}
            startStep={startStep} // must be less than # of steps...
            //navSteps
            validate
            title="Connect Amazon Account"
            description=""
            headerTextCenter
            finishButtonClasses="btn-wd btn-primary"
            nextButtonClasses="btn-wd btn-primary"
            previousButtonClasses="btn-wd"
            progressbar
            color="primary"
            finishButtonClick={finishButtonClick}
          />
        </Col>
      </div>
    </>
  )
}

export default OnboardingSeller
