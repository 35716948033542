import React, { forwardRef, useImperativeHandle } from 'react'
// reactstrap components
import {} from 'reactstrap'

const OnboardingSellerStep3 = forwardRef((props, ref) => {
  const isValidated = () => {
    if (true) {
      return true
    } else {
      return false
    }
  }

  useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated()
    },
    state: {},
  }))

  return (
    <>
      <div className="success-checkmark">
        <div className="check-icon">
          <span className="icon-line line-tip"></span>
          <span className="icon-line line-long"></span>
          <div className="icon-circle"></div>
          <div className="icon-fix"></div>
        </div>
      </div>
      <h3 className="info-text">Good job!</h3>
      <p className="info-text">
        Your Amazon account is successfully connected.
      </p>
    </>
  )
})

export default OnboardingSellerStep3
