import React, { useState, useEffect, Fragment, lazy } from 'react'
import IframeResizer from 'iframe-resizer-react'
import { useQuery, useQueryClient } from 'react-query'
import apiAnalytics from 'services/api/Analytics'
import { useHistory } from 'react-router-dom'

// nodejs library that concatenates classes
import classNames from 'classnames'

// reactstrap components
import { Card, CardHeader, CardTitle, CardBody, Row, Col } from 'reactstrap'
import SkeletonInsights from 'components/SkeletonCard/SkeletonInsights'

const Dashboard = ({ user }) => {
  const [loading, setLoading] = useState(true)

  const analyticsUrl = process.env.REACT_APP_FRONTEND_APP_BASE_URL

  const [iframeUrl, setIframeUrl] = useState(
    analyticsUrl // for the auto height to work, it appears a default url is needed
  )
  const queryClient = useQueryClient()
  const history = useHistory()

  const embedUrlParam = {
    resource: { dashboard: 12 },
    params: {
      seller_id: user.primary_seller.selling_partner_id,
      marketplace_id: user.primary_seller.primary_marketplace_id,
    },
  }

  // Get Metabase iframeUrl
  const { data: embedUrl, isFetching: embedUrlIsLoading } = useQuery(
    ['POST/analytics/embed', embedUrlParam],
    () => apiAnalytics.generateEmbedUrl(embedUrlParam),
    {
      staleTime: 10 * 60 * 1000, // 10 minutes since iframe_url is only valid for 11 minutes
      onSuccess: (embedUrl) => {
        if (embedUrl && embedUrl.status < 300) {
          setIframeUrl(embedUrl.data.iframe_url)
        } else {
          // could handle this better but for now just assume token expired
          history.go(0) // refresh page
        }
      },
    }
  )

  useEffect(() => {
    const iframeUrlCache = queryClient.getQueryData([
      'POST/analytics/embed',
      embedUrlParam,
    ])
    
    if (iframeUrlCache && iframeUrlCache.data?.iframe_url) {
      setIframeUrl(iframeUrlCache.data.iframe_url)  
    }

  }, [queryClient, embedUrlParam])

  return (
    <>
      <div className="content">
        <Row
          className={classNames({
            'd-flex justify-content-center align-items-end': loading,
          })}
        >          
          <Fragment>
            <Col xs="12">
              <Card className="card-plain info-area">
                <CardHeader>
                  <CardTitle tag="h2">Overview</CardTitle>
                  <hr className="line-primary mb-2" />
                </CardHeader>
                <CardBody>
                  <p className="card-description description">
                    Easily understand your Amazon business performance at a glance.
                  </p>
                </CardBody>
              </Card>
              <Card>
                <CardBody>

                  {loading && <SkeletonInsights/>}
                  
                  {iframeUrl !== analyticsUrl && 
                    <IframeResizer
                      src={iframeUrl}
                      style={{ width: '1px', minWidth: '100%', minHeight: "62.5em"}}
                      hidden={loading}
                      onLoad={() => setLoading(false)}
                    />
                  }

                </CardBody>
              </Card>
            </Col>
          </Fragment>
        </Row>
      </div>
    </>
  )
}

export default Dashboard
