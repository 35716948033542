import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import classnames from 'classnames'
// reactstrap components
import { Row, Button } from 'reactstrap'
import { generateRandomString } from 'services/Utils'
import { getAuthToken } from 'variables/authToken'
import useAlert from 'services/hooks/useAlert'
import { sellerAndVendorCentralUrls } from 'services/Utils'

const OnboardingSellerStep1 = forwardRef((props, ref) => {
  const params = new URLSearchParams(useLocation().search)
  const [status] = useState(params.get('status'))
  const [message] = useState(params.get('message'))

  const [spapiAccess] = useState(props.spapiAccess)
  const [marketplaceId, setMarketplaceId] = useState(props.marketplaceId)
  const [publicId, setPublicId] = useState(props.publicId)
  const { addAlert } = useAlert()
  const history = useHistory()

  useEffect(() => {
    setMarketplaceId(props.marketplaceId)
    setPublicId(props.publicId)
  }, [props])

  useEffect(() => {
    if (status && message) {
      if (status === 'fail') {
        addAlert('danger', 'tim-icons icon-alert-circle-exc', message, null)
      }
      if (status === 'success') {
        addAlert('success', 'tim-icons icon-check-2', message, null)
      }
      // don't want noti to fire again incase of refresh
      params.delete('status')
      params.delete('message')
      history.replace({ search: params.toString() })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const startAmazonSellingPartnerOAuth = async () => {
    let baseUrl
    // Use authToken as state and thus backend api auth on redirect
    const authToken = getAuthToken()

    // Inspired by https://github.com/aaronpk/pkce-vanilla-js/blob/master/index.html
    // Create and store a new PKCE code_verifier (the plaintext random secret)
    var code_verifier = generateRandomString()

    // https://github.com/amzn/selling-partner-api-docs/blob/main/guides/en-US/developer-guide/SellingPartnerApiDeveloperGuide.md#seller-central-urls
    // this should be in marketplace model not hardcoded in frontend...
    baseUrl =
      sellerAndVendorCentralUrls(marketplaceId, 'seller') +
      '/apps/authorize/consent' // TODO support 'vendor'

    // Build the amazon oauth URL
    var url = new URL(baseUrl)
    url.searchParams.append(
      'application_id',
      process.env.REACT_APP_AMAZON_SP_API_APP_ID
    )
    if (process.env.NODE_ENV === 'development') {
      url.searchParams.append(
        'redirect_uri',
        'https://api.autron.ai/auth/amazon-sp-api/cb'
      ) // use this for testing change to http://127.0.0.1:5000 after amazon redirect
    } else {
      url.searchParams.append(
        'redirect_uri',
        [
          process.env.REACT_APP_BACKEND_API_BASE_URL,
          '/auth/amazon-sp-api/cb',
        ].join('')
      )
    }
    url.searchParams.append(
      'state',
      [authToken, publicId, code_verifier].join(' ')
    )
    if (process.env.NODE_ENV === 'development')
      url.searchParams.append('version', 'beta') // use to test draft or pending app

    // Redirect to the amazon authorization server
    window.location = url
  }

  const isValidated = () => {
    if (spapiAccess || status) {
      addAlert(
        'success',
        'tim-icons icon-check-2',
        'Successfully authorized as Amazon Selling Partner',
        null
      )
      return true
    } else {
      addAlert(
        'danger',
        'tim-icons icon-alert-circle-exc',
        'Amazon Selling Partner authorization failed, please try again.',
        null
      )
      return false
    }
  }

  useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated()
    },
  }))

  return (
    <>
      <h4 className="info-text">Authorize as Amazon Selling Partner</h4>
      <p className="info-text">
        Grant Autron permission to help manage your Amazon business.
      </p>
      <Row className="justify-content-center mt-4">
        <Button
          disabled={spapiAccess}
          className={classnames({
            'btn-round': true,
            'button-disabled': spapiAccess,
          })}
          color="primary"
          onClick={(e) => startAmazonSellingPartnerOAuth()}
        >
          {spapiAccess ? (
            <div>
              <i className="tim-icons icon-link-72" /> Authorized as Selling
              Partner
            </div>
          ) : (
            <div>Authorize Selling Partner</div>
          )}
        </Button>
      </Row>
      {spapiAccess ? (
        <Row className="justify-content-center">
          <p className="mb-0">
            <span
              className="div-link"
              onClick={(e) => startAmazonSellingPartnerOAuth()}
            >
              Need to reauthorize?
            </span>
          </p>
        </Row>
      ) : null}
    </>
  )
})

export default OnboardingSellerStep1
