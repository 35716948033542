import React, { useState, Fragment, useContext } from 'react'
import classNames from 'classnames'
// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Spinner,
  Button,
} from 'reactstrap'
import referralImg from 'assets/img/referral.png'
import affiliatesHero from 'assets/img/affiliates-hero.svg'
import affiliatesHeroLightMode from 'assets/img/affiliatesHeroLightMode.svg'

import ThemeContext from 'services/providers/ThemeContext'

const Affiliate = () => {
  const capitalIsLoading = false
  const { lightMode } = useContext(ThemeContext)

  return (
    <>
      <div className="content">
        <Row>
          <Col
            className={classNames({
              'd-flex justify-content-center align-items-end mt-xl':
                capitalIsLoading,
            })}
          >
            {capitalIsLoading ? (
              <Spinner /> // used for initial load
            ) : (
              <Fragment>
                <Card className="card-plain info-area">
                  <CardHeader>
                    <CardTitle tag="h2">Earn with Autron</CardTitle>
                    <hr className="line-primary mb-2" />
                  </CardHeader>
                  <CardBody>
                    <p className="card-description description">
                      Collaborate with Autron and earn ongoing commissions by
                      promoting Autron’s AI-driven advertising solutions to your
                      network.
                    </p>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div className="content overflow-hidden pb-5 px-3">
                      <Row className="row-vertical-align affiliate-hero">
                        <Col
                          className="hero-left-col text-left mt-n4 mt-md-nxl"
                          lg="6"
                          md="6"
                        >
                          <h1 className="title affiliate-title">
                            Join the Autron
                            <br /> Affiliate Program
                          </h1>
                          <p className="card-description description mb-4">
                            Partner with Autron and get paid for giving your
                            audience access to the latest in AI-powered
                            advertising to crush their Amazon goals. Earn{' '}
                            <strong>recurring 25% commission</strong> on monthly
                            subscriptions.
                          </p>
                          <Button
                            href={process.env.REACT_APP_AFFILIATE_SIGN_UP_URL}
                            className={'btn-round mt-4'}
                            color="primary"
                            size="md"
                            target="_blank"
                          >
                            Join Now
                          </Button>
                          <div className="card-description description mt-2">
                            {'Already an Affiliate? '}
                            <a
                              href={process.env.REACT_APP_AFFILIATE_LOGIN_URL}
                              className="form-control-static div-link home-div-link"
                              target="_blank"
                            >
                              Login
                            </a>
                          </div>
                        </Col>
                        <Col lg="6" md="6">
                          <Card className="mt-md-nmd ml-sm-nmd card-plain">
                            <img
                              alt="hero"
                              className={`img-fluid hero-img ${
                                lightMode ? 'zoom-img' : 'zoom-img-dark-mode'
                              }`}
                              src={
                                lightMode
                                  ? affiliatesHeroLightMode
                                  : affiliatesHero
                              }
                            />
                          </Card>
                        </Col>
                      </Row>

                      <Row className="mt-0 pt-5">
                        <Col lg="12">
                          <Row>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="icon icon-primary">
                                  <i className="tim-icons icon-coins" />
                                </div>
                                <h3 className="info-title mb-4">
                                  Recurring 25% Commission
                                </h3>
                                <hr className="line-primary mb-4" />
                                <p className="card-description description">
                                  Get 25% commission on all customer payments
                                  for the first 24 months. Reap the rewards of
                                  compounding!
                                </p>
                              </div>
                            </Col>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="icon icon-warning">
                                  <i className="tim-icons icon-spaceship" />
                                </div>
                                <h3 className="info-title mb-4">
                                  No Earnings Cap
                                </h3>
                                <hr className="line-warning mb-4" />
                                <p className="card-description description">
                                  We have no caps on how much money you can
                                  make. Easily earn thousands of dollars from
                                  our monthly plans which range from $99 to
                                  $5000.
                                </p>
                              </div>
                            </Col>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="icon icon-success">
                                  <i className="tim-icons icon-refresh-02" />
                                </div>
                                <h3 className="info-title mb-4">
                                  60-Day Conversion Window
                                </h3>
                                <hr className="line-success mb-4" />
                                <p className="card-description description">
                                  We provide a 60-day affiliate cookie to ensure
                                  you're credited whenever a customer converts.
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="icon icon-info">
                                  <i className="tim-icons icon-money-coins" />
                                </div>
                                <h3 className="info-title mb-4">
                                  Get Paid Monthly
                                </h3>
                                <hr className="line-info mb-4" />
                                <p className="card-description description">
                                  Payouts are made via PayPal or Wise each
                                  month, based on a 60-day conversion window.
                                </p>
                              </div>
                            </Col>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="icon icon-danger">
                                  <i className="tim-icons icon-tag" />
                                </div>
                                <h3 className="info-title mb-4">
                                  Special Deals
                                </h3>
                                <hr className="line-danger mb-4" />
                                <p className="card-description description">
                                  Get personalized codes and deals for your
                                  audience.
                                </p>
                              </div>
                            </Col>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="icon icon-hero">
                                  <i className="tim-icons icon-gift-2" />
                                </div>
                                <h3 className="info-title mb-4">
                                  Partner Perks
                                </h3>
                                <hr className="line-hero mb-4" />
                                <p className="card-description description">
                                  Top affiliates get early access to new
                                  features & tools and favourble Autron pricing.
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div className="content bg-card pt-5 pb-5 px-3">
                      <Row className="">
                        <Col className="ml-auto mr-auto text-center" md="12">
                          <h1 className="title">Ready to get started?</h1>
                          <p className="card-description description mb-5 mt-n3">
                            It only takes a few minutes!
                          </p>
                          <Row>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="number-circle">1</div>
                                <h3 className="info-title my-4">
                                  Create Affiliate Account
                                </h3>

                                <p className="card-description description">
                                  Sign up for an affiliate account and gain
                                  access to your affiliate dashboard.
                                </p>
                              </div>
                            </Col>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="number-circle">2</div>
                                <h3 className="info-title my-4">
                                  Get Your Link
                                </h3>
                                <p className="card-description description">
                                  Your personalized affiliate link ensures you
                                  get full credit for every conversion you earn.
                                </p>
                              </div>
                            </Col>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="number-circle">3</div>
                                <h3 className="info-title my-4">
                                  Share and Earn
                                </h3>
                                <p className="card-description description">
                                  Share this link with your audience and start
                                  collecting a recurring 25% commission for
                                  every conversion!
                                </p>
                              </div>
                            </Col>
                          </Row>
                          <Button
                            href={process.env.REACT_APP_AFFILIATE_SIGN_UP_URL}
                            className={'btn-round mt-4'}
                            color="primary"
                            size="md"
                            target="_blank"
                          >
                            Get Started
                          </Button>
                        </Col>
                        <Col lg="6">
                          <Row className=""></Row>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row className="my-lg px-3">
                      <Col lg="6" md="6">
                        <h1 className="title">Share with your audience</h1>
                        <p className="mt-5 card-description description">
                          There are a million and one ways to share Autron
                          online, here are some suggestions:
                        </p>
                        <ul className="unordered-description-list">
                          <li>
                            <i className="tim-icons icon-minimal-right text-primary" />{' '}
                            Recommend Autron to clients / mentees in
                            conversations
                          </li>
                          <li>
                            <i className="tim-icons icon-minimal-right text-primary" />{' '}
                            Link to us from a blog post
                          </li>
                          <li>
                            <i className="tim-icons icon-minimal-right text-primary" />{' '}
                            Write or publish a video review of our product
                          </li>
                          <li>
                            <i className="tim-icons icon-minimal-right text-primary" />{' '}
                            Collaborate on a case study with us
                          </li>
                          <li>
                            <i className="tim-icons icon-minimal-right text-primary" />{' '}
                            Run a webinar / podcast together
                          </li>
                          <li>
                            <i className="tim-icons icon-minimal-right text-primary" />{' '}
                            Write a guest post for the Inside Autron blog
                          </li>
                          <li>
                            <i className="tim-icons icon-minimal-right text-primary" />{' '}
                            Have us do a guest newsletter to your email list
                          </li>
                        </ul>
                        <Button
                          href={process.env.REACT_APP_AFFILIATE_SIGN_UP_URL}
                          className={'btn-round mt-4'}
                          color="primary"
                          size="md"
                          target="_blank"
                        >
                          Join Now
                        </Button>
                      </Col>
                      <Col lg="6" md="6">
                        <img alt="affiliate" className="" src={referralImg} />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Affiliate
