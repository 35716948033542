import React, { useEffect, useState, useMemo, Fragment } from 'react'
import classNames from 'classnames'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Button,
  Spinner,
  UncontrolledTooltip,
  Row,
  Col,
  UncontrolledAlert,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from 'reactstrap'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'
import apiSellers from 'services/api/Seller'
import ReactTable from 'components/ReactTable/ReactTable.js'
import Product from './Product'
import ProductsImport from './ProductsImport'

const Products = ({ user }) => {
  const params = useParams()
  const [product, setProduct] = useState()
  const [primarySeller, setPrimarySeller] = useState({})
  const primarySellerPublicId = primarySeller?.public_id
  const [products, setProducts] = useState([])
  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: 10,
  })
  const [gfilterQuery, setGfilterQuery] = useState(false)
  const [pages, setPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const history = useHistory()
  const queryClient = useQueryClient()

  const [importType, setImportType] = useState(null)

  const importTypes = [
    {
      options: { value: 'costs', label: 'Product costs' },
      fields: [
        'asin',
        'cost_of_goods_per_unit',
        'shipping_cost_per_unit',
        'misc_cost_per_unit',
      ],
      exampleFileUrl:
        'https://docs.google.com/spreadsheets/d/1sZ0ISVFlxgkLL8ZQIrMPzixS9DXOGAO-CHt_k3ylDao/edit?usp=sharing',
    },
    {
      options: { value: 'targets', label: 'Product ad targeting' },
      fields: ['asin', 'type', 'match_type', 'target'],
      exampleFileUrl:
        'https://docs.google.com/spreadsheets/d/1KTga8XcKZiuHqcaf1H-LdAZsnCI1OoMY23klm509vEk/edit?usp=sharing',
    },
  ]

  useEffect(() => {
    setPrimarySeller(user.primary_seller)
  }, [user])

  const setProductStates = (pObj, param) => {
    setProducts(pObj?.data.products)
    setPages(pObj?.data.pages)
    setTotal(pObj?.data.total)
    setLoading(false)
    setProduct(
      pObj?.data.products?.find(
        (product) => product.public_id === params.public_id
      )
    )
    setGfilterQuery(
      Object.keys(param).some(function (k) {
        return ~k.indexOf('gfilter')
      })
    )
  }

  const {
    data: productsObject,
    isFetching: isLoading,
    productsRefetch,
  } = useQuery(
    ['GET/sellers/', primarySellerPublicId, '/products', queryParam],
    () => apiSellers.products(primarySellerPublicId, queryParam),
    {
      staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (productsObject) => {
        setProductStates(productsObject, queryParam)
      },
    }
  )

  useEffect(() => {
    const productsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/products',
      queryParam,
    ])
    if (productsCache && productsCache.data?.products) {
      setProductStates(productsCache, queryParam)
    }
  }, [queryClient, primarySellerPublicId, queryParam])

  useEffect(() => {
    setProduct(
      products?.find((product) => product.public_id === params.public_id)
    )
  }, [params.public_id]) // if add products then get 2 calls on refresh...

  const productColumns = useMemo(
    () => [
      {
        Header: 'name',
        accessor: 'product_name',
        filter: 'ilike', // filter is used by react-table
        gfilter: 1, // custom param, number order needs to match what's expected in backend dto
        sortable: true,
      },
      {
        Header: 'sku',
        accessor: 'sku',
        filter: 'eq',
        gfilter: 2,
        sortable: true,
      },
      {
        Header: 'asin',
        accessor: 'asin',
        filter: 'eq',
        gfilter: 3,
        sortable: true,
      },
      {
        Header: 'Inventory',
        accessor: 'inventory_quantity',
        sortable: false,
      },
      {
        Header: 'Price',
        accessor: 'price',
        sortable: false,
      },
      {
        Header: 'Amazon Fee',
        accessor: 'estimated_fee_total',
        sortable: false,
      },
      {
        Header: 'Total Cost',
        accessor: 'total_cost',
        sortable: false,
      },
      {
        Header: 'Profit',
        accessor: 'profit',
        sortable: false,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
      },
    ],
    []
  )

  const totalCostAndProfit = (product) => {
    if (product?.product_costs?.length > 0) {
      return [
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: product.currency,
        }).format(product.total_cost),
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: product.currency,
        }).format(product.profit),
      ]
    } else {
      return [
        <p
          className="form-control-static div-link info"
          onClick={() => {
            setProduct(product)
            history.push('/admin/products/' + product.public_id)
          }}
        >
          Set Costs
        </p>,
        '',
      ]
    }
  }

  const productData = products?.map((product, key) => {
    let costAndProfit = totalCostAndProfit(product)
    let price = product.price || product.your_price
    let estimated_fee_total =
      product.estimated_fee_total || product.estimated_referral_fee_per_item
    let inventory_quantity =
      product.afn_fulfillable_quantity || product.quantity
    return {
      id: key,
      public_id: product.public_id,
      product_name: product.product_name.substring(0, 100) + '...',
      sku: product.sku,
      asin: product.asin,
      inventory_quantity: inventory_quantity,
      price: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: product.currency,
      }).format(price),
      estimated_fee_total: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: product.currency,
      }).format(estimated_fee_total),
      total_cost: costAndProfit[0],
      profit: costAndProfit[1],
      actions: (
        <div className="actions-right">
          <Button
            onClick={() => {
              setProduct(product)
              history.push('/admin/products/' + product.public_id)
            }}
            id={'products_table_edit_btn_' + key}
            color="warning"
            size="sm"
            className={classNames('btn-icon btn-link like', {
              'btn-neutral': key < 0,
            })}
          >
            <i className="tim-icons icon-pencil" />
          </Button>
          <UncontrolledTooltip
            delay={0}
            target={'products_table_edit_btn_' + key}
          >
            Edit
          </UncontrolledTooltip>
        </div>
      ),
    }
  })

  return (
    <>
      <div className="content">
        <Row>
          <Col
            className={classNames({
              'd-flex justify-content-center align-items-end mt-xl': loading,
            })}
          >
            {loading ? (
              <Spinner /> // used for initial load
            ) : product ? (
              <Product product={product} />
            ) : (
              <Fragment>
                <Card className="card-plain info-area">
                  <CardHeader>
                    <CardTitle tag="h2">Products</CardTitle>
                    <hr className="line-primary mb-2" />
                  </CardHeader>
                  <CardBody>
                    <p className="card-description description">
                      Your Amazon products. Where you can specify product costs
                      and suggest advertising targets.
                    </p>
                  </CardBody>
                  {/* <CardFooter></CardFooter> */}
                </Card>
                {products?.length === 0 && !gfilterQuery ? (
                  <Row>
                    <Col sm="9" md="6">
                      <UncontrolledAlert color="info" fade={true}>
                        <span>
                          <strong>No products found.</strong> If this is
                          unexpected, we might still be syncing your Amazon
                          account, please check back in a couple of hours.
                        </span>
                      </UncontrolledAlert>
                    </Col>
                  </Row>
                ) : (
                  <Card>
                    {importType ? (
                      <CardBody>
                        <ProductsImport
                          seller={primarySeller}
                          importType={importType}
                          setImportType={setImportType}
                          importTypes={importTypes}
                        />
                      </CardBody>
                    ) : (
                      <CardBody>
                        {products?.length > 0 ||
                        (products?.length === 0 && gfilterQuery) ? (
                          <Fragment>
                            <UncontrolledDropdown className="float-right">
                              <DropdownToggle
                                caret
                                className="btn-simple btn btn-primary"
                                color="primary"
                                data-toggle="dropdown"
                                type="button"
                              >
                                {/* <i className="tim-icons icon-settings-gear-63" /> */}
                                <span
                                  style={{
                                    marginRight: '-20px',
                                    display: 'inline',
                                  }}
                                >
                                  Bulk import
                                </span>
                              </DropdownToggle>
                              <DropdownMenu right>
                                {importTypes.map((type, i) => (
                                  <NavLink tag="li" key={i}>
                                    <DropdownItem
                                      className="nav-item"
                                      onClick={(e) =>
                                        setImportType(type.options.value)
                                      }
                                    >
                                      {type.options.label}
                                    </DropdownItem>
                                  </NavLink>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            <ReactTable
                              columns={productColumns}
                              data={productData}
                              loading={isLoading}
                              total={total}
                              pageCount={pages}
                              queryParam={queryParam}
                              setQueryParam={setQueryParam}
                              onRowClick={(row, e) => {
                                history.push(
                                  '/admin/products/' + row.original['public_id']
                                )
                              }}
                            />
                          </Fragment>
                        ) : null}
                      </CardBody>
                    )}
                  </Card>
                )}
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Products
