import moment from 'moment-timezone'
import React from 'react'

// function that returns true if value is email, false otherwise
export const verifyEmail = (value) => {
  var emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}
// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true
  }
  return false
}
// function that verifies if value is numeric including decimal (positive and negative)
// https://stackoverflow.com/questions/2811031/decimal-or-numeric-values-in-regular-expression-validation
const verifyNumber = (value) => {
  var numberRex = /^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/
  if (numberRex.test(value)) {
    return true
  }
  return false
}
const verifyPositiveNumber = (value) => {
  var positiveNumberRex = /^(0|[1-9]\d*)(\.\d+)?$/
  if (positiveNumberRex.test(value)) {
    return true
  }
  return false
}
// function that verifies if value contains only numbers / is a positive integer
const verifyPositiveInteger = (value) => {
  var positiveIntegerRex = /^(0|[1-9]\d*)$/
  if (positiveIntegerRex.test(value)) {
    return true
  }
  return false
}
// function that verifies if checkbox checked
const verifyCheckbox = (checked) => {
  if (checked) {
    return true
  }
  return false
}
// function that verifies if two values are equal
const verifyEqual = (value1, value2) => {
  if (value1 === value2) {
    return true
  }
  return false
}
// function that verifies value greater than or equal to a number
const verifyGTENumber = (value, min) => {
  if (value >= min) {
    return true
  }
  return false
}
// function that verifies value less than or equal to a number
const verifyLTENumber = (value, max) => {
  if (value >= max) {
    return true
  }
  return false
}

// handle input field changes and associated validation
export const inputFieldChange = (
  event,
  setState,
  setValidationState,
  type,
  stateNameEqualTo,
  required = true
) => {
  switch (type) {
    case 'email':
      if (verifyEmail(event.target.value)) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break
    case 'password':
      if (
        verifyLength(event.target.value, stateNameEqualTo) &&
        !verifyPositiveInteger(event.target.value)
      ) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break
    case 'number':
      if (verifyNumber(event.target.value)) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break
    case 'positiveNumber':
      if (verifyPositiveNumber(event.target.value)) {
        setValidationState('has-success')
      } else {
        setValidationState(
          required || event.target.value.length > 0 ? 'has-danger' : ''
        )
      }
      break
    case 'positiveNumberGTE':
      if (
        verifyPositiveNumber(event.target.value) &&
        verifyGTENumber(event.target.value, stateNameEqualTo)
      ) {
        setValidationState('has-success')
      } else {
        setValidationState(
          required || event.target.value.length > 0 ? 'has-danger' : ''
        )
      }
      break
    case 'positiveInteger':
      if (verifyPositiveInteger(event.target.value)) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break
    case 'length':
      if (verifyLength(event.target.value, stateNameEqualTo)) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break
    case 'checkbox':
      if (verifyCheckbox(event.target.checked)) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break
    case 'select_length':
      if (verifyLength(event.value, stateNameEqualTo)) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break
    case 'select_multi_length':
      if (verifyLength(event ? event : [], stateNameEqualTo)) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break
    case 'phone':
      if (verifyLength(event, stateNameEqualTo)) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break
    case 'equal':
      if (verifyEqual(event.target.value, stateNameEqualTo)) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break
    case 'Datetime':
      if (event.length !== 0) {
        setValidationState('has-success')
      } else {
        setValidationState('has-danger')
      }
      break

    default:
      break
  }
  if (['Datetime'].includes(type)) {
    if (moment.isMoment(event)) {
      setState(event.format())
    }
  } else {
    if (['select_length', 'phone', 'select_multi_length'].includes(type)) {
      setState(event)
    } else {
      setState(event.target.value)
    }
  }
}

// notifications
export const noti = (notiRef, type, icon, message) => {
  var options = {}
  options = {
    place: 'tr',
    message: message,
    type: type,
    icon: icon,
    autoDismiss: 7,
  }
  notiRef.current.notificationAlert(options)
}

//////////////////////////////////////////////////////////////////////
// PKCE HELPER FUNCTIONS

// Generate a secure random string using the browser crypto functions
export const generateRandomString = () => {
  var array = new Uint32Array(28)
  window.crypto.getRandomValues(array)
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
    ''
  )
}

// Calculate the SHA256 hash of the input text.
// Returns a promise that resolves to an ArrayBuffer
function sha256(plain) {
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest('SHA-256', data)
}

// Base64-urlencodes the input string
function base64urlencode(str) {
  // Convert the ArrayBuffer to string using Uint8 array to conver to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge
export const pkceChallengeFromVerifier = async (v) => {
  var hashed = await sha256(v)
  return base64urlencode(hashed)
}
//////////////////////////////////////////////////////////////////////

// https://stackoverflow.com/a/979325
export const sortByField = (field, reverse = false, primer = Number) => {
  const key = primer
    ? function (x) {
        return primer(x[field])
      }
    : function (x) {
        return x[field]
      }

  reverse = !reverse ? 1 : -1

  return function (a, b) {
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a))
  }
}

// Helper function to filter the profile
const filterSellerProfile = (seller) => {
  if (seller && seller.profiles.length > 0) {
    return seller.profiles.filter(function (m) {
      return (
        m.account_info_id === seller?.selling_partner_id && // potential issue, there can be multiple seller profiles for a given marketplace e.g seller_id = AC0XBGQMQ0GR0
        m.account_info_marketplace_string_id ===
          seller?.primary_marketplace_id &&
        m.account_info_type === 'seller'
      )
    })[0] // Returns the first matching profile or undefined
  }
  return undefined
}

export const sellerProfileId = (seller) => {
  const profile = filterSellerProfile(seller)
  return profile ? profile.profile_id : null
}

export const sellerProfile = (seller) => {
  return filterSellerProfile(seller)
}

export const sleep = (ms) => new Promise((res) => setTimeout(res, ms))

export const sellerAndVendorCentralUrls = (marketplaceId, type) => {
  let baseUrl
  switch (marketplaceId) {
    // NA
    case 'A2EUQ1WTGCTBG2': // Canada
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.ca'
          : 'https://vendorcentral.amazon.ca'
      break
    case 'ATVPDKIKX0DER': // US
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.com'
          : 'https://vendorcentral.amazon.com'
      break
    case 'A1AM78C64UM0Y8': // Mexico
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.com.mx'
          : 'https://vendorcentral.amazon.com.mx'
      break
    case 'A2Q3Y263D00KWC': // Brazil
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.com.br'
          : 'https://vendorcentral.amazon.com.br'
      break
    // EUROPE
    case 'A1RKKUPIHCS9HS': // Spain
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral-europe.amazon.com'
          : 'https://vendorcentral.amazon.es'
      break
    case 'A1F83G8C2ARO7P': // UK
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral-europe.amazon.com'
          : 'https://vendorcentral.amazon.co.uk'
      break
    case 'A13V1IB3VIYZZH': // France
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral-europe.amazon.com'
          : 'https://vendorcentral.amazon.fr'
      break
    case 'A1805IZSGTT6HS': // Netherlands
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.nl'
          : 'https://vendorcentral.amazon.nl'
      break
    case 'A1PA6795UKMFR9': // Germany
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral-europe.amazon.com'
          : 'https://vendorcentral.amazon.de'
      break
    case 'APJ6JRA9NG5V4': // Italy
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral-europe.amazon.com'
          : 'https://vendorcentral.amazon.it'
      break
    case 'A2NODRKZP88ZB9': // Sweden
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.se'
          : 'https://vendorcentral.amazon.se'
      break
    case 'A1C3SOZRARQ6R3': // Poland
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.pl'
          : 'https://vendorcentral.amazon.pl'
      break
    case 'A33AVAJ2PDY3EV': // Turkey
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.com.tr'
          : 'https://vendorcentral.amazon.com.tr'
      break
    case 'A2VIGQ35RCS4UG': // UAE
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.ae'
          : 'https://vendorcentral.amazon.me'
      break
    case 'A21TJRUUN4KGV': // India
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.in'
          : 'https://vendorcentral.amazon.in'
      break
    case 'AE08WJ6YKNBMC': // South Africa
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.co.za'
          : 'https://vendorcentral.amazon.co.za'
      break
    case 'AMEN7PMS3EDWL': // Belgium
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.com.be'
          : 'https://vendorcentral.amazon.com.be'
      break
    case 'A17E79C6D8DWNP': // Saudi Arabia
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.sa'
          : 'https://vendorcentral.amazon.sa'
      break
    // FAR EAST
    case 'A19VAU5U5O7RUS': // Singapore
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.sg'
          : 'https://vendorcentral.amazon.com.sg'
      break
    case 'A39IBJ37TRP1C6': // Australia
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.com.au'
          : 'https://vendorcentral.amazon.com.au'
      break
    case 'A1VC38T7YXB528': // Japan
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.co.jp'
          : 'https://vendorcentral.amazon.co.jp'
      break
    default:
      baseUrl =
        type === 'seller'
          ? 'https://sellercentral.amazon.com'
          : 'https://vendorcentral.amazon.com'
  }
  return baseUrl
}

export const priceFromTiers = (tiers, usageInitial, mode) => {
  // example: https://stripe.com/docs/billing/subscriptions/tiers
  // tiers = [
  //   { flat_amount_decimal: '1000',  unit_amount_decimal: '500', up_to: 5 },
  //   { flat_amount_decimal: '2000',  unit_amount_decimal: '400', up_to: 10 },
  //   { flat_amount_decimal: '3000',  unit_amount_decimal: '300', up_to: 15 },
  //   { flat_amount_decimal: '4000',  unit_amount_decimal: '200', up_to: 20 }
  // ]
  // let usage = 12

  let usage = usageInitial
  let tierTotals = []
  if (mode === 'graduated') {
    tierTotals = tiers.map((t, i) => {
      let upTo = t.up_to ? t.up_to : Infinity
      let upToPrev = i > 0 ? tiers[i - 1].up_to : 0
      let units = Math.min(usage, upTo - upToPrev)
      let tierTotal =
        parseFloat(t.flat_amount_decimal) / 100 +
        (usage > 0 ? (parseFloat(t.unit_amount_decimal) / 100) * units : 0)
      usage = Math.max(usage - units, 0)
      return tierTotal
    })
  } else {
    // 'volume'
    tierTotals = tiers.map((t, i) => {
      let upTo = t.up_to ? t.up_to : Infinity
      let upToPrev = i > 0 ? tiers[i - 1].up_to : 0
      if (usage < upTo && usage >= upToPrev) {
        return (
          parseFloat(tiers[i].flat_amount_decimal) / 100 +
          (parseFloat(tiers[i].unit_amount_decimal) / 100) * usage
        )
      } else {
        return 0
      }
    })
  }

  return tierTotals.reduce((a, b) => a + b, 0)
}

export const tierPricingPreview = (tiers, usageInitial, mode) => {
  let usage = usageInitial
  let preview = []
  let total = 0
  if (mode === 'graduated') {
    preview = tiers?.map((t, i) => {
      let upTo = t.up_to ? t.up_to : usageInitial
      let upToPrev = i > 0 ? tiers[i - 1].up_to : 0
      let units = Math.min(usage, upTo - upToPrev)
      let tierTotal =
        parseFloat(t.flat_amount_decimal) / 100 +
        (usage > 0 ? (parseFloat(t.unit_amount_decimal) / 100) * units : 0)
      total = total + tierTotal
      usage = Math.max(usage - units, 0)
      let p =
        tierTotal > 0
          ? (i === 0 ? 'First ' : 'Next ') +
            Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(units) +
            ' x ' +
            Intl.NumberFormat('en-US', {
              style: 'percent',
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
            }).format(parseFloat(t.unit_amount_decimal) / 100) +
            (parseFloat(t.flat_amount_decimal) > 0
              ? ' + ' +
                Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(parseFloat(t.flat_amount_decimal) / 100)
              : '') +
            ' = ' +
            Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(tierTotal)
          : ''
      return p
    })
    let e =
      'Example: ' +
      Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(usageInitial) +
      ' of ad sales would cost ' +
      Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(total)
    if (preview !== undefined) {
      preview.unshift(e)
    } else {
      preview = []
    }
  } else {
    // 'volume'
    return ''
  }

  return preview
}

export async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}

export function managedSellerInviteLink(company_public_id, company_name) {
  let page = process.env.REACT_APP_BACKEND_APP_BASE_URL + '/auth/register'
  let query =
    '?managing_company=' +
    company_public_id +
    '&managing_company_name=' +
    encodeURI(company_name)
  return page + query
}

export function referralInviteLink(user) {
  let page = process.env.REACT_APP_BACKEND_APP_BASE_URL + '/auth/register'
  let query = '?referral=' + user.company.public_id
  return page + query
}

export const cartesian = (...a) =>
  a.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())))

export function titleize(str) {
  return (str || '').replace(/\w\S*/g, (w) =>
    w.replace(/^\w/, (c) => c.toUpperCase())
  )
}

export const chunkArray = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )

export const reactTableSortAndFilter = (data, queryParam) => {
  const sort = Object.keys(queryParam).find((i) => {
    return i.startsWith('sort')
  })
  const sortField = sort ? sort.match(/\[(.*?)\]/)[1] : null
  const sortValue = sort ? queryParam[sort] : null
  const filter = Object.keys(queryParam).find((i) => {
    return i.startsWith('gfilter')
  })
  const filterField = filter ? filter.match(/\[(.*?)\]/)[1] : null
  const filterValue = filter
    ? queryParam[filter].replaceAll('%', '').toLowerCase()
    : null
  let results = data
  if (sortField) {
    results = results?.sort(sortByField('type', sortValue === 'desc', String))
  }
  if (filterField) {
    results = results?.filter((t) =>
      t.target.toLowerCase().includes(filterValue)
    )
  }
  if (results?.length > 0) {
    results = chunkArray(results, queryParam.per_page)[queryParam.page - 1]
  }
  const pages = Math.ceil(
    (filterField ? results?.length : data?.length) / queryParam.per_page
  )
  const total = filterField ? results?.length : data?.length
  return [results, pages, total]
}

const isPropValuesEqual = (subject, target, propNames) =>
  propNames.every((propName) => subject[propName] === target[propName])

export const uniqueListOfObjects = (items, propNames) => {
  const propNamesArray = Array.from(propNames)

  return items.filter(
    (item, index, array) =>
      index ===
      array.findIndex((foundItem) =>
        isPropValuesEqual(foundItem, item, propNamesArray)
      )
  )
}

export const freeUser = (user) => {
  const company = user.company
  const stripe_customer_id = user.company.stripe_customer_id
  const subs = user.company.stripe_subscription_items
  if (
    [
      'd49bc0d2-5ecd-43af-9ddd-ffe6ea7f0301',
      'ccda45de-9d76-4df6-bbfe-426c8f134739', // SI
      '57abdb58-2404-49a3-90d1-6f5a50ba7eeb',
      '56ffe675-1785-45cf-8535-bb55688a28f6', // Bohana
      'ad7d440e-258d-4e08-b2c9-15e7c9d48759', // Alpakitas
      'b26830de-ac99-4570-a3e5-6029d4d65129', // Waku Inc
      'd09920e8-e30b-4f85-b898-1d93d55654b4', // PNK Solutions
      'e0cbb21f-66e1-4be1-8d55-ef696efb5825', // BEAUTOLOGY LAB
      'c84a371b-6f1f-4e88-86ac-0458572e1f8d', // Element 26
      '740b9b54-3233-4ba1-ad81-d84cba5f87b7',
      'bf857904-d368-4888-a74f-cb24596fc9ea', // Pet Impact
      'efa2a80f-307f-4396-bf78-147340976c13', // BestLine
      '3551542c-a106-4de1-99b4-f7aeb1deeda2', // The Last Coat
      '8cfa6a76-8141-4285-b4a5-63e496716c82', // DeNomad
      '2c7fe24a-1a95-4a85-ba1e-c2ef3a2a8b18', // Elevate
      '0bf35a0e-903d-4162-a37b-faa4b459e36e', // Take Stock
      'eacc9cc8-a1a2-4acd-ba54-4c41467edf0f', // Nudie Snacks
      '9be7f3a9-0263-4015-8e41-761f3636eba1', // Aduna
      '955da496-98c9-4d0f-af74-c9239361e758', // MOJO
      'c8c3fafa-8280-435c-85ae-2f9abc7cfe44', // Titan
      '487e6f0e-3b73-473b-9c9e-dbd47d9ab9c4', // DSquared
      '721bc2b5-c606-44c2-9cf3-3f89151e3c46', // Profitable Pineapple Ads - Jordan
      'dbe0b655-4d76-4e8a-bb3b-b80a8ffd1db1', // Profitable Pineapple Ads - Christopher
      '4cdebbd2-b533-4723-a4dc-3d9982a2bf2c', // Profitable Pineapple Ads - Damien
      '335c370a-57db-40a0-b4fd-82159fb061f7', // Profitable Pineapple Ads - Alex
      '52cb8e98-4268-4b97-934c-f4cd0bb08507', // Lumos
      '322a261e-ef69-4b50-907f-c5114035ff07', // Wholey Moly
      '7eec5a39-ef17-4932-8adc-d0abc5a87086', // PBN'J Games
      '9d3ed2f5-3af7-430e-af18-5b0ab08e4198', // SmashCommerce
      '07617a50-e0c9-45f2-bf8e-396918ef3d74', // Profitable Pineapple Ads - DFY
      '46253841-212d-4a3e-bd22-4a0c62bb6fd3', // New Kings Coffee
      '9a2104e4-52b6-48f5-a521-4a353141f09c', // Vaultskin
      'dd3ec670-27c5-4dc5-9135-0f80dc4c94c3', // Procure Trading
    ].includes(company.public_id)
  ) {
    // omg so bad
    return false
  } else {
    if (stripe_customer_id && subs?.length > 0) {
      return (
        subs.filter((sub) =>
          ['active', 'trialing', 'past_due'].includes(sub.subscription_status)
        )?.length === 0
      )
    } else {
      return true
    }
  }
}

// Select options for ppc audit generation tool form
export const budgetOptions = [
  { value: 'More than $1m', label: 'More than $1m' },
  { value: '$500k - $1m', label: '$500k - $1m' },
  { value: '$200k - $500k', label: '$200k - $500k' },
  { value: '$100k - $200k', label: '$100k - $200k' },
  { value: '$50k - $100k', label: '$50k - $100k' },
  { value: '$20k - $50k', label: '$20k - $50k' },
  { value: '$10k - $20k', label: '$10k - $20k' },
  { value: '$5k - $10k', label: '$5k - $10k' },
  { value: '$1k - $5k', label: '$1k - $5k' },
  { value: 'Below $1k', label: 'Below $1k' },
]

export const businessTypes = [
  { value: 'brand', label: 'Brand' },
  { value: 'agency', label: 'Agency' },
]

export const marketplaces = [
  {
    value: {
      sales_channel: 'amazon.com',
      region_code: 'na',
      marketplace_id: 'ATVPDKIKX0DER',
      region: 'North America',
      primary_marketplace: 'United States',
    },
    label: 'amazon.com',
  },
  {
    value: {
      sales_channel: 'amazon.ca',
      region_code: 'na',
      marketplace_id: 'A2EUQ1WTGCTBG2',
      region: 'North America',
      primary_marketplace: 'Canada',
    },
    label: 'amazon.ca',
  },
  {
    value: {
      sales_channel: 'amazon.co.uk',
      region_code: 'eu',
      marketplace_id: 'A1F83G8C2ARO7P',
      region: 'Europe',
      primary_marketplace: 'United Kingdom',
    },
    label: 'amazon.co.uk',
  },
  {
    value: {
      sales_channel: 'amazon.de',
      region_code: 'eu',
      marketplace_id: 'A1PA6795UKMFR9',
      region: 'Europe',
      primary_marketplace: 'Germany',
    },
    label: 'amazon.de',
  },
  {
    value: {
      sales_channel: 'amazon.fr',
      region_code: 'eu',
      marketplace_id: 'A13V1IB3VIYZZH',
      region: 'Europe',
      primary_marketplace: 'France',
    },
    label: 'amazon.fr',
  },
  {
    value: {
      sales_channel: 'amazon.it',
      region_code: 'eu',
      marketplace_id: 'APJ6JRA9NG5V4',
      region: 'Europe',
      primary_marketplace: 'Italy',
    },
    label: 'amazon.it',
  },
  {
    value: {
      sales_channel: 'amazon.es',
      region_code: 'eu',
      marketplace_id: 'A1RKKUPIHCS9HS',
      region: 'Europe',
      primary_marketplace: 'Spain',
    },
    label: 'amazon.es',
  },
  {
    value: {
      sales_channel: 'amazon.com.mx',
      region_code: 'na',
      marketplace_id: 'A1AM78C64UM0Y8',
      region: 'North America',
      primary_marketplace: 'Mexico',
    },
    label: 'amazon.com.mx',
  },
  {
    value: {
      sales_channel: 'amazon.in',
      region_code: 'eu',
      marketplace_id: 'A21TJRUUN4KGV',
      region: 'Europe',
      primary_marketplace: 'India',
    },
    label: 'amazon.in',
  },
  {
    value: {
      sales_channel: 'amazon.ae',
      region_code: 'eu',
      marketplace_id: 'A2VIGQ35RCS4UG',
      region: 'Europe',
      primary_marketplace: 'United Arab Emirates',
    },
    label: 'amazon.ae',
  },
  {
    value: {
      sales_channel: 'amazon.sg',
      region_code: 'fe',
      marketplace_id: 'A19VAU5U5O7RUS',
      region: 'Far East',
      primary_marketplace: 'Singapore',
    },
    label: 'amazon.sg',
  },
  {
    value: {
      sales_channel: 'amazon.nl',
      region_code: 'eu',
      marketplace_id: 'A1805IZSGTT6HS',
      region: 'Europe',
      primary_marketplace: 'Netherlands',
    },
    label: 'amazon.nl',
  },
  {
    value: {
      sales_channel: 'amazon.se',
      region_code: 'eu',
      marketplace_id: 'A2NODRKZP88ZB9',
      region: 'Europe',
      primary_marketplace: 'Sweden',
    },
    label: 'amazon.se',
  },
  {
    value: {
      sales_channel: 'amazon.com.br',
      region_code: 'na',
      marketplace_id: 'A2Q3Y263D00KWC',
      region: 'North America',
      primary_marketplace: 'Brazil',
    },
    label: 'amazon.com.br',
  },
  {
    value: {
      sales_channel: 'amazon.be',
      region_code: 'eu',
      marketplace_id: 'AMEN7PMS3EDWL',
      region: 'Europe',
      primary_marketplace: 'Belgium',
    },
    label: 'amazon.be',
  },
  {
    value: {
      sales_channel: 'amazon.co.za',
      region_code: 'eu',
      marketplace_id: 'AE08WJ6YKNBMC',
      region: 'Europe',
      primary_marketplace: 'South Africa',
    },
    label: 'amazon.co.za',
  },
  {
    value: {
      sales_channel: 'amazon.pl',
      region_code: 'eu',
      marketplace_id: 'A1C3SOZRARQ6R3',
      region: 'Europe',
      primary_marketplace: 'Poland',
    },
    label: 'amazon.pl',
  },
  {
    value: {
      sales_channel: 'amazon.eg',
      region_code: 'eu',
      marketplace_id: 'ARBP9OOSHTCHU',
      region: 'Europe',
      primary_marketplace: 'Egypt',
    },
    label: 'amazon.eg',
  },
  {
    value: {
      sales_channel: 'amazon.com.tr',
      region_code: 'eu',
      marketplace_id: 'A33AVAJ2PDY3EV',
      region: 'Europe',
      primary_marketplace: 'Turkey',
    },
    label: 'amazon.com.tr',
  },
  {
    value: {
      sales_channel: 'amazon.sa',
      region_code: 'eu',
      marketplace_id: 'A17E79C6D8DWNP',
      region: 'Europe',
      primary_marketplace: 'Saudi Arabia',
    },
    label: 'amazon.sa',
  },
  {
    value: {
      sales_channel: 'amazon.com.au',
      region_code: 'fe',
      marketplace_id: 'A39IBJ37TRP1C6',
      region: 'Far East',
      primary_marketplace: 'Australia',
    },
    label: 'amazon.com.au',
  },
  {
    value: {
      sales_channel: 'amazon.co.jp',
      region_code: 'fe',
      marketplace_id: 'A1VC38T7YXB528',
      region: 'Far East',
      primary_marketplace: 'Japan',
    },
    label: 'amazon.co.jp',
  },
]

export const getMarketplaceInfo = (marketplace_id) => {
  const marketplace = marketplaces.find(
    (market) => market.value.marketplace_id === marketplace_id
  )

  if (marketplace) {
    return {
      region: marketplace.value.region,
      primary_marketplace: marketplace.value.primary_marketplace,
    }
  }

  return null
}

export const categories = [
  { value: 'Appliances', label: 'Appliances' },
  { value: 'Arts, Crafts & Sewing', label: 'Arts, Crafts & Sewing' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Baby', label: 'Baby' },
  { value: 'Beauty & Personal Care', label: 'Beauty & Personal Care' },
  { value: 'Camera & Photo', label: 'Camera & Photo' },
  { value: 'Cell Phones & Accessories', label: 'Cell Phones & Accessories' },
  { value: 'Clothing, Shoes & Jewelry', label: 'Clothing, Shoes & Jewelry' },
  { value: 'Computers & Accessories', label: 'Computers & Accessories' },
  { value: 'Electronics', label: 'Electronics' },
  { value: 'Grocery & Gourmet Food', label: 'Grocery & Gourmet Food' },
  { value: 'Health & Household', label: 'Health & Household' },
  { value: 'Home & Kitchen', label: 'Home & Kitchen' },
  { value: 'Industrial & Scientific', label: 'Industrial & Scientific' },
  { value: 'Kitchen & Dining', label: 'Kitchen & Dining' },
  { value: 'Musical Instruments', label: 'Musical Instruments' },
  { value: 'Office Products', label: 'Office Products' },
  { value: 'Patio, Lawn & Garden', label: 'Patio, Lawn & Garden' },
  { value: 'Pet Supplies', label: 'Pet Supplies' },
  { value: 'Sports & Outdoors', label: 'Sports & Outdoors' },
  { value: 'Tools & Home Improvement', label: 'Tools & Home Improvement' },
  { value: 'Toys & Games', label: 'Toys & Games' },
]
