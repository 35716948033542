import React from 'react'
import ContentLoader from 'react-content-loader'

const PlainImageLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      viewBox="0 0 100 100"
      backgroundOpacity={0}
      foregroundColor="#ffffff"
      foregroundOpacity={0.1}
      {...props}
    >
      <rect x="0%" y="0%" rx="3" ry="3" width="100%" height="100%" />
    </ContentLoader>
  )
}

export default PlainImageLoader
