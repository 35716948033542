import React, { useState, useMemo, Fragment } from 'react'
import classNames from 'classnames'
// reactstrap components
import { Button, UncontrolledTooltip } from 'reactstrap'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import ReactTable from 'components/ReactTable/ReactTable.js'
import { DateTime } from 'luxon'
import moment from 'moment-timezone'
import ProductCost from './ProductCost'
import apiProducts from 'services/api/Product'
import { useQueryClient } from 'react-query'
import useAlert from 'services/hooks/useAlert'
import { useHistory } from 'react-router-dom'

const ProductCosts = ({ product }) => {
  const today = new moment()
    .tz(product.marketplace_timezone)
    .startOf('day')
    .format()
  const { addAlert } = useAlert()
  const queryClient = useQueryClient()
  const history = useHistory()

  const emptyProductCost = {
    public_id: '',
    cost_of_goods_per_unit: '',
    shipping_cost_per_unit: '',
    misc_cost_per_unit: '',
    total_cost_per_unit: '',
    effective_from: today,
  }
  const [productCost, setProductCost] = useState(emptyProductCost)
  const [showProductCostForm, setShowProductCostForm] = useState(false)
  const [deleteProductCostWarningAlert, setDeleteProductCostWarningAlert] =
    useState(null)

  const deleteProductCostWarning = (productCostPublicId) => {
    setDeleteProductCostWarningAlert(
      <ReactBSAlert
        warning
        style={{ display: 'block', marginTop: '-100px' }}
        title="Are you sure?"
        onConfirm={() => deleteProductCostWarningConfirm(productCostPublicId)}
        onCancel={() => deleteProductCostWarningHideAlert()}
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        {
          'Both Current and Historical costs are used by the Autron Ad Engine. For best performance, only delete costs if they were made in error.'
        }
      </ReactBSAlert>
    )
  }
  const deleteProductCostWarningConfirm = async (productCostPublicId) => {
    // delete product cost via api
    const response = await apiProducts
      .deleteSingleCost(product.public_id, productCostPublicId)
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      queryClient.invalidateQueries('GET/sellers/')
      addAlert('success', 'tim-icons icon-check-2', response.data.message, null)
    } else {
      if (response) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response.data.message,
          null
        )
      }
    }
    setDeleteProductCostWarningAlert(null)
  }
  const deleteProductCostWarningHideAlert = () => {
    setDeleteProductCostWarningAlert(null)
  }

  const productCostColumns = useMemo(
    () => [
      {
        Header: 'cost of goods',
        accessor: 'cost_of_goods_per_unit',
        sortable: false,
        Cell: (props) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(props.value),
      },
      {
        Header: 'inbound shipping',
        accessor: 'shipping_cost_per_unit',
        sortable: false,
        Cell: (props) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(props.value),
      },
      {
        Header: 'miscellaneous cost',
        accessor: 'misc_cost_per_unit',
        sortable: false,
        Cell: (props) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(props.value),
      },
      {
        Header: 'total cost',
        accessor: 'total_cost_per_unit',
        sortable: false,
        Cell: (props) =>
          new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: product.currency,
          }).format(props.value),
      },
      {
        Header: 'effective from',
        accessor: 'effective_from',
        sortable: false,
        Cell: (props) =>
          new DateTime.fromISO(props.value)
            .setZone(product.marketplace_timezone)
            .setLocale('en-US')
            .toLocaleString(DateTime.DATE_MED),
      },
      {
        Header: 'effective to',
        accessor: 'effective_to',
        sortable: false,
        Cell: (props) =>
          props.row.values['state'] === 'archived'
            ? new DateTime.fromISO(props.value)
                .setZone(product.marketplace_timezone)
                .setLocale('en-US')
                .toLocaleString(DateTime.DATE_MED)
            : 'Present',
      },
      {
        Header: (
          <Fragment>
            Status
            <Button
              color="link"
              id="product-cost-status-header-tooltip"
              title=""
              type="button"
              className="p-0 m-0 mt-n1 pl-2"
            >
              <i className="fas fa-question-circle"></i>
            </Button>
            <UncontrolledTooltip
              delay={0}
              target="product-cost-status-header-tooltip"
            >
              Both Current and Historical costs are used by the Autron Ad
              Engine. For best performance, please only delete or edit costs if
              they were made in error.
            </UncontrolledTooltip>
          </Fragment>
        ),
        accessor: 'state',
        sortable: false,
        Cell: (props) => (
          <span
            className={classNames(
              'status_cell',
              props.value === 'enabled' ? 'status-success' : 'status-warning'
            )}
          >
            {props.value === 'enabled' ? 'current' : 'historical'}
          </span>
        ),
      },
      {
        Header: 'actions',
        accessor: 'actions',
        sortable: false,
        Cell: (props) => (
          <Fragment>
            <div className="actions-right">
              <Button
                onClick={(e) => editClick(props.row, e)}
                id={'product_cost_table_edit_btn_' + props.row.index}
                color="warning"
                size="sm"
                className={classNames('btn-icon btn-link like', {
                  'btn-neutral': props.row.index < 0,
                })}
              >
                <i className="tim-icons icon-pencil" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target={'product_cost_table_edit_btn_' + props.row.index}
              >
                Edit
              </UncontrolledTooltip>{' '}
              <Button
                onClick={(e) =>
                  deleteProductCostWarning(props.row.original['public_id'])
                }
                id={'product_cost_table_delete_btn_' + props.row.index}
                color="danger"
                size="sm"
                className={classNames('btn-icon btn-link like', {
                  'btn-neutral': props.row.index < 0,
                })}
              >
                <i className="tim-icons icon-simple-remove" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target={'product_cost_table_delete_btn_' + props.row.index}
              >
                Delete
              </UncontrolledTooltip>
            </div>
          </Fragment>
        ),
      },
    ],
    []
  )

  const editClick = (row, e) => {
    if (!e.target.className.split(' ').includes('icon-simple-remove')) {
      // handle unintentional row click when clicking delete
      setProductCost({
        public_id: row.original['public_id'], // note original
        cost_of_goods_per_unit: row.values['cost_of_goods_per_unit'] || 0,
        shipping_cost_per_unit: row.values['shipping_cost_per_unit'] || 0,
        misc_cost_per_unit: row.values['misc_cost_per_unit'] || 0,
        total_cost_per_unit: row.values['total_cost_per_unit'] || 0,
        effective_from: row.values['effective_from'],
      })
      setShowProductCostForm(true)
    }
  }

  return (
    <>
      {deleteProductCostWarningAlert}
      {showProductCostForm ? (
        <ProductCost
          productCost={productCost}
          productPublicId={product.public_id}
          currency={product.currency}
          timezone={product.marketplace_timezone}
          setShowComponent={setShowProductCostForm}
        />
      ) : (
        <Fragment>
          <p className="card-description mb-3">
            Input unit cost of goods and inbound shipping costs so Autron can
            calculate profits and optimize Ad spend.
          </p>
          {product.product_costs?.length > 0 ? (
            <ReactTable
              columns={productCostColumns}
              data={product.product_costs}
              loading={false}
              total={product.product_costs?.length}
              onRowClick={(row, e) => editClick(row, e)}
            />
          ) : null}
          <Button
            className="btn-wd btn btn-just-icon"
            color="primary"
            onClick={(e) => {
              setProductCost(emptyProductCost)
              setShowProductCostForm(true)
            }}
          >
            Add Costs
          </Button>
          <Button
            color="primary"
            className={classNames('btn-simple')}
            onClick={(e) => history.push('/admin/products')}
          >
            Back
          </Button>
        </Fragment>
      )}
    </>
  )
}

export default ProductCosts
