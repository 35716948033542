import React, { cloneElement } from 'react'
import { Redirect, Route } from 'react-router-dom'
import apiAuth from 'services/api/Auth'
import { useQuery } from 'react-query'
import { segmentIdentify } from 'services/Segment'

const AuthRoute = ({ path, layout }) => {
  const {
    isLoading,
    error,
    data: user,
  } = useQuery('GET/auth/user', () => apiAuth.user(), {
    retry: false,
    //staleTime: 60 * 1000 * 1, // 1 minutes
    onSuccess: () => {
      // Update Segment Identify user upon loading any auth page (too many calls this way?)
      if (user) {
        segmentIdentify(user)
      }
    },
  })

  if (isLoading) {
    return null
  }

  //if ( error ) { return <Redirect to="/auth/login" /> }

  return typeof user != 'undefined' ? (
    <Route
      path={path}
      render={(props) => cloneElement(layout, { props, user })}
    />
  ) : (
    <Redirect to="/auth/login" />
  )
}

export default AuthRoute
