import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classnames from 'classnames'
// reactstrap components
import {
  Spinner,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  CardSubtitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  NavLink,
} from 'reactstrap'
import apiUsers from 'services/api/User'
import apiAuth from 'services/api/Auth'
import { inputFieldChange } from 'services/Utils'
import useAlert from 'services/hooks/useAlert'
import cardPrimaryImg from 'assets/img/card-primary.png'

const Register = () => {
  const params = new URLSearchParams(useLocation().search)

  const [firstname, setFirstname] = useState(params.get('firstname') || '')
  const [lastname, setLastname] = useState(params.get('lastname') || '')
  const [company, setCompany] = useState(params.get('company') || '')
  const [email, setEmail] = useState(params.get('email') || '')
  const [password, setPassword] = useState('')
  const [, setTerms] = useState('')
  const [, setExternalManagement] = useState('')

  const [firstnameFocus, setFirstnameFocus] = useState(false)
  const [lastnameFocus, setLastnameFocus] = useState(false)
  const [companyFocus, setCompanyFocus] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)
  const [passwordFocus, setPasswordFocus] = useState(false)

  const [firstnameValidationState, setFirstnameValidationState] = useState(
    params.get('firstname') ? 'has-success' : ''
  )
  const [lastnameValidationState, setLastnameValidationState] = useState(
    params.get('lastname') ? 'has-success' : ''
  )
  const [companyValidationState, setCompanyValidationState] = useState(
    params.get('company') ? 'has-success' : ''
  )
  const [emailValidationState, setEmailValidationState] = useState(
    params.get('email') ? 'has-success' : ''
  )
  const [passwordValidationState, setPasswordValidationState] = useState('')
  const [termsValidationState, setTermsValidationState] = useState('')
  const [
    externalManagementValidationState,
    setExternalManagementValidationState,
  ] = useState('')

  const [loading, setLoading] = useState(false)

  const [showVerifyCard, setShowVerifyCard] = useState(false)

  const { addAlert } = useAlert()

  const [managingCompanyPublicId] = useState(params.get('managing_company'))
  const [managingCompanyName] = useState(params.get('managing_company_name'))

  // post user data to api if everything validated
  const registerClick = async (e) => {
    if (
      firstnameValidationState === 'has-success' &&
      lastnameValidationState === 'has-success' &&
      companyValidationState === 'has-success' &&
      emailValidationState === 'has-success' &&
      passwordValidationState === 'has-success' &&
      termsValidationState === 'has-success' &&
      (externalManagementValidationState === 'has-success' ||
        !managingCompanyPublicId)
    ) {
      setLoading(true)
      const managing_company_public_id = managingCompanyPublicId
      const user = {
        firstname,
        lastname,
        company,
        email,
        password,
        managing_company_public_id,
      }
      // create user via api
      const response = await apiUsers
        .createSingle(JSON.stringify(user))
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response?.data?.message,
          null
        )
        setLoading(false)
        setShowVerifyCard(true)
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response?.data?.message,
            null
          )
        }
        e.preventDefault()
      }
      setLoading(false)
    } else {
      if (firstnameValidationState === '') {
        setFirstnameValidationState('has-danger')
      }
      if (lastnameValidationState === '') {
        setLastnameValidationState('has-danger')
      }
      if (companyValidationState === '') {
        setCompanyValidationState('has-danger')
      }
      if (emailValidationState === '') {
        setEmailValidationState('has-danger')
      }
      if (passwordValidationState === '') {
        setPasswordValidationState('has-danger')
      }
      if (termsValidationState === '') {
        setTermsValidationState('has-danger')
      }
      if (externalManagementValidationState === '') {
        setExternalManagementValidationState('has-danger')
      }
      e.preventDefault()
    }
  }

  const resendEmailClick = async (e) => {
    if (email) {
      setLoading(true)
      const auth = { email }
      const response = await apiAuth
        .verification_email(JSON.stringify(auth))
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response.data.message,
            null
          )
        }
      }
      setLoading(false)
    } else {
      e.preventDefault()
    }
  }

  useEffect(() => {
    document.body.classList.toggle('register-page')
    return () => {
      document.body.classList.toggle('register-page')
    }
  }, [])

  return (
    <>
      <div className="content">
        {showVerifyCard ? (
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form className="form form-auth">
                  <Card className="card-lock card-white card-verify-email">
                    <CardHeader>
                      <CardTitle tag="h3">Please verify your email</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <CardSubtitle tag="p">
                        You’re almost there {firstname}! We sent an email to{' '}
                        <strong>{email}</strong>
                      </CardSubtitle>
                      <CardSubtitle tag="p">
                        Just click on the link in that email to complete your
                        registration. If you don't see it, you may need to{' '}
                        <strong>check your spam</strong> folder.
                      </CardSubtitle>
                    </CardBody>
                    <CardFooter>
                      <CardSubtitle tag="p">
                        Still can’t find the email?
                      </CardSubtitle>
                      <Button
                        className={classnames('btn-round btn-wd', {
                          disabled: loading,
                        })}
                        color="primary"
                        onClick={(e) => resendEmailClick(e)}
                        size="sm"
                      >
                        {loading === true ? (
                          <Spinner size="sm" role="status"></Spinner>
                        ) : (
                          'Resend Email'
                        )}
                      </Button>
                      <div className="pull-right">
                        <h6>
                          <a
                            className="link footer-link"
                            rel="noopener noreferrer"
                            href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                            target="_blank"
                          >
                            Need help?
                          </a>
                        </h6>
                      </div>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col className="ml-auto" md="5">
                <div className="info-area info-horizontal mt-5">
                  <div className="icon icon-warning">
                    <i className="tim-icons icon-atom" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">AI-powered PPC</h3>
                    <p className="description">
                      Your AI-powered ad engine by your side - simulating,
                      predicting and delivering decisions for you.
                    </p>
                  </div>
                </div>
                <div className="info-area info-horizontal">
                  <div className="icon icon-primary">
                    <i className="tim-icons icon-refresh-02" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">Algorithmic Bidding</h3>
                    <p className="description">
                      Be confident your bids are always optimal to maximize ROI,
                      control ACoS and achieve your advertising goals.
                    </p>
                  </div>
                </div>
                <div className="info-area info-horizontal">
                  <div className="icon icon-info">
                    <i className="tim-icons icon-spaceship" />
                  </div>
                  <div className="description">
                    <h3 className="info-title">Growth on Autopilot</h3>
                    <p className="description">
                      With fully automated campaigns, optimised bids and keyword
                      harvesting - sit back and relax as we do the heavy
                      lifting.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="mr-auto" md="7">
                <Card className="card-register card-white">
                  <CardHeader>
                    <CardImg alt="..." src={cardPrimaryImg} />
                    <CardTitle tag="h4">Register</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Form className="form form-auth">
                      <InputGroup
                        className={classnames(firstnameValidationState, {
                          'input-group-focus': firstnameFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="First Name"
                          type="text"
                          autoComplete="given-name"
                          onFocus={(e) => setFirstnameFocus(true)}
                          onBlur={(e) => setFirstnameFocus(false)}
                          onChange={(e) =>
                            inputFieldChange(
                              e,
                              setFirstname,
                              setFirstnameValidationState,
                              'length',
                              1
                            )
                          }
                          defaultValue={firstname}
                        />
                        {firstnameValidationState === 'has-danger' ? (
                          <label className="error">
                            This field is required.
                          </label>
                        ) : null}
                      </InputGroup>
                      <InputGroup
                        className={classnames(lastnameValidationState, {
                          'input-group-focus': lastnameFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-caps-small" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Last Name"
                          type="text"
                          autoComplete="family-name"
                          onFocus={(e) => setLastnameFocus(true)}
                          onBlur={(e) => setLastnameFocus(false)}
                          onChange={(e) =>
                            inputFieldChange(
                              e,
                              setLastname,
                              setLastnameValidationState,
                              'length',
                              1
                            )
                          }
                          defaultValue={lastname}
                        />
                        {lastnameValidationState === 'has-danger' ? (
                          <label className="error">
                            This field is required.
                          </label>
                        ) : null}
                      </InputGroup>
                      <InputGroup
                        className={classnames(companyValidationState, {
                          'input-group-focus': companyFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-badge" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Company"
                          type="text"
                          autoComplete="organization"
                          onFocus={(e) => setCompanyFocus(true)}
                          onBlur={(e) => setCompanyFocus(false)}
                          onChange={(e) =>
                            inputFieldChange(
                              e,
                              setCompany,
                              setCompanyValidationState,
                              'length',
                              1
                            )
                          }
                          defaultValue={company}
                        />
                        {companyValidationState === 'has-danger' ? (
                          <label className="error">
                            This field is required.
                          </label>
                        ) : null}
                      </InputGroup>
                      <InputGroup
                        className={classnames(emailValidationState, {
                          'input-group-focus': emailFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="text"
                          autoComplete="email"
                          onFocus={(e) => setEmailFocus(true)}
                          onBlur={(e) => setEmailFocus(false)}
                          onChange={(e) =>
                            inputFieldChange(
                              e,
                              setEmail,
                              setEmailValidationState,
                              'email'
                            )
                          }
                          defaultValue={email}
                        />
                        {emailValidationState === 'has-danger' ? (
                          <label className="error">
                            Please enter a valid email address.
                          </label>
                        ) : null}
                      </InputGroup>
                      <InputGroup
                        className={classnames(passwordValidationState, {
                          'input-group-focus': passwordFocus,
                        })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          autoComplete="new-password"
                          onFocus={(e) => setPasswordFocus(true)}
                          onBlur={(e) => setPasswordFocus(false)}
                          onChange={(e) =>
                            inputFieldChange(
                              e,
                              setPassword,
                              setPasswordValidationState,
                              'password',
                              10
                            )
                          }
                        />
                        {passwordValidationState === 'has-danger' ? (
                          <label className="error">
                            Please ensure password is at least 10 characters and
                            non-numeric.
                          </label>
                        ) : null}
                      </InputGroup>
                      {managingCompanyPublicId ? (
                        <FormGroup
                          check
                          className={classnames(
                            externalManagementValidationState,
                            'text-left'
                          )}
                        >
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={(e) =>
                                inputFieldChange(
                                  e,
                                  setExternalManagement,
                                  setExternalManagementValidationState,
                                  'checkbox'
                                )
                              }
                            />
                            <span className="form-check-sign" />I agree to{' '}
                            <span className="font-weight-bold">
                              {managingCompanyName}
                            </span>{' '}
                            managing Autron on my behalf.
                            {externalManagementValidationState ===
                            'has-danger' ? (
                              <label className="error">
                                Please agree to external account management.
                              </label>
                            ) : null}
                          </Label>
                        </FormGroup>
                      ) : null}
                      <FormGroup
                        check
                        className={classnames(
                          termsValidationState,
                          'text-left'
                        )}
                      >
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={(e) =>
                              inputFieldChange(
                                e,
                                setTerms,
                                setTermsValidationState,
                                'checkbox'
                              )
                            }
                          />
                          <span className="form-check-sign" />I agree to the{' '}
                          <NavLink
                            className="text-primary d-inline p-0"
                            tag={Link}
                            to="/terms"
                          >
                            terms of service.
                          </NavLink>
                          {termsValidationState === 'has-danger' ? (
                            <label className="error">
                              Please agree to the terms of service.
                            </label>
                          ) : null}
                        </Label>
                      </FormGroup>
                    </Form>
                  </CardBody>
                  <CardFooter>
                    <Button
                      className={classnames('btn-round btn-wd', {
                        disabled: loading,
                      })}
                      color="primary"
                      onClick={(e) => registerClick(e)}
                      size="md"
                    >
                      {loading === true ? (
                        <Spinner size="sm" role="status"></Spinner>
                      ) : (
                        'Register'
                      )}
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  )
}

export default Register
