import React, { useState, useEffect, useCallback, useContext } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  Button,
  Alert,
} from 'reactstrap'
import autronLogoImg from 'assets/img/autron-logo.png'
import autronLogoReverseImg from 'assets/img/autron-logo-reverse.png'
import ThemeContext from 'services/providers/ThemeContext'

const MainNavbar = () => {
  const { lightMode } = useContext(ThemeContext)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [color, setColor] = useState('navbar-transparent')
  const autronLogo = lightMode ? autronLogoReverseImg : autronLogoImg
  const [logo, setLogo] = useState(autronLogo)

  const [banner, setBanner] = useState(false)

  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen && !lightMode) {
      setColor('navbar-transparent')
      setLogo(autronLogoImg)
    } else if (!lightMode) {
      setColor('bg-white')
      setLogo(autronLogoReverseImg)
    } else if (lightMode) {
      setColor('bg-white')
      setLogo(autronLogoReverseImg)
    }
    setCollapseOpen(!collapseOpen)
  }
  const closeCollapse = () => {
    if (collapseOpen === true) {
      toggleCollapse()
    }
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = useCallback(() => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor('bg-white')
    } else {
      setColor('navbar-transparent')
    }
  }, [collapseOpen])

  useEffect(() => {
    window.addEventListener('resize', updateColor)
    return () => {
      window.removeEventListener('resize', updateColor)
    }
  }, [updateColor])

  return (
    <>
      {banner ? (
        <Alert className="top-banner text-center mb-0">
          <NavLink
            href={
              process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/founding-seller'
            }
            className="nav-link p-0"
          >
            <span role="img" aria-label="hello">
              👋
            </span>{' '}
            Get Early Access
          </NavLink>
        </Alert>
      ) : null}
      <Navbar
        className={classnames(
          'main-navbar navbar-absolute',
          banner ? 'fixed-top-banner' : 'fixed-top',
          color
        )}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <NavbarBrand
              href={process.env.REACT_APP_FRONTEND_APP_BASE_URL}
              className=""
              onClick={closeCollapse}
            >
              <div className="logo-img">
                <img alt="..." className="" src={logo} />
              </div>
              <div>autron</div>
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav navbar className="ml-auto">
              <NavItem>
                <NavLink
                  href={
                    process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/pricing'
                  }
                  className="nav-link"
                  onClick={closeCollapse}
                >
                  Pricing
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/blog'}
                  className="nav-link"
                  onClick={closeCollapse}
                >
                  Blog
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href={process.env.REACT_APP_HELP_CENTER_URL}
                  className="nav-link"
                  onClick={closeCollapse}
                >
                  Help
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  tag={Link}
                  to="/auth/login"
                  className="nav-link"
                  onClick={closeCollapse}
                >
                  Login
                </NavLink>
              </NavItem>
              <NavItem>
                <Button
                  className={classnames('btn-round')}
                  color="primary"
                  tag={Link}
                  to="/auth/register"
                  size="md"
                  onClick={closeCollapse}
                >
                  Get Started
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default MainNavbar
