import React, { useEffect, useState } from 'react'
import FormSubmittedImage from '../../assets/img/form_submit_image.jpg'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Label,
  Input,
  Spinner,
} from 'reactstrap'
import Select from 'react-select'
import PhoneInput from 'react-phone-input-2'
import {
  marketplaces,
  budgetOptions,
  categories,
  businessTypes,
} from 'services/Utils'
import useAlert from 'services/hooks/useAlert'
import { generateRandomString } from 'services/Utils'
import apiLeads from 'services/api/Leads'
import { pkceChallengeFromVerifier } from 'services/Utils'
import image1 from '../../assets/img/2022 US Innovation Award Finalist.png'
import image2 from '../../assets/img/amazon-spn-2.png'
import image3 from '../../assets/img/Verified partner badge.png'
import { verifyEmail } from 'services/Utils'
import Confetti from 'react-confetti'
import { getMarketplaceInfo } from 'services/Utils'

const PPCAudit = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    businessEmail: '',
    phoneDetails: {
      countryCode: '',
      phoneNumber: '',
    },
    companyName: '',
    adBudget: null,
    businessType: null,
    marketplace: null,
    category: null,
  })

  const { addAlert } = useAlert()
  const [validPhoneNumber, setValidPhoneNumber] = useState(true)
  const [connected, setConnected] = useState(false)
  const [stepNumber, setStepNumber] = useState(1)
  const [marketplaceId, setMarketplaceId] = useState(null)
  const [regionCode, setRegionCode] = useState(null)
  const [loading, setLoading] = useState(false)
  const [numberOfConfetti, setNumberOfConfetti] = useState(200)
  const validateForm = (requiredFields) => {
    if (formData.firstName === '') requiredFields.push('Firstname')

    if (formData.lastName === '') requiredFields.push('LastName')

    if (formData.businessEmail === '') requiredFields.push('Business Email')

    if (formData.companyName === '') requiredFields.push('Company Name')

    if (formData.adBudget === null) requiredFields.push('adBudget')

    if (formData.businessType === null) requiredFields.push('BusinessType')

    if (formData.marketplace === null) requiredFields.push('Marketplace')

    if (formData.category === null) requiredFields.push('Category')

    if (!validPhoneNumber) return false

    return true
  }

  useEffect(() => {
    if (connected) {
      const timer = setTimeout(() => setNumberOfConfetti(0), 3000)
      return () => clearTimeout(timer)
    }
  }, [connected])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const status = queryParams.get('status')
    const message = queryParams.get('message')

    if (
      status === 'success' &&
      message === 'Successfully connected to Amazon Advertising!'
    ) {
      setLoading(false)
      setStepNumber(3)
      setConnected(true)
      addAlert(
        'success',
        'tim-icons icon-check-2',
        'Successfully connected to Amazon Advertising!',
        null
      )
    } else if (status === 'fail') {
      setLoading(false)
      addAlert('danger', 'tim-icons icon-alert-circle-exc', message, null)
    }
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSelectChange = (selectedOption, metaData) => {
    const { name } = metaData
    if (name === 'marketplace') {
      setMarketplaceId(selectedOption.value.marketplace_id)
      setRegionCode(selectedOption.value.region_code)
      setFormData({
        ...formData,
        [name]: selectedOption.value.sales_channel,
      })
    } else {
      setFormData({
        ...formData,
        [name]: selectedOption.value,
      })
    }
  }

  const validatePhone = (phoneWithoutCountryCode) => {
    if (phoneWithoutCountryCode.length === 0) return
    const isValid = /^\d{9,}$/.test(phoneWithoutCountryCode)
    setValidPhoneNumber(isValid)
  }

  const handlePhoneChange = (value, country) => {
    const phoneWithoutCountryCode = value.slice(country.dialCode.length)
    setFormData({
      ...formData,
      phoneDetails: {
        countryCode: '+' + country.dialCode,
        phoneNumber: phoneWithoutCountryCode,
      },
    })
    validatePhone(phoneWithoutCountryCode)
  }

  const handleSubmit = () => {
    setLoading(true)
    let requiredFields = []

    const validForm = validateForm(requiredFields)

    if (
      validForm &&
      requiredFields.length === 0 &&
      verifyEmail(formData.businessEmail)
    ) {
      setStepNumber(2)
      const seller_public_id = crypto.randomUUID()
      const marketplaceRegion = getMarketplaceInfo(marketplaceId).region
      const lead = {
        firstname: formData.firstName,
        lastname: formData.lastName,
        phone:
          formData.phoneDetails.countryCode + formData.phoneDetails.phoneNumber,
        email: formData.businessEmail,
        company_name: formData.companyName,
        segment_event: 'PPC Audit Signed Up',
        marketplace: formData.marketplace,
        business_type: formData.businessType,
        seller_public_id: seller_public_id,
        monthly_ad_budget: formData.adBudget,
        category: formData.category,
        marketplace_id: marketplaceId,
        marketplace_region: marketplaceRegion,
      }
      apiLeads.createLeads(lead).then(async (res) => {
        let baseUrl
        var code_verifier = generateRandomString()
        var code_challenge = await pkceChallengeFromVerifier(code_verifier)

        if (regionCode === 'fe') {
          baseUrl = 'https://apac.account.amazon.com/ap/oa'
        } else if (regionCode === 'eu') {
          baseUrl = 'https://eu.account.amazon.com/ap/oa'
        } else {
          baseUrl = 'https://www.amazon.com/ap/oa'
        }
        var url = new URL(baseUrl)
        url.searchParams.append(
          'client_id',
          process.env.REACT_APP_LOGIN_WITH_AMAZON_CLIENT_ID
        )
        url.searchParams.append('scope', 'advertising::campaign_management')
        url.searchParams.append('response_type', 'code')
        url.searchParams.append(
          'redirect_uri',
          [
            process.env.REACT_APP_BACKEND_API_BASE_URL,
            '/auth/amazon-ads/ppc-audit',
          ].join('')
        )
        url.searchParams.append(
          'state',
          [regionCode, seller_public_id, code_verifier].join(' ')
        )
        url.searchParams.append('code_challenge', code_challenge)
        url.searchParams.append('code_challenge_method', 'S256')

        window.location = url
      })
    } else {
      setLoading(false)
      if (!verifyEmail(formData.businessEmail)) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          requiredFields.join(', ') + `Please enter valid email!`,
          null
        )
      }
      addAlert(
        'danger',
        'tim-icons icon-alert-circle-exc',
        requiredFields.join(', ') +
          `${requiredFields.length > 1 ? ' are' : ' is'} required!`,
        null
      )
    }
  }

  return (
    <>
      <div className="content">
        <Container>
          <Row className="flex-md-column flex-lg-row">
            <Col className="audit-description-container ml-auto" md="12" lg="5">
              <div className="description-title-container">
                <h2 className="description-title-1 m-0 title">
                  Get your Amazon PPC Audit{' '}
                  <h2 className="description-subtitle-1 title">
                    in three simple steps
                  </h2>
                </h2>
                <h2 className="description-title-2">in three simple steps</h2>
              </div>
              <p className="m-0">
                Uncover wasted ad spend and see how active your agency truly is
                with our Amazon Advertising audit tool. Identify missed keyword
                opportunities and more. It's 100% safe, secure, and free.
              </p>
              <p className="m-0">
                Just a few steps and you'll receive access to your analysis.
              </p>
              <div className="steps-tracker">
                <div className="step">
                  <div
                    className={`step-number-1 step-number ${
                      stepNumber >= 2
                        ? 'active-before active'
                        : stepNumber >= 1
                        ? 'active'
                        : ''
                    }`}
                  >
                    1
                  </div>
                  <p
                    className={`step-description ${
                      stepNumber >= 1 ? 'active' : ''
                    }`}
                  >
                    Confirm your company information
                  </p>
                </div>
                <div className="step">
                  <div
                    className={`step-number-2 step-number ${
                      stepNumber >= 2 ? 'active active-before' : ''
                    } ${stepNumber >= 3 ? 'active-after' : ''}`}
                  >
                    2
                  </div>
                  <p
                    className={`step-description ${
                      stepNumber >= 2 ? 'active' : ''
                    }`}
                  >
                    Import data from your Amazon Advertising account
                  </p>
                </div>
                <div className="step">
                  <div
                    className={`step-number-3 step-number ${
                      stepNumber >= 3 ? 'active' : ''
                    }`}
                  >
                    3
                  </div>
                  <p
                    className={`step-description ${
                      stepNumber >= 3 ? 'active' : ''
                    }`}
                  >
                    We generate your report & notify you when it is ready
                  </p>
                </div>
              </div>
              <div className="m-0 awards-image-container">
                <div className="awards-image-container-1">
                  <div className="innovation-finalist-image">
                    <img src={image1} width={220}></img>
                  </div>
                  <div className="amazon-spn-2">
                    <img src={image2}></img>
                  </div>
                </div>
                <div className="awards-image-container-2">
                  <div className="verified-partner-image">
                    <img src={image3} height={100}></img>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="mr-auto" md="12" lg="7">
              {connected ? (
                <Card className="card-white form-submitted-container">
                  <h2 className="title preparing-audit-title">
                    Your audit will be ready in 24 hours!
                  </h2>
                  <Confetti
                    className="confetti-explosion"
                    numberOfPieces={numberOfConfetti}
                    zIndex={100}
                    gravity={0.1}
                  />
                  <p>
                    Your amazon account has been connected & we are preparing
                    your report. We will <b>notify you via email</b> when the
                    report is ready.
                  </p>
                  <img src={FormSubmittedImage} width={400} height={350} />
                  <p>
                    While you wait you can head on to our blog, stacked with
                    resources that will help you get the most out of your Amazon
                    Advertising.
                  </p>
                  <Button
                    color="primary"
                    href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/blog'}
                  >
                    Visit our blog
                  </Button>
                </Card>
              ) : (
                <Card className="card-white audit-form-container">
                  <div className="audit-form-input-fields">
                    <div className="grid-item">
                      <Label>First Name</Label>
                      <Input
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="grid-item">
                      <Label>Last Name</Label>
                      <Input
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="grid-item">
                      <Label>Business Email</Label>
                      <Input
                        name="businessEmail"
                        value={formData.businessEmail}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="grid-item grid-item-phone-input">
                      <Label>Phone number</Label>
                      <PhoneInput
                        specialLabel=""
                        country={'us'}
                        inputClass="phone-input"
                        buttonClass="phone-dropdown-button"
                        searchClass="phone-dropdown-search"
                        inputStyle={{ width: '100%' }}
                        enableSearch={true}
                        disableSearchIcon={true}
                        searchPlaceholder="Search"
                        searchNotFound="No countries found"
                        inputProps={{
                          autoFocus: false,
                          name: 'phone',
                          required: true,
                        }}
                        value={formData.phoneNumber}
                        onChange={(value, country) =>
                          handlePhoneChange(value, country)
                        }
                      />
                      {!validPhoneNumber && (
                        <p className="invalid-phone-alert">
                          Please enter a valid phone number.
                        </p>
                      )}
                    </div>
                    <div className="grid-item">
                      <Label>Company Name</Label>
                      <Input
                        name="companyName"
                        value={formData.companyName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="grid-item">
                      <Label>Monthly Ad Budget</Label>
                      <Select
                        name="adBudget"
                        className="react-select primary w-100 select-input"
                        classNamePrefix="react-select"
                        placeholder={'- Select -'}
                        options={budgetOptions}
                        onChange={handleSelectChange}
                      />
                    </div>
                    <div className="grid-item grid-item-full">
                      <Label>Business Type</Label>
                      <Select
                        name="businessType"
                        className="react-select primary w-100 select-input"
                        classNamePrefix="react-select"
                        placeholder={'- Select -'}
                        style={{ width: '100%' }}
                        options={businessTypes}
                        onChange={handleSelectChange}
                      />
                    </div>
                    <div className="grid-item">
                      <Label>Select your marketplace</Label>
                      <Select
                        name="marketplace"
                        className="react-select primary w-100 select-input"
                        classNamePrefix="react-select"
                        placeholder={'- Select -'}
                        options={marketplaces}
                        onChange={handleSelectChange}
                      />
                    </div>
                    <div className="grid-item">
                      <Label>What is your main category?</Label>
                      <Select
                        name="category"
                        className="react-select primary w-100 select-input"
                        classNamePrefix="react-select"
                        placeholder={'- Select -'}
                        options={categories}
                        onChange={handleSelectChange}
                      />
                    </div>
                  </div>
                  <div className="audit-form-privacy-note">
                    By clicking "Connect your Amazon account" you agree to our
                    <span>
                      <a
                        href={
                          process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/terms'
                        }
                      >
                        {' '}
                        Terms of Service
                      </a>
                    </span>{' '}
                    and{' '}
                    <span>
                      <a
                        href={
                          process.env.REACT_APP_FRONTEND_APP_BASE_URL +
                          '/privacy'
                        }
                      >
                        Privacy Policy
                      </a>
                    </span>{' '}
                    as well as to receive marketing email from Autron. You can
                    unsubscribe from these communications at any time.
                  </div>
                  <div className="connect-amazon-button">
                    <Button color="primary" onClick={handleSubmit}>
                      {loading === true ? (
                        <Spinner size="sm" role="status"></Spinner>
                      ) : (
                        'Connect your Amazon account'
                      )}
                    </Button>
                  </div>
                  <div className="learn-more-ppc-audit">
                    Your data is safe with us.{' '}
                    <span>
                      <a
                        href={
                          process.env.REACT_APP_HELP_CENTER_URL +
                          '/en/articles/8954194-how-does-autron-utilize-seller-central-data'
                        }
                      >
                        {' '}
                        Learn more about why we need to connect your account
                      </a>
                    </span>
                  </div>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default PPCAudit
