import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import apiAuth from 'services/api/Auth'
import apiSellers from 'services/api/Seller'
import apiUsers from 'services/api/User'
import { setAuthToken } from 'variables/authToken'
import { useQueryClient, QueryCache } from 'react-query'
import { useAsyncDebounce } from 'react-table'
// nodejs library that concatenates classes
import classNames from 'classnames'
import moonImage from 'assets/img/moon-image.svg'
import moonImageMobileView from 'assets/img/moon-image-mobile-view.svg'
import sunImage from 'assets/img/sun-image.svg'

// reactstrap components
import {
  Button,
  Spinner,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Dropdown,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import useAlert from 'services/hooks/useAlert'
import { Fragment } from 'react'
import { inputFieldChange } from 'services/Utils'
import { segmentReset } from 'services/Segment'
import ThemeContext from 'services/providers/ThemeContext'

const AdminNavbar = ({
  handleMiniClick,
  brandText,
  sidebarOpened,
  toggleSidebar,
  user,
}) => {
  const queryClient = useQueryClient()
  const queryCache = new QueryCache()
  const { lightMode, setLightMode } = useContext(ThemeContext)

  useEffect(() => {
    setPrimarySeller(user.primary_seller)
  }, [user])

  const [primarySeller, setPrimarySeller] = useState({})
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [color, setColor] = useState('navbar-transparent')
  const history = useHistory()
  const { addAlert } = useAlert()
  const [loading, setLoading] = useState(false)

  const [feedback, setFeedback] = useState('')
  const [feedbackFocus, setFeedbackFocus] = useState(false)
  const [feedbackValidationState, setFeedbackValidationState] = useState('')
  const [feedbackDropdownOpen, setFeedbackDropdownOpen] = useState(false)

  const regionAbbreviations = {
    'North America': 'NA',
    Europe: 'EU',
    'Far East': 'FE',
  }

  const maxVisibleSellers = 5

  const [sellerSearchFocus, setSellerSearchFocus] = useState(false)
  const [sellerSearchString, setSellerSearchString] = useState('')
  const [visibleSellers, setVisibleSellers] = useState(maxVisibleSellers)
  const [filteredSellers, setFilteredSellers] = useState([
    ...user.company.sellers,
    ...user.company.managed_sellers,
  ])

  const [searchResultFlag, setSearchResultFlag] = useState(true)

  const onSellerSearchChange = useAsyncDebounce(async (s) => {
    let sellers = [...user.company.sellers, ...user.company.managed_sellers] // default dropdown items
    setSearchResultFlag(true) // hide the "No match found message"

    if (user.admin !== 'True') {
      // non autron admin user, perform search in managed_sellers
      if (s) {
        sellers = sellers.filter(
          (seller) => seller.name?.toLowerCase()?.indexOf(s?.toLowerCase()) > -1
        )
      }
    } else if (user.admin === 'True' && s.trim().length !== 0) {
      // clear the results set first
      setFilteredSellers([])

      // autron admin user, search ALL sellers by requesting on /sellers/search api
      // console.log('admin search: ', s);
      setLoading(true)
      const response = await apiSellers.search(s).then((apiResponse) => {
        return apiResponse
      })

      if (response && response.status === 200) {
        // console.log('admin search result: ', [ ...response.data.data]);
        sellers = [...response.data.data]
      }
      setLoading(false)
    }

    // show message when no matching seller is found
    sellers.length !== 0
      ? setSearchResultFlag(true)
      : setSearchResultFlag(false)
    setFilteredSellers(sellers)
    setVisibleSellers(maxVisibleSellers)
  }, 500)

  const feedbackToggle = () =>
    setFeedbackDropdownOpen((prevState) => !prevState)

  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor('navbar-transparent')
    } else {
      setColor('bg-white')
    }
    setCollapseOpen(!collapseOpen)
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = useCallback(() => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor('bg-white')
    } else {
      setColor('navbar-transparent')
    }
  }, [collapseOpen])

  // function that handles change of theme
  const handleTheme = () => {
    if (lightMode) {
      localStorage.setItem('theme', 'dark')
      setLightMode(false)
    } else {
      localStorage.setItem('theme', 'light')
      setLightMode(true)
    }
  }
  // logout user via api
  const logoutClick = async (e) => {
    const response = await apiAuth.logout().then((res) => {
      return res
    })
    if (response && response.status < 300) {
      setAuthToken('')
      queryCache.clear()
      segmentReset()
      addAlert('success', 'tim-icons icon-check-2', response.data.message, null)
      history.push('/auth/login')
    } else {
      if (response) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response.data.message,
          null
        )
      }
      e.preventDefault()
    }
  }

  // send user feedback
  const sendFeedbackClick = async (e) => {
    if (feedbackValidationState === 'has-success') {
      e.preventDefault()
      setLoading(true)
      const feedback_data = {
        template: 'generic_email.html',
        subject: 'Feedback - '.concat(history.location.pathname),
        to_email: 'hello@autron.ai',
        message: feedback,
      }
      // send feedback email via api
      const response = await apiUsers
        .send_email(user.public_id, feedback_data)
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        addAlert(
          'success',
          'tim-icons icon-check-2',
          'Thanks for your feedback, '.concat(user.firstname).concat('!'),
          null
        )
        setFeedback('')
        setFeedbackValidationState('')
        feedbackToggle()
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response.data.message,
            null
          )
        }
      }
      setLoading(false)
    } else {
      if (feedbackValidationState === '') {
        setFeedbackValidationState('has-danger')
      }
      e.preventDefault()
    }
  }

  // set user primary seller and marketplace
  const primarySellerClick = async (e) => {
    let t = e.target
    if (e.target.tagName.toLowerCase() === 'span') {
      t = e.target.parentElement
    }
    const [sellerPublicId, marketplaceId] = t
      .querySelector('input')
      .value.split(',')
    const seller = {
      primary_marketplace_id: marketplaceId,
    }
    const response = await apiSellers
      .primary(sellerPublicId, seller)
      .then((res) => {
        return res
      })
    if (response && response.status < 300) {
      //queryClient.invalidateQueries("GET/auth/user")
      addAlert('success', 'tim-icons icon-check-2', response.data.message, null)
      window.location.replace('/admin/dashboard') // ideally we would handle this with invalidateQueries
    } else {
      if (response) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response.data.message,
          null
        )
      }
      e.preventDefault()
    }
  }

  const completeOnboardingClick = async (e, seller) => {
    if (seller.public_id === primarySeller.public_id) {
      history.push('/admin/onboarding/seller/' + seller.public_id)
    } else {
      const response = await apiSellers
        .primary(seller.public_id, seller)
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
        window.location.replace('/admin/onboarding/seller/' + seller.public_id) // ideally we would handle this with invalidateQueries
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response.data.message,
            null
          )
        }
        e.preventDefault()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateColor)
    return () => {
      window.removeEventListener('resize', updateColor)
    }
  }, [updateColor])

  return (
    <>
      <Navbar
        className={classNames('navbar-absolute', {
          [color]: history.location.pathname.indexOf('full-screen-map') === -1,
        })}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize d-inline">
              <Button
                className="minimize-sidebar btn-just-icon"
                color="link"
                id="tooltip209599"
                onClick={handleMiniClick}
              >
                <i className="tim-icons icon-align-center visible-on-sidebar-regular" />
                <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini" />
              </Button>
              <UncontrolledTooltip
                delay={0}
                target="tooltip209599"
                placement="right"
              >
                Sidebar toggle
              </UncontrolledTooltip>
            </div>
            <div
              className={classNames('navbar-toggle d-inline', {
                toggled: sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              {''}
            </NavbarBrand>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navigation"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <li>
                {lightMode ? (
                  <img
                    src={sunImage}
                    width={25}
                    height={25}
                    className="light-mode-icon"
                    onClick={handleTheme}
                  />
                ) : (
                  <div>
                    <img
                      src={moonImage}
                      width={25}
                      height={25}
                      className="dark-mode-icon"
                      onClick={handleTheme}
                    />
                    <img
                      src={moonImageMobileView}
                      width={25}
                      height={25}
                      className="dark-mode-icon-mobile-view"
                      onClick={handleTheme}
                    />
                  </div>
                )}
              </li>
              <Dropdown
                nav
                isOpen={feedbackDropdownOpen}
                toggle={feedbackToggle}
              >
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                >
                  <div className="d-none d-lg-block d-xl-block" />
                  <i className="tim-icons icon-volume-98" />
                  <p className="d-lg-none">Feedback?</p>
                </DropdownToggle>
                <DropdownMenu
                  className="dropdown-navbar dropdown-menu-text-input"
                  right
                  tag="ul"
                >
                  <DropdownItem header className="d-none d-lg-block d-xl-block">
                    Feedback?
                  </DropdownItem>
                  <div className="dropdown-item-text-input" role="menuitem">
                    <Form className="form form-auth">
                      <InputGroup
                        className={classNames(feedbackValidationState, {
                          'input-group-focus': feedbackFocus,
                        })}
                      >
                        <Input
                          type="textarea"
                          name="feedback"
                          id="feedback"
                          defaultValue={feedback}
                          placeholder="Help us improve this page."
                          onFocus={(e) => setFeedbackFocus(true)}
                          onBlur={(e) => setFeedbackFocus(false)}
                          onChange={(e) =>
                            inputFieldChange(
                              e,
                              setFeedback,
                              setFeedbackValidationState,
                              'length',
                              1
                            )
                          }
                        />
                        {feedbackValidationState === 'has-danger' ? (
                          <label className="error">
                            Please enter a message.
                          </label>
                        ) : null}
                      </InputGroup>
                      <Button
                        color="primary"
                        type="submit"
                        className={classNames({ disabled: loading })}
                        onClick={(e) => sendFeedbackClick(e)}
                      >
                        {loading === true ? (
                          <Spinner size="sm" role="status"></Spinner>
                        ) : (
                          'Send Feedback'
                        )}
                      </Button>
                    </Form>
                  </div>
                </DropdownMenu>
              </Dropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="d-none d-lg-inline-block .d-xl-inline-block text-right text-uppercase">
                    {(primarySeller.name ? primarySeller.name : '').substr(
                      0,
                      20
                    ) +
                      (primarySeller.name && primarySeller.name?.length > 20
                        ? '... '
                        : '')}
                    <div className="subtitle mb-0">
                      {primarySeller.primary_marketplace_country}
                    </div>
                  </div>
                  <div className="avatar-initials btn-primary ml-lg-3">
                    <span>{user.firstname[0] + user.lastname[0]}</span>
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  <p className="d-lg-none">
                    {primarySeller.primary_marketplace_country_code +
                      ' | ' +
                      (primarySeller.name ? primarySeller.name : '').substr(
                        0,
                        20
                      ) +
                      (primarySeller.name && primarySeller.name?.length > 20
                        ? '... '
                        : '')}{' '}
                  </p>
                </DropdownToggle>
                <DropdownMenu className="dropdown-navbar" right tag="ul">
                  {
                    // spawn the searchbox if the current user is admin OR if the managed_sellers is more than visibleSellers
                    user.admin === 'True' ||
                    [...user.company.sellers, ...user.company.managed_sellers]
                      ?.length > visibleSellers ||
                    visibleSellers > maxVisibleSellers ? (
                      <div className="dropdown-item-search">
                        <InputGroup
                          className={classNames({
                            'input-group-focus': sellerSearchFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-zoom-split" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            value={sellerSearchString || ''}
                            onChange={(e) => {
                              setSellerSearchString(e.target.value)
                              onSellerSearchChange(e.target.value)
                            }}
                            onFocus={(e) => setSellerSearchFocus(true)}
                            onBlur={(e) => setSellerSearchFocus(false)}
                            placeholder={`Seller search`}
                          />
                          <InputGroupAddon addonType="append">
                            <InputGroupText>
                              {sellerSearchString ? (
                                <i
                                  className="tim-icons icon-simple-remove search-reset-icon"
                                  onClick={(e) => {
                                    setSellerSearchString('')
                                    onSellerSearchChange('')
                                    // reset the dropdown items back to the original items
                                    setFilteredSellers([
                                      ...user.company.sellers,
                                      ...user.company.managed_sellers,
                                    ])
                                  }}
                                />
                              ) : null}
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </div>
                    ) : null
                  }

                  {/* spinner while request is being fetched */}
                  {loading === true ? (
                    <div class="row h-100 justify-content-center align-items-center py-3">
                      <Spinner />
                    </div>
                  ) : null}

                  {searchResultFlag === false ? (
                    <div class="text-dark row h-100 justify-content-center align-items-center py-3">
                      No match found.
                    </div>
                  ) : null}

                  {filteredSellers
                    ?.slice(0, visibleSellers)
                    ?.map((seller, i) => (
                      <Fragment key={i}>
                        <DropdownItem header className="text-dark">
                          {seller.name +
                            ' (' +
                            regionAbbreviations[seller.marketplace_region] +
                            ')'}
                        </DropdownItem>
                        {seller.marketplaces?.length > 0 &&
                        seller?.ingest_completed_at ? (
                          seller.marketplaces.map((marketplace, j) => (
                            <Fragment key={j}>
                              <DropdownItem
                                className="nav-item compact"
                                onClick={(e) => primarySellerClick(e)}
                              >
                                <FormGroup check className="form-check-radio">
                                  <Label check>
                                    <Input
                                      defaultChecked={
                                        seller.primary_marketplace_id ===
                                          marketplace.marketplace_id &&
                                        seller.public_id ===
                                          primarySeller.public_id
                                          ? true
                                          : false
                                      }
                                      defaultValue={
                                        seller.public_id +
                                        ',' +
                                        marketplace.marketplace_id
                                      }
                                      id={
                                        'navbar_seller_radio_input_' +
                                        seller.public_id +
                                        '_' +
                                        marketplace.marketplace_id
                                      }
                                      name={
                                        'navbar_seller_radio_' +
                                        seller.public_id
                                      }
                                      type="radio"
                                    />
                                    <span className="form-check-sign" />
                                    {marketplace.country}
                                  </Label>
                                </FormGroup>
                              </DropdownItem>
                            </Fragment>
                          ))
                        ) : user.company.managed_sellers.filter(
                            (s) => s.public_id === seller.public_id
                          )?.length > 0 ? (
                          <DropdownItem className="mt-0 no-pointer-events">
                            Integration incomplete
                          </DropdownItem>
                        ) : (
                          <NavLink>
                            <DropdownItem
                              className="nav-item text-primary mt-0"
                              onClick={(e) =>
                                completeOnboardingClick(e, seller)
                              }
                            >
                              Finish Amazon Setup
                            </DropdownItem>
                          </NavLink>
                        )}
                      </Fragment>
                    ))}

                  {[...user.company.sellers, ...user.company.managed_sellers]
                    ?.length > maxVisibleSellers &&
                  filteredSellers?.length > maxVisibleSellers &&
                  visibleSellers <= maxVisibleSellers ? (
                    <p
                      className="dropdown-item div-link no-hover-background mt-0 pt-0 pb-1"
                      onClick={(e) => setVisibleSellers(100)}
                    >
                      <div>
                        <i className="tim-icons icon-minimal-down text-primary" />
                      </div>
                      <div style={{ marginTop: '-16px' }}>
                        <i className="tim-icons icon-minimal-down text-primary" />
                      </div>
                    </p>
                  ) : null}

                  {filteredSellers?.length > maxVisibleSellers &&
                  visibleSellers > maxVisibleSellers ? (
                    <p
                      className="dropdown-item div-link no-hover-background mt-0 pt-0 pb-1"
                      onClick={(e) => setVisibleSellers(maxVisibleSellers)}
                    >
                      <div>
                        <i className="tim-icons icon-minimal-up text-primary" />
                      </div>
                      <div style={{ marginTop: '-16px' }}>
                        <i className="tim-icons icon-minimal-up text-primary" />
                      </div>
                    </p>
                  ) : null}

                  {[...user.company.sellers, ...user.company.managed_sellers]
                    ?.length === 0 ? (
                    <NavLink to={'/admin/onboarding/seller/'} tag={Link}>
                      <DropdownItem className="nav-item text-primary mt-0">
                        Connect Amazon Account
                      </DropdownItem>
                    </NavLink>
                  ) : null}
                  <DropdownItem divider tag="li" />
                  <NavLink to="/admin/account" tag={Link}>
                    <DropdownItem className="nav-item">Account</DropdownItem>
                  </NavLink>
                  <NavLink tag="li">
                    <DropdownItem
                      className="nav-item"
                      onClick={(e) => logoutClick(e)}
                    >
                      Log out
                    </DropdownItem>
                  </NavLink>
                </DropdownMenu>
              </UncontrolledDropdown>
              <li className="separator d-lg-none" />
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default AdminNavbar
