import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import useAlert from 'services/hooks/useAlert'
import { useQuery } from 'react-query'
// reactstrap components
import {
  Spinner,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import apiAuth from 'services/api/Auth'
import { inputFieldChange } from 'services/Utils'
import { setAuthToken } from 'variables/authToken'
import cardPrimaryImg from 'assets/img/card-primary.png'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [emailFocus, setEmailFocus] = useState(false)
  const [passwordFocus, setPasswordFocus] = useState(false)

  const [emailValidationState, setEmailValidationState] = useState('')
  const [passwordValidationState, setPasswordValidationState] = useState('')

  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const { addAlert } = useAlert()

  // post login data to api if everything validated
  const { data } = useQuery(
    'GET/auth/user',
    () =>
      // eslint-disable-line no-unused-vars
      apiAuth.user(),
    {
      retry: false,
      //staleTime: 60 * 1000 * 1, // 1 minutes
      onSuccess: (data) => {
        if (data) {
          history.push('/admin/dashboard')
        }
      },
    }
  )

  // post login data to api if everything validated
  const loginClick = async (e) => {
    if (
      emailValidationState === 'has-success' &&
      passwordValidationState === 'has-success'
    ) {
      setLoading(true)
      const auth = { email, password }
      // login via api
      const response = await apiAuth.login(JSON.stringify(auth)).then((res) => {
        return res
      })
      if (response?.status < 300) {
        setAuthToken(response.data.Authorization)
        history.push('/admin')
      } else {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response?.data?.message,
          null
        )
      }
      setLoading(false)
    } else {
      if (emailValidationState === '') {
        setEmailValidationState('has-danger')
      }
      if (passwordValidationState === '') {
        setPasswordValidationState('has-danger')
      }
      e.preventDefault()
    }
  }

  useEffect(() => {
    document.body.classList.toggle('login-page')
    return () => {
      document.body.classList.toggle('login-page')
    }
  }, [])

  return (
    <>
      <div className="content">
        <Container>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form form-auth">
              <Card className="card-login card-white">
                <CardHeader>
                  <img alt="..." src={cardPrimaryImg} />
                  <CardTitle tag="h1">Login</CardTitle>
                </CardHeader>
                <CardBody>
                  <InputGroup
                    className={classnames(emailValidationState, {
                      'input-group-focus': emailFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="text"
                      autoComplete="email"
                      onFocus={(e) => setEmailFocus(true)}
                      onBlur={(e) => setEmailFocus(false)}
                      onChange={(e) =>
                        inputFieldChange(
                          e,
                          setEmail,
                          setEmailValidationState,
                          'email'
                        )
                      }
                    />
                    {emailValidationState === 'has-danger' ? (
                      <label className="error">
                        Please enter a valid email address.
                      </label>
                    ) : null}
                  </InputGroup>
                  <InputGroup
                    className={classnames(passwordValidationState, {
                      'input-group-focus': passwordFocus,
                    })}
                  >
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="current-password"
                      onFocus={(e) => setPasswordFocus(true)}
                      onBlur={(e) => setPasswordFocus(false)}
                      onChange={(e) =>
                        inputFieldChange(
                          e,
                          setPassword,
                          setPasswordValidationState,
                          'password',
                          1
                        )
                      }
                    />
                    {passwordValidationState === 'has-danger' ? (
                      <label className="error">Please enter a password.</label>
                    ) : null}
                  </InputGroup>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    className={classnames('mb-3', { disabled: loading })}
                    color="primary"
                    onClick={(e) => loginClick(e)}
                    size="lg"
                  >
                    {loading === true ? (
                      <Spinner size="sm" role="status"></Spinner>
                    ) : (
                      'Sign In'
                    )}
                  </Button>
                  <div className="pull-left">
                    <h6>
                      <Link className="link footer-link" to="/auth/register">
                        Create Account
                      </Link>
                    </h6>
                  </div>
                  <div className="pull-right">
                    <h6>
                      <Link
                        className="link footer-link"
                        to="/auth/forgot-password"
                      >
                        Forgot Password?
                      </Link>
                    </h6>
                  </div>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Container>
      </div>
    </>
  )
}

export default Login
