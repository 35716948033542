import React, { useEffect, useState, useMemo, Fragment } from 'react'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledAlert,
  Spinner,
} from 'reactstrap'
import { useQuery, useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import apiSellers from 'services/api/Seller'
import Goal from './Goal'
import GoalsByProduct from './GoalsByProduct'
import classNames from 'classnames'
import { sellerProfileId, freeUser } from 'services/Utils'

const Goals = ({ user }) => {
  const objectives = [
    { value: 'maximize_sales', label: 'Maximize Sales' },
    { value: 'maximize_profit', label: 'Maximize Profit' },
  ]

  const strategies = [
    // {
    //   value: 'minimum_net_profit',
    //   label: 'Target Profit',
    //   value_format: (value) => {
    //     return Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 1,
    //       maximumFractionDigits: 1 }).format(value)
    //   }
    // },
    {
      value: 'maximum_acos',
      label: 'Target Advertising Cost of Sales (ACOS)',
      value_format: (value) => {
        return Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(value)
      },
    },
    // {
    //   value: 'maximum_daily_budget',
    //   label: 'Target Daily Budget',
    //   value_format: (value, cur) => {
    //     return Intl.NumberFormat('en-US', { style: 'currency', currency: cur || 'USD' }).format(value)
    //   }
    // }
    {
      value: 'maximum_tacos',
      label: 'Target Total Advertising Cost of Sales (TACOS)',
      value_format: (value) => {
        return Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(value)
      },
    },
  ]

  const [horizontalTabs, setHorizontalTabs] = useState('global')
  const [primarySeller, setPrimarySeller] = useState(user.primary_seller || {})
  const primarySellerPublicId = primarySeller?.public_id
  const [globalGoal, setGlobalGoal] = useState()
  const [productGoals, setProductGoals] = useState([])
  const [products, setProducts] = useState([])
  const [newProducts, setNewProducts] = useState([])
  const [profileId, setProfileId] = useState(
    sellerProfileId(user.primary_seller)
  )
  const [isFreeUser, setIsFreeUser] = useState(freeUser(user))
  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: freeUser(user) ? 5000 : 10,
  })

  const [gfilterQuery, setGfilterQuery] = useState(false)
  const [pages, setPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)

  const [globalGoalOn, setGlobalGoalOn] = useState(true)
  const [productGoalsOn, setProductGoalsOn] = useState(true)

  const queryClient = useQueryClient()

  const setGoalStates = (gObj, param) => {
    const gGoal = gObj?.data.goals?.find((goal) => goal.type === 'global')
    const prodGoals = []
    setProducts(
      gObj?.data.goals
        .filter((goal) => goal?.type === 'product')
        .map((goal) => {
          prodGoals.push(goal)
          return {
            public_id: goal.product_public_id,
            asin: goal.product_asin,
            sku: goal.product_sku,
            your_price: goal.product_your_price,
            price: goal.product_price,
            profit: goal.product_profit,
          }
        })
    )

    setGlobalGoal(gGoal)
    setProductGoals(prodGoals)
    setPages(gObj?.data.pages)
    setTotal(gObj?.data.total)
    setLoading(false)
    setGfilterQuery(
      Object.keys(param).some(function (k) {
        return ~k.indexOf('gfilter')
      })
    )
    setGlobalGoalOn(gGoal?.state === 'enabled')
    setProductGoalsOn(prodGoals?.some((g) => g.state === 'enabled'))
  }

  const {
    data: goalsObject,
    isFetching: goalsIsLoading,
    goalsRefetch,
  } = useQuery(
    ['GET/sellers/', primarySellerPublicId, '/goals', queryParam],
    () =>
      apiSellers.goals(
        primarySellerPublicId,
        Object.assign(queryParam, {
          'filter[type][in]': JSON.stringify(['global', 'product']),
        })
      ),
    {
      // filter hack... improve!
      staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (goalsObject) => {
        setGoalStates(goalsObject, queryParam)
      },
    }
  )
  useEffect(() => {
    const goalsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/goals',
      queryParam,
    ])
    if (goalsCache && goalsCache.data?.goals) {
      setGoalStates(goalsCache, queryParam)
    }
  }, [queryClient, primarySellerPublicId, queryParam])

  useQuery(
    ['GET/sellers/', primarySellerPublicId, '/goals-new'],
    () => apiSellers.newGoals(primarySellerPublicId),
    {
      staleTime: 1440 * 60 * 1000, // 60 minutes
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (newGoalsObj) => {
        setNewProducts(newGoalsObj?.data?.new_product_goals)
      },
    }
  )

  useEffect(() => {
    const newGoalsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/goals-new',
    ])
    if (newGoalsCache && newGoalsCache.data) {
      setNewProducts(newGoalsCache?.data?.new_product_goals)
    }
  }, [queryClient, primarySellerPublicId])

  return (
    <>
      <div className="content">
        <Row>
          <Col
            className={classNames({
              'd-flex justify-content-center align-items-end mt-xl': loading,
            })}
          >
            {loading ? (
              <Spinner /> // used for initial load
            ) : (
              <Fragment>
                <Card className="card-plain info-area">
                  <CardHeader>
                    <CardTitle tag="h2">Goals</CardTitle>
                    <hr className="line-primary mb-2" />
                  </CardHeader>
                  <CardBody>
                    <p className="card-description description">
                      What are your advertising goals? Select the settings that
                      would make advertising successful for you. <br />
                      Autron's AI advertising engine optimizes performance
                      towards your objective given the strategic constraints.
                    </p>
                  </CardBody>
                  {globalGoal &&
                  !gfilterQuery &&
                  (!globalGoalOn ||
                    (!productGoalsOn &&
                      queryParam.page === 1 &&
                      !newProducts.length)) ? (
                    <CardFooter>
                      <UncontrolledAlert
                        className="alert-with-icon"
                        color="danger"
                        fade={true}
                      >
                        <span
                          data-notify="icon"
                          className="tim-icons icon-alert-circle-exc"
                        />
                        <span>
                          {!globalGoalOn
                            ? "Autron's AI is not managing your ads. Click 'Relaunch Autron!' to continue."
                            : 'All your product goals are off. Turn one back on to benefit from Autron’s AI ad engine managing your ads.'}
                        </span>
                      </UncontrolledAlert>
                    </CardFooter>
                  ) : null}
                </Card>
                {products?.length > 0 || newProducts.length || gfilterQuery ? (
                  <Card className="">
                    <CardBody>
                      <Nav className="nav-tabs-info" tabs>
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            href="#"
                            className={
                              horizontalTabs === 'global' ? 'active' : ''
                            }
                            onClick={(e) => setHorizontalTabs('global')}
                          >
                            Global
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            data-toggle="tab"
                            href="#"
                            className={
                              horizontalTabs === 'byProduct' ? 'active' : ''
                            }
                            onClick={(e) => setHorizontalTabs('byProduct')}
                          >
                            By Product
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        className="tab-space"
                        activeTab={horizontalTabs}
                      >
                        <TabPane tabId="global">
                          <Goal
                            objectives={objectives}
                            strategies={strategies}
                            type={'global'}
                            goal={globalGoal}
                            goalGlobalPublicId={globalGoal?.public_id}
                            goalGlobalASIN={
                              isFreeUser
                                ? productGoals?.find(
                                    (pg) =>
                                      pg.use_global_public_id !== null &&
                                      pg.state === 'enabled'
                                  )?.product_asin
                                : null
                            }
                            products={products}
                            newProducts={newProducts}
                            seller={primarySeller}
                            profileId={profileId}
                            isFreeUser={isFreeUser}
                            dataIsLoading={false} //goalsIsLoading || productsIsLoading ? true : false}
                          />
                        </TabPane>
                        <TabPane tabId="byProduct">
                          {globalGoalOn ? (
                            <GoalsByProduct
                              objectives={objectives}
                              strategies={strategies}
                              type={'product'}
                              goals={productGoals}
                              globalGoal={globalGoal}
                              newProducts={newProducts}
                              seller={primarySeller}
                              profileId={profileId}
                              isFreeUser={isFreeUser}
                              loading={goalsIsLoading}
                              total={total}
                              pageCount={pages}
                              queryParam={queryParam}
                              setQueryParam={setQueryParam}
                              gfilterQuery={gfilterQuery}
                            />
                          ) : (
                            <Row>
                              <Col sm="9" md="6">
                                <UncontrolledAlert color="info" fade={true}>
                                  <span>
                                    Create and turn on a global goal before
                                    managing product specific goals.
                                  </span>
                                </UncontrolledAlert>
                              </Col>
                            </Row>
                          )}
                        </TabPane>
                      </TabContent>
                    </CardBody>
                    <CardFooter></CardFooter>
                  </Card>
                ) : (
                  <Col sm="9" md="6">
                    <UncontrolledAlert color="info" fade={true}>
                      <span>
                        Looks like your{' '}
                        <NavLink
                          className="d-inline p-0 text-white"
                          tag={Link}
                          to="/admin/products"
                        >
                          <strong>Products</strong>
                        </NavLink>{' '}
                        haven't synced yet. Once your products are ready you can
                        set your goals.
                      </span>
                    </UncontrolledAlert>
                  </Col>
                )}
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Goals
