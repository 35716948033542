import React, { useState, useEffect, Fragment } from 'react'
import IframeResizer from 'iframe-resizer-react'
import { useQuery, useQueryClient } from 'react-query'
import apiAnalytics from 'services/api/Analytics'
import { Link, useLocation } from 'react-router-dom'

import classNames from 'classnames'

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,
} from 'reactstrap'
import SkeletonInsights from 'components/SkeletonCard/SkeletonInsights'

const PPCAuditResult = () => {
  const params = new URLSearchParams(useLocation().search)
  const [loading, setLoading] = useState(true)

  const analyticsUrl = process.env.REACT_APP_FRONTEND_APP_BASE_URL

  const [iframeUrl, setIframeUrl] = useState(analyticsUrl)

  const queryClient = useQueryClient()
  const encoded_public_id = params.get('encoded_public_id')
  const [error, setError] = useState(
    encoded_public_id ? '' : 'No Results Found'
  )

  // Use React Query to fetch the iframeUrl if sellerId and marketplaceId are available
  const embedUrlParam = {
    resource: { dashboard: 13 },
    params: {
      encoded_public_id: encoded_public_id,
    },
  }

  const { data: embedUrl, isFetching: embedUrlIsLoading } = useQuery(
    ['POST/analytics/embed/ppc-audit', embedUrlParam],
    () => apiAnalytics.generateEmbedUrlPPCAudit(embedUrlParam),
    {
      enabled: !!encoded_public_id, // Only run this query if sellerId and marketplaceId are available
      staleTime: 10 * 60 * 1000,
      onSuccess: (embedUrl) => {
        if (embedUrl && embedUrl.status < 300) {
          setIframeUrl(embedUrl.data.iframe_url)
        } else {
          setError('Failed to load dashboard. Please try again later.')
        }
      },
      onError: () => {
        setError('Failed to load dashboard. Please try again later.')
      },
    }
  )

  useEffect(() => {
    const iframeUrlCache = queryClient.getQueryData([
      'POST/analytics/embed/ppc-audit',
      embedUrlParam,
    ])

    if (iframeUrlCache && iframeUrlCache.data?.iframe_url) {
      setIframeUrl(iframeUrlCache.data.iframe_url)
    }
  }, [queryClient, embedUrlParam])

  return (
    <>
      <div className="content audit-report-container">
        <Row
          className={classNames({
            'd-flex justify-content-center align-items-end': loading,
          })}
        >
          <Fragment>
            <Col xs="12">
              <Card className="card-plain info-area">
                <CardHeader>
                  <CardTitle tag="h2">Amazon PPC Audit Report</CardTitle>
                  <hr className="line-primary mb-2" />
                </CardHeader>
                <CardBody>
                  <p className="card-description description">
                    Gain Clear Insights into Your Amazon PPC Performance with
                    Our Comprehensive Audit Report
                  </p>
                </CardBody>
              </Card>
              <Card>
                <CardBody
                  className={`${error ? 'ppc-audit-result-container' : ''}`}
                >
                  {error && (
                    <div className="no-results-container">
                      <CardTitle tag="h2">No Results Found</CardTitle>
                      <p>
                        Please click the link in the email or request a PPC Audt
                        below
                      </p>
                      <Button
                        color="primary"
                        tag={Link}
                        to="/ppc-audit"
                        size="md"
                      >
                        Get PPC Audit
                      </Button>
                    </div>
                  )}
                  {loading && !error && <SkeletonInsights />}

                  {iframeUrl !== analyticsUrl && (
                    <IframeResizer
                      src={iframeUrl}
                      style={{
                        width: '1px',
                        minWidth: '100%',
                        minHeight: '42.5em',
                      }}
                      hidden={loading}
                      onLoad={() => setLoading(false)}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Fragment>
        </Row>
      </div>
    </>
  )
}

export default PPCAuditResult
