import React from 'react'
import ContentLoader from 'react-content-loader'

const DashboardTrendLoader = (props) => {
  const theme = localStorage.getItem('theme')
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={80}
      backgroundOpacity={0}
      foregroundColor={`${theme === 'light' ? '#000000' : '#ffffff'}`}
      foregroundOpacity={0.1}
      {...props}
    >
      <rect x="0%" y="35%" rx="3" ry="3" width="100%" height="35" />
    </ContentLoader>
  )
}

export default DashboardTrendLoader
