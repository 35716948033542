import React, { useRef, useEffect } from 'react'
import useAlert from '../../services/hooks/useAlert'
import NotificationAlert from 'react-notification-alert'

const AlertNotification = () => {
  const { alert } = useAlert()
  const alertRef = useRef()

  useEffect(() => {
    if (alertRef && alertRef.current && alert) {
      alertRef.current.notificationAlert({
        place: 'tr',
        message: alert ? alert.message : null,
        type: alert ? alert.type : null,
        icon: alert ? alert.icon : null,
        autoDismiss: 7,
      })
    }
  }, [alertRef, alert])

  // const handleSubmit = () => {
  //   removeAlert();
  // };

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={alertRef} />
      </div>
    </>
  )
}

export default AlertNotification
