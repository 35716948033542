import React, { useContext, useEffect } from 'react'
import { AppRoutes } from 'AppRoutes'
import AlertProvider from 'services/providers/AlertProvider.js'
import AlertNotification from 'components/AlertNotification/AlertNotification.js'
import { StripeProvider } from 'services/providers/StripeContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from 'services/providers/ThemeContext'
import ThemeContext from 'services/providers/ThemeContext'

// Create a react-query client
const queryClient = new QueryClient()

export const App = () => {
  // do app scope stuff here e.g useEffect etc.
  const { lightMode } = useContext(ThemeContext)
  useEffect(() => {
    if (lightMode) {
      document.body.classList.add('white-content')
    } else {
      document.body.classList.remove('white-content')
    }
  }, [lightMode])

  return (
    <QueryClientProvider client={queryClient}>
      <StripeProvider>
        <AlertProvider>
          <AppRoutes />
          <AlertNotification />
        </AlertProvider>
      </StripeProvider>
    </QueryClientProvider>
  )
}
