import React, { useEffect, useState, useMemo, Fragment } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Select from 'react-select'
import Papa from 'papaparse'
import {
  Row,
  Col,
  CardTitle,
  InputGroup,
  Button,
  UncontrolledTooltip,
  Form,
  Label,
  NavLink,
  Spinner,
  Badge,
} from 'reactstrap'
import { inputFieldChange } from 'services/Utils'
import UploadThumbnailGallery from 'components/CustomUpload/UploadThumbnailGallery'
import ReactTable from 'components/ReactTable/ReactTable'
import { useQueryClient } from 'react-query'
import useAlert from 'services/hooks/useAlert'
import apiProducts from 'services/api/Product'
import { sortByField, uniqueListOfObjects } from 'services/Utils'

const ProductsImport = ({ seller, importType, setImportType, importTypes }) => {
  const { addAlert } = useAlert()
  const queryClient = useQueryClient()

  const [type, setType] = useState(
    importTypes.find((t) => t.options.value === importType)?.options
  )
  const [typeHeaders, setTypeHeaders] = useState(
    importTypes.find((t) => t.options.value === importType)?.fields
  )
  const [typeValidation, setTypeValidation] = useState('')
  const [typeFocus, setTypeFocus] = useState(false)

  const [importFile, setImportFile] = useState(null)
  const [importFileValidation, setImportFileValidation] = useState('')
  const [importValidationErrors, setImportValidationErrors] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [isImporting, setIsImporting] = useState(false)

  const [parsedData, setParsedData] = useState([])
  const [parsedErrors, setParsedErrors] = useState([])
  const [previewTableData, setPreviewTableData] = useState([])
  const [previewTableHeaders, setPreviewTableHeaders] = useState([])

  const previewRowCount = 3

  const handleTypeChange = (selectedType) => {
    setImportType(selectedType.value)
    setTypeHeaders(
      importTypes.find((t) => t.options.value === selectedType.value)?.fields
    )
    setParsedData([])
    setParsedErrors([])
    setPreviewTableData([])
    setPreviewTableHeaders([])
    setImportFile(null)
    setImportFileValidation('')
    setImportValidationErrors([])
  }

  const createTargetData = (type) => {
    const baseData = {
      seller_selling_partner_id: seller.selling_partner_id,
      marketplace_marketplace_id: seller.primary_marketplace_id,
    }

    if (type === 'targets') {
      return {
        ...baseData,
        targets: parsedData.map((object) => ({
          ...object,
          state: 'enabled',
        })),
      }
    }

    if (type === 'costs') {
      return {
        ...baseData,
        costs: parsedData.map((object) => ({
          ...object,
          effective_from: new Date(),
        })),
      }
    }
  }

  const createApiCall = async (type, data) => {
    if (type === 'targets') {
      return await apiProducts.createBulkTargets(data)
    }

    if (type === 'costs') {
      return await apiProducts.createBulkCosts(data)
    }
  }

  const importDataClick = async (e) => {
    if (!importFile) {
      setImportFileValidation('has-danger')
      setImportValidationErrors(['Please first upload a CSV file.'])
      return
    }

    if (importFileValidation !== 'has-success') {
      return
    }

    setIsImporting(true)

    const targetData = createTargetData(importType)
    const response = await createApiCall(importType, targetData)

    if (response && response.status < 300) {
      queryClient.invalidateQueries('GET/sellers/')
      addAlert('success', 'tim-icons icon-check-2', response.data.message, null)
      setImportType(null)
    } else if (response) {
      addAlert(
        'danger',
        'tim-icons icon-alert-circle-exc',
        response.data.message,
        null
      )
    }

    setIsImporting(false)
  }

  const onUpload = (file) => {
    setImportFile(file)
    setImportFileValidation(validateFile(file))
  }

  const validateFile = (file) => {
    let errors = []
    if (file) {
      if (file.size / 1024 / 1024 > 100) {
        errors.push('File must be 100 MB or smaller,')
      }
      if (file.type !== 'text/csv') {
        errors.push('File must be of CSV format')
      }
    }
    setImportValidationErrors(errors)
    return errors.length > 0 ? 'has-danger' : 'has-success'
  }

  useEffect(() => {
    if (importFile && importFileValidation === 'has-success') {
      setIsUploading(true)
      Papa.parse(importFile, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const headers = []
          // filter columns to only include headers for importType
          const [data, rowsRemoved] = parsedDataValidationAndFormatting(
            results?.data,
            typeHeaders
          )
          if (
            data?.length > 0 &&
            Object.keys(data[0])?.length === typeHeaders?.length
          ) {
            const previewData = data?.slice(0, previewRowCount)
            previewData.map((d) => {
              headers.push(Object.keys(d))
            })
            setParsedData(data)
            setPreviewTableData(data?.slice(0, previewRowCount))
            setPreviewTableHeaders(headers[0])
            if (rowsRemoved.length > 0) {
              setParsedErrors([
                {
                  message:
                    'Warning: row(s) ' +
                    rowsRemoved.join(', ') +
                    ' were removed due to invalid or duplicate data',
                },
              ])
            } else {
              setParsedErrors(results.errors.slice(0, 10))
            }
          } else {
            setImportFileValidation('has-danger')
            setImportValidationErrors([
              'Error: expecting data for the fields ' + typeHeaders.join(', '),
            ])
          }
          setIsUploading(false)
        },
      })
    }
  }, [importFile, importFileValidation, typeHeaders])

  const parsedDataValidationAndFormatting = (data, headers) => {
    if (headers.includes('match_type')) {
      // filter columns to only include headers for importType
      const d = data?.map((row) =>
        headers
          ?.filter((key) => key in row)
          ?.reduce((obj, key) => ((obj[key] = row[key]), obj), {})
      )
      // format data
      const d1 = d.map((r, i) => ({
        ...r,
        ...(r.asin && { asin: r.asin?.toUpperCase().trim() }),
        ...(r.type && { type: r.type?.toLowerCase().trim() }),
        ...(r.match_type &&
          r.type === 'keyword' && {
            match_type: r.match_type?.toLowerCase().trim(),
          }),
        ...(!r.match_type &&
          r.type === 'product' && { match_type: 'asinSameAs' }),
        ...(!r.match_type &&
          r.type === 'category' && { match_type: 'asinCategorySameAs' }),
        ...(r.target && {
          target:
            r.type?.toLowerCase().trim() === 'product'
              ? r.target?.toUpperCase().trim()
              : r.target
                  ?.toLowerCase()
                  .split(' ')
                  .map((w) => w?.trim())
                  .filter((w) => w !== '')
                  .join(' '),
        }),
        row: i + 2,
      }))
      // general filters
      const d2 = d1.filter(
        (r) =>
          r?.asin !== '' &&
          ['keyword', 'product', 'category'].includes(r?.type) &&
          r?.target !== ''
      )
      // target type specific filters
      const dK = d2
        .filter((r) => r?.type === 'keyword')
        .filter((r) => ['exact', 'phrase', 'broad'].includes(r?.match_type))
      const dP = d2
        .filter((r) => r?.type === 'product')
        .filter((r) => ['asinSameAs'].includes(r?.match_type))
      const dC = d2
        .filter((r) => r?.type === 'category')
        .filter((r) => r?.match_type?.includes('asinCategorySameAs'))
      const d3 = [...dK, ...dP, ...dC].sort(sortByField('row'))
      // remove duplicates
      const d4 = uniqueListOfObjects(d3, headers)
      // determine rows removed
      const rowsRemoved = d1
        .map((r) => r.row)
        .filter((x) => !d4.map((r) => r.row).includes(x))
      // remove row key
      const d5 = d4.map(({ row, ...r }) => r)

      // sort match_type to expected order for grouping
      const d6 = d5
        .slice()
        .sort(
          (a, b) =>
            ['exact', 'phrase', 'broad', ''].indexOf(a.match_type) -
            ['exact', 'phrase', 'broad', ''].indexOf(b.match_type)
        )
      // group match_types for same asin, type, target combo
      const d7 = [
        ...d6
          .reduce((r, o) => {
            const key = o.asin + '-' + o.type + '-' + o.target
            const item =
              r.get(key) ||
              Object.assign({}, o, {
                match_type: '',
              })
            item.match_type +=
              item.match_type === '' ? o.match_type : ',' + o.match_type
            return r.set(key, item)
          }, new Map())
          .values(),
      ]

      return [d7, rowsRemoved]
    } else {
      // convert any non-numeric strings or nulls to zero for cost fields
      const d1 = data?.map((row) =>
        headers
          ?.filter((key) => key in row)
          ?.reduce((obj, key) => {
            if (
              [
                'cost_of_goods_per_unit',
                'shipping_cost_per_unit',
                'misc_cost_per_unit',
              ].includes(key)
            ) {
              obj[key] =
                isNaN(row[key]) || row[key] === null || row[key] === ''
                  ? 0
                  : parseFloat(row[key])
            } else {
              obj[key] = row[key]
            }
            return obj
          }, {})
      )
      // Filter out rows with mismatched headers
      const d2 = d1.filter(
        (r) =>
          Object.keys(r).length === headers.length &&
          Object.keys(r).every((key) => headers.includes(key))
      )
      // Get unique list based on 'asin'
      const d3 = uniqueListOfObjects(d2, ['asin'])
      // Create a set to hold the stringified version of objects from d3 for quick look-up.
      const d3Set = new Set(d3.map(JSON.stringify))
      // Create an array to hold the index of the rows that existed in d1 but not in d3.
      const rowsRemoved = []
      // Loop through d1 and identify which rows don't exist in d3.
      for (let i = 0; i < d1.length; i++) {
        if (!d3Set.has(JSON.stringify(d1[i]))) {
          rowsRemoved.push(i)
        }
      }
      return [d3, rowsRemoved]
    }
  }

  const previewTableColumns = useMemo(
    () =>
      previewTableHeaders.map((h) => ({
        Header: h.replace(/_/g, ' '),
        accessor: h,
        sortable: false,
        Cell: (props) =>
          h === 'match_type'
            ? ['keyword'].includes(props.row.original['type'])
              ? props.value.split(',').map((p) => (
                  <Badge
                    key={p}
                    className={`badge-pill-custom badge-primary-inverse`}
                    pill
                  >
                    {p}
                  </Badge>
                ))
              : ['category'].includes(props.row.original['type']) &&
                props.value?.includes('|')
              ? props.value
              : null
            : props.value,
      })),
    [previewTableHeaders]
  )

  return (
    <>
      <Row className="mb-3">
        <Col sm="8">
          <CardTitle tag="h4">Import CSV</CardTitle>
          <p className="card-description mb-2">
            See an example of a{' '}
            {importTypes?.map((t, i) => (
              <Fragment key={i}>
                <NavLink
                  className="d-inline p-0 div-link"
                  tag={Link}
                  to={{ pathname: t.exampleFileUrl }}
                  target="_blank"
                >
                  {t.options.label.toLowerCase()}
                </NavLink>
                <span>
                  {importTypes?.length === i + 1 ? ' CSV file.' : ' or a '}
                </span>
              </Fragment>
            ))}
          </p>
        </Col>
      </Row>
      <Form className="form-horizontal">
        <Row className="align-items-center">
          <Label sm="3" className="pt-0 mt-n1">
            Import type
          </Label>
          <Col sm="9" md="6">
            <InputGroup
              className={classNames(typeValidation, {
                'input-group-focus': typeFocus,
              })}
            >
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="brand"
                value={type}
                placeholder=""
                onChange={(e) => {
                  inputFieldChange(
                    e,
                    setType,
                    setTypeValidation,
                    'select_length',
                    1
                  )
                  handleTypeChange(e)
                }}
                options={importTypes.map((t) => t.options)}
                onFocus={(e) => setTypeFocus(true)}
                onBlur={(e) => setTypeFocus(false)}
              />
              {typeValidation === 'has-danger' ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </Col>
        </Row>
        {parsedData?.length > 0 ? (
          <Row className="align-items-center">
            <Label sm="3" className="pt-0 mt-n1">
              Preview
              <Button
                color="link"
                id="preview-label-tooltip"
                title=""
                type="button"
                className="p-0 m-0 mt-n1 pl-2"
              >
                <i className="fas fa-question-circle"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="preview-label-tooltip">
                Preview of first {previewRowCount} rows to be imported.
              </UncontrolledTooltip>
            </Label>
            <Col sm="9" md="9">
              <div className="py-3">
                <i className="tim-icons icon-single-copy-04 mr-2" />
                {importFile.name}
                <i className="tim-icons icon-check-2 ml-2 mr-3 text-success" />
                <Button
                  color="primary"
                  className={classNames('btn-simple btn-sm-custom')}
                  onClick={(e) => setParsedData([])}
                >
                  Replace
                </Button>
              </div>
              <ReactTable
                columns={previewTableColumns}
                data={previewTableData}
                loading={false}
                total={previewRowCount}
              />
              {parsedErrors?.length > 0 ? (
                <label>
                  <ul className="list-inline">
                    {parsedErrors.map((error, idx) => (
                      <li key={idx} className={'text-warning'}>
                        {error?.row
                          ? 'Warning for row ' +
                            (error?.row + 2) +
                            ' - ' +
                            error?.message
                          : error?.message}
                      </li>
                    ))}
                  </ul>
                </label>
              ) : null}
            </Col>
          </Row>
        ) : (
          <Row className="align-items-center">
            <Label sm="3" className="pt-0 mt-n1">
              CSV file
              <Button
                color="link"
                id="csv-label-tooltip"
                title=""
                type="button"
                className="p-0 m-0 mt-n1 pl-2"
              >
                <i className="fas fa-question-circle"></i>
              </Button>
              <UncontrolledTooltip delay={0} target="csv-label-tooltip">
                <strong>file requirements</strong>
                <br />
                - be in CSV format
                <br />
                - not exceed 100MB in size
                <br />- not contain more than 100 columns
              </UncontrolledTooltip>
            </Label>
            <Col sm="9" md="6">
              <UploadThumbnailGallery
                assets={[]}
                assetType={'csv'}
                onPick={(e) => e.preventDefault}
                onUpload={onUpload}
                uploading={isUploading}
              />
              {importFileValidation === 'has-danger' ? (
                <label>
                  <ul className="list-inline">
                    {importValidationErrors.map((error, idx) => (
                      <li key={idx} className="text-danger-states">
                        {error}
                      </li>
                    ))}
                  </ul>
                </label>
              ) : null}
            </Col>
          </Row>
        )}
        <Row className="mt-5">
          <Label sm="3"></Label>
          <Col sm="9" md="6">
            <Button
              color="primary"
              className={classNames('btn-wd', { disabled: isImporting })}
              onClick={(e) => importDataClick(e)}
            >
              {isImporting ? (
                <Spinner size="sm" role="status"></Spinner>
              ) : (
                'Import Data'
              )}
            </Button>
            <Button
              color="primary"
              className={classNames('btn-simple')}
              onClick={(e) => setImportType(null)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ProductsImport
