import React, { useState, useCallback } from 'react'

export const AlertContext = React.createContext({
  alert: null,
  addAlert: () => {},
  removeAlert: () => {},
})

export default function AlertProvider({ children }) {
  const [alert, setAlert] = useState(null)

  const removeAlert = () => setAlert(null)

  const addAlert = (type, icon, message, status) =>
    setAlert({ type, icon, message, status })

  const contextValue = {
    alert,
    addAlert: useCallback(
      (type, icon, message, status) => addAlert(type, icon, message, status),
      []
    ),
    removeAlert: useCallback(() => removeAlert(), []),
  }

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
    </AlertContext.Provider>
  )
}
