import React, { useState } from 'react'
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import ProductCosts from './ProductCosts'
import ProductTargeting from './ProductTargeting'
import { Link } from 'react-router-dom'

const Product = ({ product }) => {
  const [horizontalTabs, setHorizontalTabs] = useState('product_costs')

  return (
    <>
      <Card className="card-plain info-area">
        <CardHeader>
          <Breadcrumb>
            <BreadcrumbItem className="card-category">
              <NavLink tag={Link} to="/admin/products">
                Products
              </NavLink>
            </BreadcrumbItem>
            <BreadcrumbItem className="card-category">
              {product.asin}
            </BreadcrumbItem>
            <BreadcrumbItem className="card-category">
              {product.sku}
            </BreadcrumbItem>
          </Breadcrumb>
          <CardTitle tag="h3">
            {product.product_name.substring(0, 100) + '...'}
          </CardTitle>
          <hr className="line-primary mb-2" />
        </CardHeader>
      </Card>
      <Card>
        <CardBody>
          <Nav className="nav-tabs-info" tabs>
            <NavItem>
              <NavLink
                data-toggle="tab"
                href="#"
                className={horizontalTabs === 'product_costs' ? 'active' : ''}
                onClick={(e) => setHorizontalTabs('product_costs')}
              >
                Unit Costs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-toggle="tab"
                href="#"
                className={horizontalTabs === 'ad_targeting' ? 'active' : ''}
                onClick={(e) => setHorizontalTabs('ad_targeting')}
              >
                Ad Targeting
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="tab-space" activeTab={horizontalTabs}>
            <TabPane tabId="product_costs">
              <ProductCosts product={product} />
            </TabPane>
            <TabPane tabId="ad_targeting">
              <ProductTargeting product={product} />
            </TabPane>
          </TabContent>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>
    </>
  )
}

export default Product
