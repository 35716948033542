import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { inputFieldChange } from 'services/Utils'
import classnames from 'classnames'
// reactstrap components
import {
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap'
import apiMarketplaces from 'services/api/Marketplace'
import { useQuery, useQueryClient } from 'react-query'
import apiSellers from 'services/api/Seller'
import useAlert from 'services/hooks/useAlert'
import apiLeads from 'services/api/Leads.js'

const OnboardingSellerStep0 = forwardRef((props, ref) => {
  const params = useParams()
  let history = useHistory()

  const [email, setEmail] = useState(props.email)
  const [name, setName] = useState(props.name)
  const [region, setRegion] = useState(
    props.region === '' ? '' : { label: props.region, value: props.region }
  )
  const [marketplace, setMarketplace] = useState(
    props.marketplaceId === ''
      ? ''
      : { label: props.marketplaceCountry, value: props.marketplaceId }
  )
  const [marketplaceCountryCode, setMarketplaceCountryCode] = useState(
    props.marketplaceCountryCode
  )
  const [marketplaces, setMarketplaces] = useState([])
  const [publicId] = useState(props.publicId)
  const [companyPublicId] = useState(props.companyPublicId)
  const [managingCompanyPublicId] = useState(props.managingCompanyPublicId)

  const [emailFocus, setEmailFocus] = useState(false)
  const [nameFocus, setNameFocus] = useState(false)
  const [regionFocus, setRegionFocus] = useState(false)
  const [marketplaceFocus, setMarketplaceFocus] = useState(false)

  const [marketplaceDisabled, setMarketplaceDisabled] = useState(
    props.marketplaceId === '' ? true : false
  )

  const [emailValidationState, setEmailValidationState] = useState(
    props.email === '' ? '' : 'has-success'
  )
  const [nameValidationState, setNameValidationState] = useState(
    props.name === '' ? '' : 'has-success'
  )
  const [regionValidationState, setRegionValidationState] = useState(
    props.region === '' ? '' : 'has-success'
  )
  const [marketplaceValidationState, setMarketplaceValidationState] = useState(
    props.marketplaceId === '' ? '' : 'has-success'
  )

  const { addAlert } = useAlert()

  const queryClient = useQueryClient()

  var marketplace_data = []
  var regions = []
  var region_marketplaces = []

  const { data } = useQuery(
    'GET/marketplaces/',
    () => apiMarketplaces.getAll(),
    {
      retry: false,
      staleTime: 604800000,
    }
  )

  if (data && data.status < 300) {
    marketplace_data = data.data.data
    regions = [...new Set(marketplace_data.map((q) => q.region))].map((x) => ({
      label: x,
      value: x,
    }))
    if (region !== '') {
      region_marketplaces = regionMarketplaces(region)
    }
  }

  function regionMarketplaces(region) {
    const filtered = marketplace_data.filter(function (m) {
      return m.region === region
    })
    return (region_marketplaces = [
      ...new Set(
        filtered.map((q) => ({ label: q.country, value: q.marketplace_id }))
      ),
    ])
  }

  const regionSelect = (region) => {
    region_marketplaces = regionMarketplaces(region)
    if (marketplace.value == '')
      setMarketplace({
        label: props.marketplaceCountry,
        value: props.marketplaceId,
      })
    setMarketplaceValidationState('')
    setMarketplaces(region_marketplaces)
    setMarketplaceDisabled(false)
  }

  const marketplaceOnFocus = (region) => {
    region_marketplaces = regionMarketplaces(region)
    setMarketplaces(region_marketplaces)
  }

  const marketplaceChange = (marketplace_id) => {
    const filtered = marketplace_data.filter(function (m) {
      return m.marketplace_id === marketplace_id
    })
    if (filtered.length > 0) {
      setMarketplaceCountryCode(filtered[0].country_code)
    }
  }

  const isValidated = async () => {
    if (
      nameValidationState === 'has-success' &&
      emailValidationState === 'has-success' &&
      regionValidationState === 'has-success' &&
      marketplaceValidationState === 'has-success'
    ) {
      const seller = {
        company_public_id: companyPublicId,
        ...(managingCompanyPublicId && {
          managing_company_public_id: managingCompanyPublicId,
        }),
        public_id: params.public_id ? params.public_id : publicId,
        name: name,
        email: email,
        marketplace_region: region.value,
        primary_marketplace_id: marketplace.value,
      }
      // create/update seller via api
      const response = await apiSellers
        .post(JSON.stringify(seller))
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        queryClient.invalidateQueries('GET/auth/user') // force a refetch to ensure correct onboarding redirect behaviour and updated primary seller in navbar
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
        history.push('/admin/onboarding/seller/' + response.data.public_id)
        return true
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response.data.message,
            null
          )
        }
        return false
      }
    } else {
      if (nameValidationState !== 'has-success') {
        setNameValidationState('has-danger')
      }
      if (emailValidationState !== 'has-success') {
        setEmailValidationState('has-danger')
      }
      if (regionValidationState !== 'has-success') {
        setRegionValidationState('has-danger')
      }
      if (marketplaceValidationState !== 'has-success') {
        setMarketplaceValidationState('has-danger')
      }
      return false
    }
  }

  useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated()
    },
  }))

  // Set initial email, name, and region states
  useEffect(() => {
    setEmail(props.email)
    setName(props.name)
    setRegion(
      props.region === '' ? '' : { label: props.region, value: props.region }
    )
    setMarketplace({
      label: props.marketplaceCountry,
      value: props.marketplaceId,
    })
    if (props.email != '' && props.name != '') {
      setEmailValidationState('has-success')
      setNameValidationState('has-success')
      setRegionValidationState('has-success')
    }
  }, [props.email, props.name, props.region, props.marketplaceId])

  // Populate marketplaces based on initial region
  useEffect(() => {
    if (region.value) {
      regionSelect(region.value)
    }
  }, [region.value])

  // Set initial marketplace state
  useEffect(() => {
    if (props.marketplaceId !== '') {
      setMarketplace({
        label: props.marketplaceCountry,
        value: props.marketplaceId,
      })
      setMarketplaceValidationState('has-success')
    }
  }, [region, props.marketplaceId])

  return (
    <>
      <h4 className="info-text">
        Let's start with your seller account details.
      </h4>
      <Row className="justify-content-center mt-5">
        <Col sm="5">
          <InputGroup
            className={classnames(nameValidationState, {
              'input-group-focus': nameFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-badge" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={name}
              name="name"
              placeholder="Seller Account Name..."
              type="text"
              onChange={(e) =>
                inputFieldChange(
                  e,
                  setName,
                  setNameValidationState,
                  'length',
                  1
                )
              }
              onFocus={(e) => setNameFocus(true)}
              onBlur={(e) => setNameFocus(false)}
            />
            {nameValidationState === 'has-danger' ? (
              <label className="error">This field is required.</label>
            ) : null}
          </InputGroup>
          <InputGroup
            className={classnames(emailValidationState, {
              'input-group-focus': emailFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-email-85" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={email}
              name="email"
              placeholder="Seller Central Email..."
              type="email"
              onChange={(e) =>
                inputFieldChange(e, setEmail, setEmailValidationState, 'email')
              }
              onFocus={(e) => setEmailFocus(true)}
              onBlur={(e) => setEmailFocus(false)}
            />
            {emailValidationState === 'has-danger' ? (
              <label className="error">This field is required.</label>
            ) : null}
          </InputGroup>
        </Col>
        <Col sm="5">
          <InputGroup
            className={classnames(regionValidationState, {
              'input-group-focus': regionFocus,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-world" />
              </InputGroupText>
            </InputGroupAddon>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              name="region"
              value={region}
              placeholder="Region..."
              onChange={(e) => {
                inputFieldChange(
                  e,
                  setRegion,
                  setRegionValidationState,
                  'select_length',
                  1
                )
                regionSelect(e.value)
              }}
              onFocus={(e) => setRegionFocus(true)}
              onBlur={(e) => setRegionFocus(false)}
              options={regions}
            />
            {regionValidationState === 'has-danger' ? (
              <label className="error">This field is required.</label>
            ) : null}
          </InputGroup>
          <InputGroup
            className={classnames(marketplaceValidationState, {
              'input-group-focus': marketplaceFocus,
              'input-group-react-select-disabled': marketplaceDisabled,
            })}
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="tim-icons icon-cart" />
              </InputGroupText>
            </InputGroupAddon>
            <Select
              isDisabled={marketplaceDisabled}
              className="react-select info"
              classNamePrefix="react-select"
              name="marketplace"
              value={marketplace}
              placeholder="Primary marketplace..."
              onChange={(e) => {
                inputFieldChange(
                  e,
                  setMarketplace,
                  setMarketplaceValidationState,
                  'select_length',
                  1
                )
                marketplaceChange(e.value)
              }}
              onFocus={(e) => {
                setMarketplaceFocus(true)
                marketplaceOnFocus(region.value)
              }}
              onBlur={(e) => setMarketplaceFocus(false)}
              options={marketplaces}
            />
            {marketplaceValidationState === 'has-danger' ? (
              <label className="error">This field is required.</label>
            ) : null}
          </InputGroup>
        </Col>
      </Row>
    </>
  )
})

export default OnboardingSellerStep0
