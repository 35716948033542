import PlainImageLoader from 'components/ContentLoaders/PlainImageLoader'
import React, { Fragment, useState, useRef, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Progress,
  NavLink,
} from 'reactstrap'
import useAlert from 'services/hooks/useAlert'
import imagePlaceholderImg from 'assets/img/image-placeholder-sq.png'
import imagePlaceholderImgLightMode from 'assets/img/image-placeholder-sq-light-mode.png'
import ThemeContext from 'services/providers/ThemeContext'

const UploadThumbnailGallery = ({
  assets,
  assetType,
  onPick,
  onUpload,
  uploading = false,
  updating = false,
  videoURL = '',
}) => {
  const fileInput = useRef(null)
  const [file, setFile] = useState(null)
  const [videoSrc, setVideoSrc] = useState('')
  const [videoMeta, setVideoMeta] = useState({})
  const [progress, setProgress] = useState(videoURL ? 100 : 0) // dodge
  const { lightMode } = useContext(ThemeContext)
  const { addAlert } = useAlert()

  const sortedAssets = assets // .sort((a, b) => (a.picked === b.picked)? 0 : a? -1 : 1)

  const onThumbnailClick = (key) => {
    setPicked(picked.map((p, i) => (true ? key === i : false)))
  }

  useEffect(() => {
    if (file && videoMeta) {
      onUpload(file, videoMeta)
    }
  }, [videoMeta])

  useEffect(() => {
    if (assetType === 'video') {
      if (!file && !videoURL) {
        setVideoSrc(undefined)
        return
      }
      const objectUrl = videoURL ? videoURL : URL.createObjectURL(file)
      setVideoSrc(objectUrl)
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
    } else {
      if (file) {
        onUpload(file)
      }
    }
  }, [file, assetType])

  function readerErrorHandler(evt) {
    switch (evt.target.error.code) {
      case evt.target.error.NOT_FOUND_ERR:
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          'File not found!',
          null
        )
        break
      case evt.target.error.NOT_READABLE_ERR:
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          'File is not readable',
          null
        )
        break
      case evt.target.error.ABORT_ERR:
        break // noop
      default:
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          'An error occurred reading this file',
          null
        )
    }
  }

  const handleAssetChange = (e) => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onerror = readerErrorHandler
    reader.onprogress = (e) =>
      setProgress(Math.round((e.loaded * 100) / e.total))
    reader.onabort = (e) =>
      addAlert(
        'danger',
        'tim-icons icon-alert-circle-exc',
        'File read cancelled',
        null
      )
    reader.onloadend = () => {
      setFile(file)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
    fileInput.current.value = '' // reset so fileInput onChange triggers even if SAME file selected
  }

  const [picked, setPicked] = useState(
    sortedAssets ? sortedAssets.map((i) => i.picked) : []
  )

  const Image = ({ url, picked }) => {
    const [loaded, setLoaded] = useState(false)
    return (
      <Fragment>
        <div className={classNames({ 'd-none': !loaded })}>
          <img
            className="img-fluid img-raised img-selectable"
            src={url}
            alt=""
            onLoad={() => setLoaded(true)}
          />
          {!picked ? (
            <div className="overlay-text">
              Set as
              <br /> default
            </div>
          ) : null}
          {picked ? (
            <i className="tim-icons icon-check-2 tick-overlay tick-circle" />
          ) : null}
        </div>
        <PlainImageLoader className={classNames({ 'd-none': loaded })} />
      </Fragment>
    )
  }

  return (
    <>
      <Row className="thumbnail-gallery">
        {assetType === 'video' && progress > 0 ? (
          <Col xl="6" sm="12" xs="12">
            <Card className="card-plain m-0">
              <CardBody className="p-0">
                <div className="video-container-16-9">
                  {file && file.type === 'video/quicktime' ? (
                    <div className="upload-overlay">
                      Cannot preview quicktime or .mov videos
                    </div>
                  ) : null}
                  {videoURL ? (
                    <div className="upload-overlay cover"></div>
                  ) : null}
                  <video
                    controls={
                      (file && file.type !== 'video/quicktime') || videoURL
                        ? true
                        : false
                    }
                    muted
                    autoPlay={true}
                    src={videoSrc}
                    className="video-player img-raised"
                    controlsList="nodownload"
                    onLoadedMetadata={(e) => {
                      setVideoMeta({
                        videoHeight: e.target.videoHeight,
                        videoWidth: e.target.videoWidth,
                        duration: e.target.duration,
                      })
                    }}
                  />
                </div>
                {progress === 100 ? (
                  !videoURL ? (
                    <div className="mt-2">
                      <NavLink
                        className="d-inline p-0 div-link"
                        onClick={() => fileInput.current.click()}
                      >
                        Change video
                      </NavLink>
                    </div>
                  ) : null
                ) : (
                  <div className="progress-container progress-primary mt-3 pl-1 pr-1">
                    <Progress max="100" value={progress} />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        ) : null}
        <Col
          xl="3"
          sm="4"
          xs="4"
          className={classNames({
            'd-none': assetType === 'video' && progress > 0,
          })}
        >
          <Card className="card-plain m-0">
            <CardBody className="text-center p-0">
              <div
                className="upload-img-wrapper fileinput"
                onClick={() => fileInput.current.click()}
              >
                <input
                  type="file"
                  accept={
                    assetType === 'image'
                      ? 'image/*'
                      : assetType === 'video'
                      ? 'video/mp4,video/x-m4v,video/quicktime'
                      : assetType === 'csv'
                      ? 'text/csv'
                      : ''
                  }
                  onChange={handleAssetChange}
                  ref={fileInput}
                />
                <img
                  className="img-fluid img-raised"
                  src={
                    lightMode
                      ? imagePlaceholderImgLightMode
                      : imagePlaceholderImg
                  }
                  alt=""
                />
                <div className="upload-overlay">
                  {uploading ? (
                    <Spinner role="status" className="opacity-7" />
                  ) : (
                    <Fragment>
                      <i className="tim-icons icon-simple-add plus-circle text-white" />
                      <br />
                      Upload
                    </Fragment>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {sortedAssets?.map((asset, key) => {
          return (
            <Col xl="3" sm="4" xs="4" key={key} className="mb-4">
              <Card className="card-plain m-0">
                <CardBody className="text-center p-0">
                  <div
                    className="img-wrapper"
                    onClick={() => {
                      onPick(asset)
                      onThumbnailClick(key)
                    }}
                  >
                    <Image url={asset.url} picked={picked[key]} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

export default UploadThumbnailGallery
