import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import Select from 'react-select'
// reactstrap components
import {
  Row,
  Col,
  InputGroup,
  Button,
  UncontrolledTooltip,
  Form,
  Label,
  Spinner,
} from 'reactstrap'
import UploadThumbnailGallery from 'components/CustomUpload/UploadThumbnailGallery'
import { inputFieldChange } from 'services/Utils'

const VideoMedia = ({
  brands,
  products,
  failedSpec,
  video,
  mediaUpdate,
  uploadingMedia,
  updatingMedia,
  failedSpecUpdate,
}) => {
  const [brand, setBrand] = useState()
  const [brandValidation, setBrandValidation] = useState('')
  const [brandFocus, setBrandFocus] = useState('')
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedProductsValidation, setSelectedProductsValidation] =
    useState('')
  const [selectedProductsFocus, setSelectedProductsFocus] = useState('')
  const [videoFile, setVideoFile] = useState(null)
  const [videoFileValidation, setVideoFileValidation] = useState('')
  const [videoValidationErrors, setVideoValidationErrors] = useState([
    'Please upload a video.',
  ])
  const history = useHistory()
  const hasMediaId = video?.media_id || video?.creative_asset_id;

  useEffect(() => {
    if (hasMediaId) {
      setBrand({ value: video.brand_id, label: video.brand })
      setBrandValidation('has-success')
      setSelectedProducts(
        video.product_asins.map((asin) => ({ value: asin, label: asin }))
      )
      setSelectedProductsValidation(
        video.product_asins.length > 0 ? 'has-success' : ''
      )
      setVideoFileValidation('has-success')
      failedSpecUpdate()
    }
  }, [video])

  const onUpload = (file, videoMeta) => {
    setVideoFile(file)
    setVideoFileValidation(validateVideo(file, videoMeta))
    failedSpecUpdate()
  }

  const validateVideo = (file, videoMeta) => {
    let errors = []
    if (file) {
      if (file.size / 1024 / 1024 > 500) {
        errors.push('Video must be 500 MB or smaller,')
      }
      if (videoMeta.duration < 6.0 || videoMeta.duration > 46.0) {
        errors.push('Video must be between 6 and 45 seconds long,')
      }
      if (
        file.type !== 'video/quicktime' && // can't check dimension of quicktime/mov files
        ([1280, 1920, 3840].indexOf(videoMeta.videoWidth) === -1 ||
          [1280, 1920, 3840].indexOf(videoMeta.videoWidth) !==
          [720, 1080, 2160].indexOf(videoMeta.videoHeight))
      ) {
        errors.push(
          'Video dimensions must be 1280 x 720px, 1920 x 1080px or 3840 x 2160px'
        )
      }
    }
    setVideoValidationErrors(errors)
    return errors.length > 0 ? 'has-danger' : 'has-success'
  }

  const saveVideoClick = (e) => {
    if (
      brandValidation === 'has-success' &&
      selectedProductsValidation === 'has-success' &&
      videoFileValidation === 'has-success'
    ) {
      mediaUpdate({
        brand_entity_id: brand?.value,
        action: 'upload_brand_media',
        product_asins: selectedProducts?.map((p) => p.value),
        file: videoFile,
      })
    } else {
      if (brand?.value === '') {
        setBrandValidation('has-danger')
      }
      if (selectedProducts?.length === 0) {
        setSelectedProductsValidation('has-danger')
      }
      if (videoFile === null) {
        setVideoFileValidation('has-danger')
      }
      e.preventDefault()
    }
  }

  const updateVideoClick = (e) => {
    if (
      brandValidation === 'has-success' &&
      selectedProductsValidation === 'has-success'
    ) {
      mediaUpdate({
        brand_entity_id: brand?.value,
        action: 'update_brand_media',
        media_id: video?.media_id,
        creative_asset_id: video?.creative_asset_id,
        product_asins: selectedProducts?.map((p) => p.value),
      })
    } else {
      if (brand?.value === '') {
        setBrandValidation('has-danger')
      }
      if (selectedProducts?.length === 0) {
        setSelectedProductsValidation('has-danger')
      }
      e.preventDefault()
    }
  }

  return (
    <>
      <Form className="form-horizontal">
        <Row className="align-items-center">
          <Label sm="3" className="pt-0 mt-n1">
            Brand
            <Button
              color="link"
              id="video-brand-label-tooltip"
              title=""
              type="button"
              className="p-0 m-0 mt-n1 pl-2"
            >
              <i className="fas fa-question-circle"></i>
            </Button>
            <UncontrolledTooltip delay={0} target="video-brand-label-tooltip">
              Your brand must be enrolled in Amazon Brand Registry for it to
              appear here.
            </UncontrolledTooltip>
          </Label>
          <Col sm="9" md="6">
            <InputGroup
              className={classNames(brandValidation, {
                'input-group-focus': brandFocus,
                'cursor-not-allowed': hasMediaId ? true : false,
              })}
            >
              <Select
                isDisabled={hasMediaId ? true : false}
                className="react-select info"
                classNamePrefix="react-select"
                name="brand"
                value={brand}
                placeholder=""
                onChange={(e) => {
                  inputFieldChange(
                    e,
                    setBrand,
                    setBrandValidation,
                    'select_length',
                    1
                  )
                }}
                options={brands?.map((b) => {
                  return {
                    value: b.brand_entity_id,
                    label: b.brand_registry_name,
                  }
                })}
                onFocus={(e) => setBrandFocus(true)}
                onBlur={(e) => setBrandFocus(false)}
              />
              {brandValidation === 'has-danger' ? (
                <label className="error">This field is required.</label>
              ) : null}
            </InputGroup>
          </Col>
        </Row>
        {brand?.value ? (
          <Fragment>
            <Row className="align-items-center">
              <Label sm="3" className="pt-0 mt-n1">
                Associated Products
                <Button
                  color="link"
                  id="video-products-label-tooltip"
                  title=""
                  type="button"
                  className="p-0 m-0 mt-n1 pl-2"
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="video-products-label-tooltip"
                >
                  Associate one or more product ASINs with this video.
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <InputGroup
                  className={classNames(selectedProductsValidation, {
                    'input-group-focus': selectedProductsFocus,
                  })}
                >
                  <Select
                    className="react-select info primary-badges"
                    classNamePrefix="react-select"
                    placeholder={'Choose or search the list'}
                    name={'videoProductsMultipleSelect'}
                    closeMenuOnSelect={false}
                    isMulti={true}
                    value={selectedProducts}
                    onChange={(e) => {
                      inputFieldChange(
                        e,
                        setSelectedProducts,
                        setSelectedProductsValidation,
                        'select_multi_length',
                        1
                      )
                    }}
                    options={products}
                    onFocus={(e) => setSelectedProductsFocus(true)}
                    onBlur={(e) => setSelectedProductsFocus(false)}
                  />
                  {selectedProductsValidation === 'has-danger' ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </InputGroup>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Label sm="3" className="pt-0 mt-n1">
                Video
                <Button
                  color="link"
                  id="video-label-tooltip"
                  title=""
                  type="button"
                  className="p-0 m-0 mt-n1 pl-2"
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip delay={0} target="video-label-tooltip">
                  <strong>Video requirements</strong>
                  <br />
                  - 6-45 sec long
                  <br />
                  - 16:9 aspect ratio
                  <br />
                  - 1280 x 720px, 1920 x 1080px or 3840 x 2160px
                  <br />
                  - 23.976, 24, 25, 29.97, 29.98, or 30 fps
                  <br />
                  - 1 Mbps or higher bit rate
                  <br />
                  - H.264 or H.265 codec
                  <br />
                  - 500 MB or smaller
                  <br />
                  - MP4 or MOV file
                  <br />- Audio bit rate of 96 kbps or higher
                </UncontrolledTooltip>
              </Label>
              <Col sm="9" md="6">
                <UploadThumbnailGallery
                  assets={[]}
                  assetType={'video'}
                  onPick={(e) => e.preventDefault}
                  onUpload={onUpload}
                  uploading={uploadingMedia}
                  videoURL={
                    hasMediaId
                      ? video.published_media_url || 'pending'
                      : ''
                  }
                />
                {videoFileValidation === 'has-danger' ? (
                  <label>
                    <ul className="list-inline">
                      {videoValidationErrors.map((error, idx) => (
                        <li key={idx} className="text-danger-states">
                          {error}
                        </li>
                      ))}
                    </ul>
                  </label>
                ) : null}
                {failedSpec ? (
                  <label>
                    <ul className="list-inline">
                      {failedSpec.map((error, idx) => (
                        <li key={idx} className="text-danger-states">
                          Error on {error.type}. Valid values [{error.expectedValues.join(', ')}]. Current value [{error.value}]
                        </li>
                      ))}
                    </ul>
                  </label>
                ) : null}
              </Col>
            </Row>
          </Fragment>
        ) : null}
        <Row className="mt-5">
          <Label sm="3"></Label>
          <Col sm="9" md="6">
            <Button
              color="primary"
              className={classNames('btn-wd', {
                disabled: uploadingMedia || updatingMedia,
              })}
              onClick={(e) =>
                hasMediaId ? updateVideoClick(e) : saveVideoClick(e)
              }
            >
              {uploadingMedia || updatingMedia ? (
                <Spinner size="sm" role="status"></Spinner>
              ) : hasMediaId ? (
                'Update'
              ) : (
                'Save Video'
              )}
            </Button>
            <Button
              color="primary"
              className={classNames('btn-simple')}
              onClick={(e) => history.push('/admin/creatives/video')}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default VideoMedia
