import React, { useEffect, useState, useMemo, Fragment, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import Slider from 'nouislider'
import { priceFromTiers } from 'services/Utils'

// reactstrap components
import {
  Spinner,
  Button,
  ButtonGroup,
  CardHeader,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  CardSubtitle,
  ListGroupItem,
  ListGroup,
  Collapse,
  UncontrolledTooltip,
  Row,
  Col,
  NavLink,
} from 'reactstrap'

import getSymbolFromCurrency from 'currency-symbol-map'
import ReactTable from 'components/ReactTable/ReactTable.js'
import useStripe from 'services/hooks/useStripe'

import cardPrimaryImg from 'assets/img/card-primary.png'
import cardSuccessImg from 'assets/img/card-success.png'
import cardWarningImg from 'assets/img/card-warning.png'
import cardDangerImg from 'assets/img/card-danger.png'

const PricingContent = ({
  prices,
  features,
  singleCardProduct,
  isBilling,
  pricingCardBtnClick,
  purchaseLoading,
}) => {
  const history = useHistory()
  const [openedCollapses, setOpenedCollapses] = useState([])

  const [productNames] = useState(['Free', 'Pro'])
  const [sortedPrices, setSortedPrices] = useState([])
  const [interval] = useState('month')
  const [recurringUsageType] = useState('metered')
  const [priceCalculatorValue, setPriceCalculatorValue] = useState(99)

  const [hideCompareTable, setHideCompareTable] = useState(isBilling)

  const calculatorSliderRef = useRef(null)

  const stripe = useStripe()

  const cardClasses = (numberCards) => {
    const numCards = singleCardProduct ? 1 : numberCards
    return [
      {
        color: numCards > 1 ? 'card-success' : 'card-primary',
        white: numCards > 1 ? true : false,
        raised: false,
        borderLeftRadius: true,
        borderRightRadius: numCards > 1 ? false : true,
      },
      {
        color: 'card-primary',
        white: false,
        raised: true,
        borderLeftRadius: false, // since raised
        borderRightRadius: false,
      },
      {
        color: 'card-warning',
        white: true,
        raised: false,
        borderLeftRadius: false,
        borderRightRadius: numCards > 3 ? false : true,
      },
      {
        color: 'card-danger',
        white: true,
        raised: false,
        borderLeftRadius: false,
        borderRightRadius: true,
      },
    ]
  }

  useEffect(() => {
    setSortedPrices(
      prices.sort(function (a, b) {
        return (
          productNames.indexOf(a.product_name) -
          productNames.indexOf(b.product_name)
        )
      })
    )
  }, [prices, productNames])

  const formatCurrency = (num) => {
    return Math.round(((Number(num) || 0) + Number.EPSILON) * 100) / 100
  }

  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  const collapsesToggle = (e, collapse) => {
    e.preventDefault()
    if (openedCollapses.includes(collapse)) {
      setOpenedCollapses(openedCollapses.filter((item) => item !== collapse))
    } else {
      setOpenedCollapses([...openedCollapses, collapse])
    }
  }

  const productColumns = (productNames) => {
    if (productNames.length > 0) {
      const productHeaders = productNames.map((productName) => {
        return {
          Header: (
            <div className="text-center pricing-compare-table headings">
              {productName}
            </div>
          ),
          accessor: productName,
          sortable: false,
          Cell: (row) => (
            <div className="text-center">
              {row.value === 'yes' ? (
                <strong>
                  <i className="tim-icons icon-check-2 text-success" />
                </strong>
              ) : row.value === 'no' ? (
                '-'
              ) : (
                row.value
              )}
            </div>
          ),
        }
      })
      return productHeaders
    } else {
      return []
    }
  }

  const featureCompareColumns = useMemo(
    () =>
      [
        // {
        //   // Build our expander column
        //   id: 'expander', // Make sure it has an ID
        //   Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        //     <span {...getToggleAllRowsExpandedProps()}>
        //       {isAllRowsExpanded ? '👇' : '👉'}
        //     </span>
        //   ),
        //   Cell: ({ row }) =>
        //     // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
        //     // to build the toggle for expanding a row
        //     row.canExpand ? (
        //       <span
        //         {...row.getToggleRowExpandedProps({
        //           style: {
        //             // We can even use the row.depth property
        //             // and paddingLeft to indicate the depth
        //             // of the row
        //             paddingLeft: `${row.depth * 2}rem`,
        //           },
        //         })}
        //       >
        //         {row.isExpanded ? '👇' : '👉'}
        //       </span>
        //     ) : null,
        // },
        {
          Header: '',
          accessor: 'feature',
          sortable: false,
          Cell: (props) => (
            <Fragment>
              <span
                className={
                  props.row.canExpand
                    ? 'pricing-compare-table subheadings'
                    : 'tab'
                }
              >
                {props.value}
              </span>
              {props.row.original['description'] ? (
                <Fragment>
                  <Button
                    color="link"
                    id={
                      'pricing-compare-feature-tooltip-' +
                      props.row.id.replace('.', '-')
                    }
                    title=""
                    type="button"
                    className="p-0 m-0 mt-n1 pl-2"
                  >
                    <i className="fas fa-question-circle"></i>
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target={
                      'pricing-compare-feature-tooltip-' +
                      props.row.id.replace('.', '-')
                    }
                  >
                    {props.row.original['description']}
                  </UncontrolledTooltip>
                </Fragment>
              ) : null}
            </Fragment>
          ),
        },
      ].concat(productColumns(productNames)),
    [productNames]
  )

  useEffect(() => {
    if (
      calculatorSliderRef.current !== null &&
      typeof calculatorSliderRef.current.noUiSlider === 'undefined'
    ) {
      Slider.create(calculatorSliderRef.current, {
        range: {
          min: [0, 100],
          // '12.5%': [2500, 250],
          '25%': [5000, 500],
          // '37.5%': [10000, 1000],
          '50%': [25000, 2500],
          // '62.5%': [50000, 5000],
          '75%': [100000, 10000],
          // '87.5%': [250000, 25000],
          max: [500000, 50000],
        },
        start: [5000],
        connect: [true, false],
        pips: {
          mode: 'values',
          values: [
            0,
            // 2500,
            5000,
            // 10000,
            25000,
            // 50000,
            100000,
            // 250000,
            500000,
          ],
          density: 20,
          format: {
            to: function (value) {
              return Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }).format(value)
            },
          },
        },
        tooltips: [true],
        format: {
          // 'to' the formatted value. Receives a number.
          to: function (value) {
            return Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(value)
          },
          // 'from' the formatted value.
          // Receives a string, should return a number.
          from: function (value) {
            return Number(value)
          },
        },
      })
    }
  }, [])
  useEffect(() => {
    if (calculatorSliderRef.current !== null) {
      calculatorSliderRef.current.noUiSlider.on('update', priceCalculator)
    }
  }, [prices])
  const priceCalculator = (
    values,
    handle,
    unencoded,
    tap,
    positions,
    noUiSlider
  ) => {
    // values: Current slider values (array);
    // handle: Handle that caused the event (number);
    // unencoded: Slider values without formatting (array);
    // tap: Event was caused by the user tapping the slider (boolean);
    // positions: Left offset of the handles (array);
    // noUiSlider: slider public Api (noUiSlider);
    const meteredPrice = prices.find(
      (p) =>
        p.recurring_interval === interval &&
        p.recurring_usage_type === recurringUsageType
    )
    if (meteredPrice && meteredPrice['tiers']?.length > 0) {
      setPriceCalculatorValue(
        priceFromTiers(
          meteredPrice['tiers'],
          unencoded[0],
          meteredPrice['tiers_mode']
        )
      )
    }
  }

  return (
    <>
      <Fragment>
        <Row className="mb-5">
          <Col
            className="ml-auto mr-auto"
            lg={Math.min(12, singleCardProduct ? 8 : productNames?.length * 4)}
          >
            <Row>
              {sortedPrices
                ?.filter((price) =>
                  singleCardProduct
                    ? price.product_name === singleCardProduct
                    : price
                ) // for billing page
                .filter((price) => price.recurring_interval === interval)
                .map((price, index) => {
                  return (
                    <Col
                      lg={singleCardProduct ? 12 : 12 / productNames.length}
                      md="6"
                      key={price.product_name}
                    >
                      <Card
                        className={classNames(
                          'card-pricing',
                          cardClasses(productNames.length)[index].color,
                          {
                            'card-white': cardClasses(productNames.length)[
                              index
                            ].white,
                            'card-raised': cardClasses(productNames.length)[
                              index
                            ].raised,
                            'card-pricing-left-radius': cardClasses(
                              productNames.length
                            )[index].borderLeftRadius,
                            'card-pricing-right-radius': cardClasses(
                              productNames.length
                            )[index].borderRightRadius,
                          }
                        )}
                      >
                        <CardBody>
                          <CardTitle tag="h1">{price.product_name}</CardTitle>
                          <CardImg
                            alt="..."
                            src={
                              [
                                cardPrimaryImg,
                                cardSuccessImg,
                                cardWarningImg,
                                cardDangerImg,
                              ][index]
                            }
                          />
                          <ListGroup>
                            <CardSubtitle tag="h5" className="">
                              {price.product_metadata?.description}
                            </CardSubtitle>
                            {price.product_metadata?.downgrade_product_name ? (
                              <ListGroupItem>
                                Everything in{' '}
                                <strong>
                                  {
                                    price.product_metadata
                                      ?.downgrade_product_name
                                  }
                                </strong>{' '}
                                plus:
                              </ListGroupItem>
                            ) : null}
                            {price.product_metadata?.top_features.map(
                              (top_feature) => {
                                return (
                                  <ListGroupItem className="" key={top_feature}>
                                    <i className="tim-icons icon-check-2" />{' '}
                                    {top_feature}
                                  </ListGroupItem>
                                )
                              }
                            )}
                          </ListGroup>
                          <div className="card-prices">
                            <h3 className="text-on-front">
                              <sup>{getSymbolFromCurrency(price.currency)}</sup>
                              {new Intl.NumberFormat('en-US').format(
                                formatCurrency(
                                  price.tiers[0]?.flat_amount_decimal / 100
                                )
                              )}
                              <span>/mo</span>
                              {price.product_name === 'Pro' ? (
                                <p className=""> + % of ad sales</p>
                              ) : null}
                            </h3>
                            <h5 className="text-on-back">
                              {new Intl.NumberFormat('en-US').format(
                                formatCurrency(
                                  price.tiers[0]?.flat_amount_decimal / 100
                                )
                              )}
                            </h5>
                            {price.product_name === 'Pro' ? (
                              <p className="plan">
                                First $5k of ad sales FREE each month
                              </p>
                            ) : null}
                          </div>
                        </CardBody>
                        <CardFooter className="text-center mb-3 mt-3">
                          <Button
                            className={classNames(
                              'btn-round btn-just-icon btn-wd',
                              { disabled: purchaseLoading || !stripe }
                            )}
                            color={cardClasses(productNames.length)[
                              index
                            ].color.replace('card-', '')}
                            value={isBilling ? price.id : null}
                            onClick={(e) =>
                              isBilling
                                ? pricingCardBtnClick(stripe, e)
                                : history.push(
                                    '/auth/register' // consider buy btn direct on pricing page or push to billing if willing to get user on pricing page
                                  )
                            }
                          >
                            {isBilling ? (
                              purchaseLoading === true ? (
                                <Spinner size="sm" role="status"></Spinner>
                              ) : (
                                'Upgrade to ' + price.product_name
                              )
                            ) : (
                              'Get Started'
                            )}
                          </Button>
                        </CardFooter>
                      </Card>
                      {false && price.product_name === singleCardProduct ? (
                        <h5 className="card-ad-sales-disclaimer mt-n4">
                          * Only ad sales managed and attributed to Autron
                        </h5>
                      ) : null}
                    </Col>
                  )
                })}
            </Row>
            <div className="price-calculator-slider">
              <Row className="mt-5">
                <Col className="col-xs-6 col-xl-7 mt-auto">
                  <h4 className="title mb-3">
                    What's your monthly Amazon <u>ad</u> sales?
                  </h4>
                </Col>
                <Col className="col-xs-6 col-xl-5 text-right">
                  <p>Your estimated Pro price</p>
                  <div className="price-calculator-slider-value-wrapper">
                    <h2 className="price-calculator-slider-value mb-0">
                      {Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(priceCalculatorValue)}
                      <span className="text-small d-inline">/mo</span>
                    </h2>
                  </div>
                </Col>
              </Row>
              <div className="slider mb-lg mt-3" ref={calculatorSliderRef} />
            </div>
            <p
              className={classNames(
                'form-control-static div-link text-center',
                { 'd-none': !isBilling }
              )}
              onClick={(e) => setHideCompareTable(!hideCompareTable)}
            >
              Which plan is right for you?
            </p>
          </Col>
        </Row>

        {prices && !hideCompareTable ? (
          <Row className="mb-5">
            <Col className="mb-5" md="12">
              <h3 className="title mt-5">Compare plans</h3>
              {features?.length > 0 ? (
                <ReactTable
                  columns={featureCompareColumns}
                  data={features}
                  loading={false}
                  total={features.length}
                  expandRowsByDefault={true}
                />
              ) : null}
            </Col>
          </Row>
        ) : null}

        {isBilling ? null : (
          <Fragment>
            <Row className="mt-5">
              <Col className="ml-auto mr-auto text-center" md="12">
                <h1 className="title">Getting started is easy</h1>
                <p className="description mb-4">
                  Create an account, connect your data and start growing your
                  business.
                </p>
                <Button
                  className={classNames('btn-round mt-3 mb-5')}
                  color="primary"
                  tag={Link}
                  to="/auth/register"
                  size="md"
                >
                  Get Started
                </Button>
              </Col>
              <Col lg="6">
                <Row className=""></Row>
              </Col>
            </Row>

            <Row>
              <Col md="12 mt-5">
                <Card>
                  <CardHeader>
                    <CardTitle className="font-weight-bold" tag="h3">
                      Questions?
                    </CardTitle>
                  </CardHeader>
                  <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                  >
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={openedCollapses.includes(
                            'collapseOne'
                          )}
                          href="#q1"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={(e) => collapsesToggle(e, 'collapseOne')}
                        >
                          How do I upgrade to PRO?{' '}
                          <i className="tim-icons icon-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes('collapseOne')}
                      >
                        <CardBody>
                          You can upgrade anytime within your account billing
                          dashboard. Autron will automatically provide the
                          additional features once done so.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={openedCollapses.includes(
                            'collapseTwo'
                          )}
                          href="#q2"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={(e) => collapsesToggle(e, 'collapseTwo')}
                        >
                          What type of Amazon sellers do you supprt?{' '}
                          <i className="tim-icons icon-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes('collapseTwo')}
                      >
                        <CardBody>
                          Currently FBA and FBM only. If you're a vendor, please
                          register your interest with us at{' '}
                          <NavLink
                            className="d-inline p-0"
                            href={`mailto:${process.env.NEXT_PUBLIC_SUPPORT_EMAIL}?subject=Interested in Autron as a Non-FBA seller.`}
                            target="_blank"
                          >
                            {process.env.NEXT_PUBLIC_SUPPORT_EMAIL}
                          </NavLink>{' '}
                          and we'll do our best to support you.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={openedCollapses.includes(
                            'collapseThree'
                          )}
                          href="#q3"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={(e) => collapsesToggle(e, 'collapseThree')}
                        >
                          Can I try for free if I have more than 1 product?{' '}
                          <i className="tim-icons icon-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes('collapseThree')}
                      >
                        <CardBody>
                          Yes you can. With a Free account you choose which
                          product ASIN you want Autron to manage. You can
                          continue to manage your other products ads yourself.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={openedCollapses.includes(
                            'collapseFour'
                          )}
                          href="#q4"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={(e) => collapsesToggle(e, 'collapseFour')}
                        >
                          Can I cancel at any time?{' '}
                          <i className="tim-icons icon-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes('collapseFour')}
                      >
                        <CardBody>
                          Sure. You can cancel at any time within your account
                          billing dashboard.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={openedCollapses.includes(
                            'collapseFive'
                          )}
                          href="#q5"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={(e) => collapsesToggle(e, 'collapseFive')}
                        >
                          What payment forms do you accept?{' '}
                          <i className="tim-icons icon-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes('collapseFive')}
                      >
                        <CardBody>
                          We accept most major credit cards including Visa,
                          Mastercard, American Express and Discover.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={openedCollapses.includes(
                            'collapseSix'
                          )}
                          href="#q6"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={(e) => collapsesToggle(e, 'collapseSix')}
                        >
                          What is your refund policy?{' '}
                          <i className="tim-icons icon-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes('collapseSix')}
                      >
                        <CardBody>
                          We strive to build the best automated ad solution in
                          the world. If you are unhappy with Autron for any
                          reason, we provide a no-questions-asked refund within
                          30 days of purchase. More info in our TOS.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={openedCollapses.includes(
                            'collapseSeven'
                          )}
                          href="#q7"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={(e) => collapsesToggle(e, 'collapseSeven')}
                        >
                          Which marketplaces do you support?{' '}
                          <i className="tim-icons icon-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={openedCollapses.includes('collapseSeven')}
                      >
                        <CardBody>
                          Autron supports United States, Canada, Mexico, United
                          Kingdom, France, Germany, Italy, Spain and Australia.
                          Autron has partial compatibility with marketplaces in
                          the Netherlands, India, United Arab Emirates,
                          Singapore, and Brazil. Some features of Autron are
                          currently limited to the U.S. market.
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                </Card>
              </Col>
            </Row>
          </Fragment>
        )}
      </Fragment>
    </>
  )
}

export default PricingContent
