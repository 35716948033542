import React, { useState, useEffect } from 'react'
import IframeResizer from 'iframe-resizer-react'
import { useQuery, useQueryClient } from 'react-query'
import apiAnalytics from 'services/api/Analytics'
import { useHistory } from 'react-router-dom'

// reactstrap components
import { Row, Col, CardTitle } from 'reactstrap'
import SkeletonInsights from 'components/SkeletonCard/SkeletonInsights'

const InsightsDashboard = ({ insight, seller }) => {
  const analyticsUrl = process.env.REACT_APP_FRONTEND_APP_BASE_URL
  
  const [iframeUrl, setIframeUrl] = useState(
    analyticsUrl // for the auto height to work, it appears a default url is needed
  )
  const queryClient = useQueryClient()
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  const embedUrlParam = {
    resource: { dashboard: insight.dashboardId },
    params: {
      seller_id: seller.selling_partner_id,
      marketplace_id: seller.primary_marketplace_id,
    },
  }

  // Get Metabase iframeUrl
  const { data: embedUrl, isFetching: embedUrlIsLoading } = useQuery(
    ['POST/analytics/embed', embedUrlParam],
    () => apiAnalytics.generateEmbedUrl(embedUrlParam),
    {
      staleTime: 10 * 60 * 1000, // 10 minutes since iframe_url is only valid for 11 minutes
      onSuccess: (embedUrl) => {
        if (embedUrl && embedUrl.status < 300) {
          setIframeUrl(embedUrl.data.iframe_url)
        } else {
          // could handle this better but for now just assume token expired
          history.go(0) // refresh page
        }
      },
    }
  )

  useEffect(() => {
    const iframeUrlCache = queryClient.getQueryData([
      'POST/analytics/embed',
      embedUrlParam,
    ])
    if (iframeUrlCache && iframeUrlCache.data?.iframe_url) {
      setIframeUrl(iframeUrlCache.data.iframe_url)
    }
  }, [queryClient, embedUrlParam])

  return (
    <>
      <Row className="mb-3">
        <Col>
          <CardTitle tag="h4">{insight.name}</CardTitle>
          <p className="card-description mb-2">{insight.description}</p>
          {insight.description2 && (
            <p
              className="card-description mb-2 font-italic"
              dangerouslySetInnerHTML={{ __html: insight.description2 }}
            ></p>
          )}
        </Col>
      </Row>
      <Row className="align-items-center">
        {loading && <SkeletonInsights/>}
        
        {iframeUrl !== analyticsUrl && 
          <IframeResizer
            src={iframeUrl}
            style={{ width: '1px', minWidth: '100%', minHeight: "62.5em"}}
            hidden={loading}
            onLoad={() => setLoading(false)}
          />
        }

      </Row>
    </>
  )
}

export default InsightsDashboard
