import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import Select from 'react-select'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  Spinner,
  Row,
  Col,
  InputGroup,
} from 'reactstrap'
import { inputFieldChange, cartesian } from 'services/Utils'
import { useQueryClient } from 'react-query'
import useAlert from 'services/hooks/useAlert'
import apiProducts from 'services/api/Product'

const ProductTarget = ({
  productTarget,
  productPublicId,
  setShowComponent,
}) => {
  const { addAlert } = useAlert()
  const [loading, setLoading] = useState(false)

  const queryClient = useQueryClient()

  const types = [
    {
      label: 'Keyword',
      value: 'keyword',
    },
    {
      label: 'Product',
      value: 'product',
    },
    {
      label: 'Category',
      value: 'category',
    },
  ]

  const matchTypes = [
    {
      label: 'Exact',
      value: 'exact',
    },
    {
      label: 'Phrase',
      value: 'phrase',
    },
    {
      label: 'Broad',
      value: 'broad',
    },
  ]

  const [type, setType] = useState(productTarget?.type)
  const [matchType, setMatchType] = useState(productTarget?.match_type)
  const [target, setTarget] = useState(productTarget?.target)

  const [typeValidation, setTypeValidation] = useState()
  const [matchTypeValidation, setMatchTypeValidation] = useState()
  const [targetValidation, setTargetValidation] = useState()

  const [typeFocus, setTypeFocus] = useState(false)
  const [matchTypeFocus, setMatchTypeFocus] = useState(false)
  const [targetFocus, setTargetFocus] = useState(false)

  useEffect(() => {
    setType(productTarget?.type)
    setMatchType(
      productTarget?.match_type ? productTarget?.match_type : matchTypes
    )
    setTarget(productTarget?.target)
    setTypeValidation(productTarget?.type ? 'has-success' : '')
    setMatchTypeValidation(productTarget?.match_type ? 'has-success' : '')
    setTargetValidation(productTarget?.target ? 'has-success' : '')
  }, [productTarget])

  const formattedTargets = (targetString) => {
    return [
      ...new Set(
        targetString
          .toLowerCase()
          .split('\n')
          .map((s) => s.trim())
      ),
    ]
  }

  const saveClick = async (e) => {
    if (
      typeValidation === 'has-success' &&
      (type.value === 'keyword'
        ? matchTypeValidation === 'has-success'
        : true) &&
      targetValidation === 'has-success'
    ) {
      setLoading(true)
      const targetData = cartesian(
        [productPublicId],
        [type.value],
        formattedTargets(target),
        type.value === 'keyword'
          ? [
              matchType
                ?.map((m) => m.value)
                .sort(
                  (a, b) =>
                    matchTypes.map((m) => m.value).indexOf(a) -
                    matchTypes.map((m) => m.value).indexOf(b)
                )
                .toString(),
            ]
          : type.value === 'product'
          ? ['asinSameAs']
          : type.value === 'category'
          ? ['asinCategorySameAs']
          : [null],
        ['enabled']
      ).map((t) => ({
        product_public_id: t[0],
        type: t[1],
        target: t[1] === 'product' ? t[2].toUpperCase() : t[2],
        match_type: t[3], //...(t[3] && {match_type: t[3]}),
        state: t[4],
      }))
      // create product target via api
      const response = await apiProducts
        .createTargets(productPublicId, targetData)
        .then((res) => {
          return res
        })
      if (response && response.status < 300) {
        queryClient.invalidateQueries('GET/sellers/') // overkill invalidating effectively with wildcard?
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
        setShowComponent(false)
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response.data.message,
            null
          )
        }
      }
      setLoading(false)
    } else {
      if (typeValidation === '') {
        setTypeValidation('has-danger')
      }
      if (matchTypeValidation === '' && typeValidation === 'keyword') {
        setMatchTypeValidation('has-danger')
      }
      if (targetValidation === '') {
        setTargetValidation('has-danger')
      }
      e.preventDefault()
    }
  }

  return (
    <>
      <Form className="form-horizontal" id="TypeValidation">
        <Card className="card-on-card">
          <CardHeader>
            <CardTitle tag="h4">Targeting</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Label sm="2">Type</Label>
              <Col sm="10">
                <FormGroup className={typeValidation}>
                  <InputGroup
                    className={classNames(typeValidation, {
                      'input-group-focus': typeFocus,
                      'input-group-react-select-disabled': false,
                    })}
                  >
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      value={type}
                      placeholder=""
                      onChange={(e) => {
                        inputFieldChange(
                          e,
                          setType,
                          setTypeValidation,
                          'select_length',
                          1
                        )
                      }}
                      onFocus={(e) => {
                        setTypeFocus(true)
                      }}
                      onBlur={(e) => setTypeFocus(false)}
                      options={types}
                    />
                    {typeValidation === 'has-danger' ? (
                      <label className="error">This field is required.</label>
                    ) : null}
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            {type?.value === 'keyword' ? (
              <Row>
                <Label sm="2">Match Type</Label>
                <Col sm="10">
                  <FormGroup className={matchTypeValidation}>
                    <InputGroup
                      className={classNames(matchTypeValidation, {
                        'input-group-focus': matchTypeFocus,
                      })}
                    >
                      <Select
                        className="react-select info primary-badges"
                        classNamePrefix="react-select"
                        placeholder={''}
                        closeMenuOnSelect={false}
                        isMulti={true}
                        value={matchType}
                        onChange={(e) => {
                          inputFieldChange(
                            e,
                            setMatchType,
                            setMatchTypeValidation,
                            'select_multi_length',
                            1
                          )
                        }}
                        options={matchTypes}
                        onFocus={(e) => setMatchTypeFocus(true)}
                        onBlur={(e) => setMatchTypeFocus(false)}
                      />
                      {matchTypeValidation === 'has-danger' ? (
                        <label className="error">This field is required.</label>
                      ) : null}
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}
            <Row>
              <Label sm="2">Targets</Label>
              <Col sm="10">
                <FormGroup className={targetValidation}>
                  <Input
                    className="product-targets-textarea"
                    value={target}
                    placeholder={
                      'Enter your targets and seperate each item with a new line' +
                      (type?.value === 'keyword'
                        ? ' e.g yoga mat'
                        : type?.value === 'product'
                        ? ' e.g B01IZDFWY2'
                        : type?.value === 'category'
                        ? ' e.g 3422301'
                        : '')
                    }
                    type="textarea"
                    onChange={(e) =>
                      inputFieldChange(
                        e,
                        setTarget,
                        setTargetValidation,
                        'length',
                        1
                      )
                    }
                    onFocus={(e) => setTargetFocus(true)}
                    onBlur={(e) => setTargetFocus(false)}
                  />
                  {targetValidation === 'has-danger' ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Label sm="2"></Label>
              <Col sm="10">
                <Button
                  color="primary"
                  className={classNames('btn-wd', { disabled: loading })}
                  onClick={(e) => saveClick(e)}
                >
                  {loading === true ? (
                    <Spinner size="sm" role="status"></Spinner>
                  ) : (
                    'Save Targets'
                  )}
                </Button>
                <Button
                  color="primary"
                  className={classNames('btn-simple')}
                  onClick={(e) => setShowComponent(false)}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Form>
    </>
  )
}

export default ProductTarget
