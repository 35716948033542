/*eslint-disable*/
import React from 'react'
import { Link } from 'react-router-dom'
import { Container, NavLink } from 'reactstrap'
// used for making the prop types of this component
import PropTypes from 'prop-types'

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={'footer' + (this.props.default ? ' footer-default' : '')}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <ul className="nav">
            <li className="nav-item">
              <NavLink
                href={process.env.REACT_APP_FRONTEND_APP_BASE_URL}
                className="card-category"
              >
                home
              </NavLink>
            </li>{' '}
            <li className="nav-item">
              <NavLink
                href={process.env.REACT_APP_FRONTEND_APP_BASE_URL + '/blog'}
                className="card-category"
              >
                blog
              </NavLink>
            </li>{' '}
            <li className="nav-item">
              <NavLink
                href={process.env.REACT_APP_HELP_CENTER_URL}
                target="_blank"
                className="card-category"
              >
                help center
              </NavLink>
            </li>
          </ul>
          <div className="copyright">
            © {new Date().getFullYear()} {' Autron, Inc.'}
          </div>
        </Container>
      </footer>
    )
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
}

export default Footer
