import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Button,
  Spinner,
  UncontrolledTooltip,
  Row,
  Col,
} from 'reactstrap'
import Datetime from 'react-datetime'
import moment from 'moment-timezone' // import required for "react-datetime" to work
import { inputFieldChange } from 'services/Utils'
import { useQueryClient } from 'react-query'
import useAlert from 'services/hooks/useAlert'
import apiProducts from 'services/api/Product'

const ProductCost = ({
  productCost,
  productPublicId,
  currency,
  timezone,
  setShowComponent,
}) => {
  const { addAlert } = useAlert()
  const [loading, setLoading] = useState(false)

  const [publicId, setPublicId] = useState(productCost.public_id)
  const [effectiveFrom, setEffectiveFrom] = useState(productCost.effective_from)
  const [cog, setCog] = useState(productCost.cost_of_goods_per_unit)
  const [shippingCost, setShippingCost] = useState(
    productCost.shipping_cost_per_unit
  )
  const [miscCost, setMiscCost] = useState(productCost.misc_cost_per_unit)
  const [totalCost, setTotalCost] = useState(productCost.total_cost_per_unit)

  const [effectiveFromValidation, setEffectiveFromValidation] = useState()
  const [cogValidation, setCogValidation] = useState()
  const [shippingCostValidation, setShippingCostValidation] = useState()
  const [miscCostValidation, setMiscCostValidation] = useState()

  const queryClient = useQueryClient()

  useEffect(() => {
    //console.log(effectiveFrom)

    setPublicId(productCost.public_id)
    setEffectiveFrom(productCost.effective_from)
    setCog(productCost.cost_of_goods_per_unit)
    setShippingCost(productCost.shipping_cost_per_unit)
    setMiscCost(productCost.misc_cost_per_unit)

    setEffectiveFromValidation('has-success')
    setCogValidation(productCost.cost_of_goods_per_unit ? 'has-success' : '')
    setShippingCostValidation(
      productCost.shipping_cost_per_unit ? 'has-success' : ''
    )
    setMiscCostValidation(productCost.misc_cost_per_unit ? 'has-success' : '')
  }, [productCost])

  const costPlaceholder = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(0)

  const saveClick = async (e) => {
    if (
      effectiveFromValidation === 'has-success' &&
      cogValidation === 'has-success' &&
      shippingCostValidation === 'has-success' &&
      (miscCostValidation === 'has-success' || miscCostValidation === '')
    ) {
      setLoading(true)
      const costs = {
        cost_of_goods_per_unit: formatCurrency(cog),
        shipping_cost_per_unit: formatCurrency(shippingCost),
        misc_cost_per_unit: formatCurrency(miscCost),
        total_cost_per_unit: totalCost,
        effective_from: effectiveFrom,
      }
      // create or update product cost via api
      // const response = ''
      const response = await (publicId
        ? apiProducts.updateSingleCost(productPublicId, publicId, costs)
        : apiProducts.createSingleCost(productPublicId, costs)
      ).then((res) => {
        return res
      })
      if (response && response.status < 300) {
        queryClient.invalidateQueries('GET/sellers/') // overkill invalidating effectively with wildcard?
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
        setShowComponent(false)
      } else {
        if (response) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            response.data.message,
            null
          )
        }
      }
      setLoading(false)
    } else {
      if (effectiveFromValidation === '') {
        setEffectiveFromValidation('has-danger')
      }
      if (cogValidation === '') {
        setCogValidation('has-danger')
      }
      if (shippingCostValidation === '') {
        setShippingCostValidation('has-danger')
      }
      e.preventDefault()
    }
  }

  const formatCurrency = (num) => {
    return Math.round(((Number(num) || 0) + Number.EPSILON) * 100) / 100
  }

  useEffect(() => {
    let totalCost = [
      formatCurrency(cog),
      formatCurrency(shippingCost),
      formatCurrency(miscCost),
    ].reduce((a, b) => a + b, 0)
    setTotalCost(totalCost ? totalCost : 0)
  }, [currency, cog, shippingCost, miscCost])

  return (
    <>
      <Form className="form-horizontal" id="TypeValidation">
        <Card className="card-on-card">
          <CardHeader>
            <CardTitle tag="h4">Unit Costs</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Label sm="2">
                Effective From
                <Button
                  color="link"
                  id="effective-from-label-tooltip"
                  title=""
                  type="button"
                  className="p-0 m-0 mt-n1 pl-2"
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="effective-from-label-tooltip"
                >
                  Products sold on or after this date will use this cost in Ad
                  optimizations (Date is in marketplace default timezone:{' '}
                  {timezone}).
                </UncontrolledTooltip>
              </Label>
              <Col sm="10">
                <FormGroup className={effectiveFromValidation}>
                  <Datetime
                    inputProps={{
                      className: 'form-control',
                    }}
                    value={moment(effectiveFrom)}
                    //initialValue={moment(effectiveFrom)}
                    //initialViewDate={effectiveFrom}
                    dateFormat="ll"
                    timeFormat={false}
                    displayTimeZone={timezone}
                    onChange={(e) => {
                      inputFieldChange(
                        e,
                        setEffectiveFrom,
                        setEffectiveFromValidation,
                        'Datetime'
                      )
                    }}
                  />
                  {effectiveFromValidation === 'has-danger' ? (
                    <label className="error">This field is required.</label>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">Cost of Goods</Label>
              <Col sm="10">
                <FormGroup className={cogValidation}>
                  <Input
                    name="cog"
                    type="number"
                    min="0.01"
                    step="0.01"
                    value={cog}
                    placeholder={costPlaceholder}
                    onChange={(e) =>
                      inputFieldChange(
                        e,
                        setCog,
                        setCogValidation,
                        'positiveNumber'
                      )
                    }
                  />
                  {cogValidation === 'has-danger' ? (
                    <label className="error">
                      This field is required and must be a positive number.
                    </label>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">Inbound Shipping Cost</Label>
              <Col sm="10">
                <FormGroup className={shippingCostValidation}>
                  <Input
                    name="shipping_cost"
                    type="number"
                    min="0.01"
                    step="0.01"
                    value={shippingCost}
                    placeholder={costPlaceholder}
                    onChange={(e) =>
                      inputFieldChange(
                        e,
                        setShippingCost,
                        setShippingCostValidation,
                        'positiveNumber'
                      )
                    }
                  />
                  {shippingCostValidation === 'has-danger' ? (
                    <label className="error">
                      This field is required and must be a positive number.
                    </label>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">
                Miscellaneous Cost
                <Button
                  color="link"
                  id="misc-product-cost-label-tooltip"
                  title=""
                  type="button"
                  className="p-0 m-0 mt-n1 pl-2"
                >
                  <i className="fas fa-question-circle"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="misc-product-cost-label-tooltip"
                >
                  Optional misc. cost for product prep, taxes or any other cost
                  you would like to expense every time this product is sold.
                </UncontrolledTooltip>
              </Label>
              <Col sm="10">
                <FormGroup className={miscCostValidation}>
                  <Input
                    name="misc_cost"
                    type="number"
                    min="0.01"
                    step="0.01"
                    value={miscCost}
                    placeholder={costPlaceholder}
                    onChange={(e) =>
                      inputFieldChange(
                        e,
                        setMiscCost,
                        setMiscCostValidation,
                        'positiveNumber',
                        null,
                        false
                      )
                    }
                  />
                  {miscCostValidation === 'has-danger' ? (
                    <label className="error">
                      This field must be a positive number.
                    </label>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">Total Cost</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    disabled
                    className="form-input-as-display"
                    name="total_cost"
                    type="text"
                    value={Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: currency,
                    }).format(totalCost)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Row>
              <Label sm="2"></Label>
              <Col sm="10">
                <Button
                  color="primary"
                  className={classNames('btn-wd', { disabled: loading })}
                  onClick={(e) => saveClick(e)}
                >
                  {loading === true ? (
                    <Spinner size="sm" role="status"></Spinner>
                  ) : (
                    'Save Costs'
                  )}
                </Button>
                <Button
                  color="primary"
                  className={classNames('btn-simple')}
                  onClick={(e) => setShowComponent(false)}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Form>
    </>
  )
}

export default ProductCost
