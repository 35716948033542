import React, { useState, Fragment, useEffect, useContext } from 'react'
import classNames from 'classnames'
// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Spinner,
  Container,
  Button,
} from 'reactstrap'
import apiPartners from 'services/api/Partner'
import useAlert from 'services/hooks/useAlert'
import myosLogoImg from 'assets/img/myos-logo-reverse.svg'
import myosLogoLightMode from 'assets/img/myos-logo-light-mode.svg'
import accelClubLogoImg from 'assets/img/accel-club-logo.jpeg'
import accelClubDistributionOfferImg from 'assets/img/accel-club-distribution-offer.jpg'
import ThemeContext from 'services/providers/ThemeContext'

const Partners = ({ user }) => {
  const { addAlert } = useAlert()

  const capitalIsLoading = false
  const [fundMeLoading, setFundMeLoading] = useState(false)
  const [accelClubLoading, setAccelClubLoading] = useState(false)
  const { lightMode } = useContext(ThemeContext)

  const handlePartnerBtnClick = async (partnerName, setLoading) => {
    setLoading(true)
    const data = { partner_name: partnerName }

    try {
      const response = await apiPartners.generatePartnerLead(data)

      if (response && response.status < 300) {
        addAlert(
          'success',
          'tim-icons icon-check-2',
          response.data.message,
          null
        )
        // Uncomment the following line if you want to redirect based on the response
        // if (response.data.link) window.location.href = response.data.link;
      } else {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response?.data?.message || 'An error occurred',
          null
        )
      }
    } catch (error) {
      addAlert(
        'danger',
        'tim-icons icon-alert-circle-exc',
        'An error occurred',
        null
      )
    } finally {
      setLoading(false)
    }
  }

  const fundMeBtnClick = (e) => handlePartnerBtnClick('myos', setFundMeLoading)
  const accelClubBtnClick = (e) =>
    handlePartnerBtnClick('accel_club', setAccelClubLoading)

  return (
    <>
      <div className="content">
        <Row>
          <Col
            className={classNames({
              'd-flex justify-content-center align-items-end mt-xl':
                capitalIsLoading,
            })}
          >
            {capitalIsLoading ? (
              <Spinner /> // used for initial load
            ) : (
              <Fragment>
                <Card className="card-plain info-area">
                  <CardHeader>
                    <CardTitle tag="h2">Partners</CardTitle>
                    <hr className="line-primary mb-2" />
                  </CardHeader>
                  <CardBody>
                    <p className="card-description description">
                      Collaborate with key partners to drive growth and success
                      for your business.
                    </p>
                  </CardBody>
                </Card>
                <Card className="">
                  <CardBody>
                    <Container>
                      <Row className="mt-5 pt-5">
                        <Col lg="12">
                          <Row>
                            <Col lg="3" className="mb-5 pr-5 pt-2">
                              <img
                                alt="accel club logo"
                                className="capital-myos-logo"
                                src={accelClubLogoImg}
                              />
                            </Col>
                            <Col lg="9" className="mb-5">
                              <p className="card-description description mb-5 partners-card-description">
                                We’re partnering with Accel Club to help you tap
                                into the $100 billion EU & UK market, expanding
                                your brand in a region growing 43% faster than
                                Amazon in North America.
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6" className="mb-1">
                              <div className="info-area">
                                <div className="icon icon-primary">
                                  <i className="tim-icons icon-globe-2" />
                                </div>
                                <h3 className="info-title mb-4">
                                  European Expansion
                                </h3>
                                <hr className="line-primary mb-4" />
                                <p className="card-description description">
                                  Expand risk-free with no costs or long-term
                                  contracts. We manage all operations, allowing
                                  you to maintain focus on your primary market.
                                  See sales in just 4 months and earn a share of
                                  the profit monthly, with the flexibility to
                                  take back control whenever you’re ready.
                                </p>
                              </div>
                            </Col>
                            <Col lg="6" className="mb-1">
                              <img
                                alt="accel club distribution offer"
                                className=""
                                src={accelClubDistributionOfferImg}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </CardBody>
                  <CardFooter className="mb-5">
                    <Container>
                      <Row className="">
                        <Col lg="12">
                          <Button
                            className={classNames(
                              'btn-round btn-just-icon btn-wd btn-primary',
                              { disabled: accelClubLoading }
                            )}
                            onClick={(e) => accelClubBtnClick(e)}
                          >
                            {accelClubLoading === true ? (
                              <Spinner size="sm" role="status"></Spinner>
                            ) : (
                              'Express interest'
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </CardFooter>
                </Card>
                <Card className="">
                  <CardBody>
                    <Container>
                      <Row className="mt-5 pt-5">
                        <Col lg="12">
                          <Row>
                            <Col lg="3" className="mb-5 pr-5 pt-2">
                              <img
                                alt="myos logo"
                                className="capital-myos-logo"
                                src={
                                  lightMode ? myosLogoLightMode : myosLogoImg
                                }
                              />
                            </Col>
                            <Col lg="9" className="mb-5">
                              <p className="card-description description mb-5 partners-card-description">
                                Autron's teamed up with Myos to offer growth
                                capital at a preferred rate, designed
                                specifically for e-commerce businesses.
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="icon icon-primary">
                                  <i className="tim-icons icon-cart" />
                                </div>
                                <h3 className="info-title mb-4">
                                  Purchase Finance
                                </h3>
                                <hr className="line-primary mb-4" />
                                <p className="card-description description">
                                  We will take care of the deposit and balance
                                  payment of your order. If you have already
                                  paid for the goods, you will get the money
                                  back from us.
                                </p>
                              </div>
                            </Col>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="icon icon-warning">
                                  <i className="tim-icons icon-app" />
                                </div>
                                <h3 className="info-title mb-4">
                                  Stock Finance
                                </h3>
                                <hr className="line-warning mb-4" />
                                <p className="card-description description">
                                  Leverage your current inventory and fuel your
                                  growth. You assign a portion of your stock as
                                  collateral and get capital in return.
                                </p>
                              </div>
                            </Col>
                            <Col lg="4" className="mb-5">
                              <div className="info-area">
                                <div className="icon icon-success">
                                  <i className="tim-icons icon-coins" />
                                </div>
                                <h3 className="info-title mb-4">
                                  Cross Finance
                                </h3>
                                <hr className="line-success mb-4" />
                                <p className="card-description description">
                                  We offer you flexible use of funds based on
                                  your current products - an alternative to
                                  factoring.
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Container>
                  </CardBody>
                  <CardFooter className="mb-5">
                    <Container>
                      <Row className="">
                        <Col lg="12">
                          <Button
                            className={classNames(
                              'btn-round btn-just-icon btn-wd btn-primary',
                              { disabled: fundMeLoading }
                            )}
                            onClick={(e) => fundMeBtnClick(e)}
                          >
                            {fundMeLoading === true ? (
                              <Spinner size="sm" role="status"></Spinner>
                            ) : (
                              'Get a free quote'
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </CardFooter>
                </Card>
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Partners
