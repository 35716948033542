import React, { useState, Fragment, useEffect } from 'react'
import classNames from 'classnames'
// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from 'reactstrap'
import { useQuery, useQueryClient } from 'react-query'
import useAlert from 'services/hooks/useAlert'
import { useHistory, useParams } from 'react-router-dom'
import apiSellers from 'services/api/Seller'
import BrandCreatives from './BrandCreatives'
import VideoCreatives from './VideoCreatives'
import apiBrands from 'services/api/Brand'
import { sellerProfileId } from 'services/Utils'
import axios from 'axios'

const Creatives = ({ user }) => {
  const params = useParams()
  const { addAlert } = useAlert()
  const queryClient = useQueryClient()
  const history = useHistory()

  const [horizontalTabs, setHorizontalTabs] = useState(
    params.type ? params.type : 'brand'
  )
  const [primarySeller, setPrimarySeller] = useState([])
  const primarySellerPublicId = primarySeller?.public_id
  const [brands, setBrands] = useState([])
  const [products, setProducts] = useState([])
  const [queryParam, setQueryParam] = useState({
    page: 1,
    per_page: 10,
  })
  const [failedSpec, setFailedSpec] = useState([])
  const [uploadingLogo, setUploadingLogo] = useState(false)
  const [updatingLogo, setUpdatingLogo] = useState(false)
  const [uploadingMedia, setUploadingMedia] = useState(false)
  const [updatingMedia, setUpdatingMedia] = useState(false)

  useEffect(() => {
    setPrimarySeller(user.primary_seller)
  }, [user])

  const {
    data: brandsObject,
    isFetching: brandsIsLoading,
    brandsRefetch,
  } = useQuery(
    ['GET/sellers/', primarySellerPublicId, '/brands', queryParam],
    () => apiSellers.brands(primarySellerPublicId, queryParam),
    {
      staleTime: 1440 * 1440 * 1000, // 24 hrs
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (brandsObject) => {
        setBrands(brandsObject?.data.brands)
      },
    }
  )

  useEffect(() => {
    const brandsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/brands',
      queryParam,
    ])
    if (brandsCache && brandsCache.data?.brands) {
      setBrands(brandsCache?.data.brands)
    }
  }, [queryClient, primarySellerPublicId, queryParam])

  useEffect(() => {
    if (uploadingMedia) {
      const timer = () =>
        setTimeout(() => {
          addAlert(
            'warning',
            'tim-icons icon-bell-55',
            'Hang tight... this can take a while for large video files.',
            null
          )
        }, 10000)
      const timerId = timer()
      return () => {
        clearTimeout(timerId)
      }
    }
  }, [uploadingMedia])

  const handleBrandUpdate = async (data) => {
    var response
    var commonData = {
      marketplace_marketplace_id: primarySeller.primary_marketplace_id,
      seller_selling_partner_id: primarySeller.selling_partner_id,
      profile_profile_id: sellerProfileId(primarySeller),
      brand_entity_id: data.brand_entity_id,
    }
    var brandData = {}
    switch (data.action) {
      case 'update_brand_logo':
        setUpdatingLogo(true)
        brandData = JSON.stringify([
          { ...commonData, ...{ logo_url: data.url } },
        ]) // note sending array
        response = await apiBrands.post(brandData).then((res) => {
          return res
        })
        break
      case 'upload_brand_logo':
        let fileSize = data.file?.size / 1024 / 1024 // in MiB
        if (fileSize > 1) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            'File size exceeds 1MB',
            null
          )
          break
        }
        setUploadingLogo(true)
        brandData = new FormData()
        brandData.append('file', data.file)
        brandData.append(
          'data',
          JSON.stringify({ ...commonData, ...{ media_type: 'brandLogo' } })
        )
        response = await apiBrands.logoUpload(brandData).then((res) => {
          return res
        })
        break
      case 'upload_brand_media':
        setUploadingMedia(true)

        try {
          response = await apiBrands.mediaLocation({
            ...commonData,
            filename: data.file.name
          });

          if (!response?.data?.upload_location) {
            throw new Error('Failed to fetch media location.');
          }

          const uploadLocation = response.data.upload_location;
          const filename = data.file.name;

          const axiosInstance = axios.create()
          axiosInstance.defaults.headers.common = {} // s3 bucket PUT requires clear/default headers
          response = await axiosInstance
            .put(uploadLocation, data.file, {
              withCredentials: false, // important
              headers: {
                'Content-Type': 'video/quicktime'
              }
            })
            .then((res) => {
              return res;
            })
            .catch((err) => {
              return null;
            });

          if (!response || response?.status > 300) {
            throw new Error('Failed to upload video.')
          }

          response = await apiBrands.mediaUploadComplete({
            ...commonData,
            media_type: 'video',
            product_asins: data.product_asins,
            upload_location: uploadLocation,
            version_id: "",
            filename: filename,
          });

          if (!response) {
            addAlert(
              'danger',
              'tim-icons icon-alert-circle-exc',
              'Failed to save video.',
              null
            );
          }

        } catch (error) {
          addAlert(
            'danger',
            'tim-icons icon-alert-circle-exc',
            error.message,
            null
          );
        }
        break
      case 'update_brand_media':
        setUpdatingMedia(true)
        brandData = JSON.stringify({
          ...commonData,
          ...{
            media_id: data.media_id,
            creative_asset_id: data.creative_asset_id,
            state: data.state,
            product_asins: data.product_asins,
            media_type: 'video',
          },
        })
        response = await apiBrands.media(brandData).then((res) => {
          return res
        })
        break
      default:
      // do nothing
    }
    if (response && response.status < 300) {
      queryClient.invalidateQueries([
        'GET/sellers/',
        primarySellerPublicId,
        '/brands',
        queryParam,
      ])
      addAlert(
        'success',
        'tim-icons icon-check-2',
        response.data?.message,
        null
      )
      if (['upload_brand_media', 'update_brand_media'].includes(data.action)) {
        history.push('/admin/creatives/video')
      }
    } else {
      if (response) {

        const failedSpecList = response?.data?.failedSpec?.specifications?.map((item) => {
          const errType = item?.stringId.replace('al-spec-', '').split('-').join(' ');
          return {
            type: errType,
            value: item?.actualValue || '',
            expectedValues: item?.arguments
          }
        })

        setFailedSpec(failedSpecList);
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response.data?.message,
          null
        )
      }
    }
    setUploadingLogo(false)
    setUpdatingLogo(false)
    setUploadingMedia(false)
    setUpdatingMedia(false)
  }

  const {
    data: productsObject,
    isFetching: productsIsLoading,
    productsRefetch,
  } = useQuery(
    [
      'GET/sellers/',
      primarySellerPublicId,
      '/products',
      { page: 1, per_page: 1000 },
    ],
    () =>
      apiSellers.products(primarySellerPublicId, { page: 1, per_page: 1000 }),
    {
      staleTime: 1440 * 1440 * 1000, // 24 hrs
      enabled: !!primarySellerPublicId, // The query will not execute until the primarySellerId exists
      onSuccess: (productsObject) => {
        setProducts(productOptions(productsObject?.data.products))
      },
    }
  )

  useEffect(() => {
    const productsCache = queryClient.getQueryData([
      'GET/sellers/',
      primarySellerPublicId,
      '/products',
      { page: 1, per_page: 1000 },
    ])
    if (productsCache && productsCache.data?.products) {
      setProducts(productOptions(productsCache?.data.products))
    }
  }, [queryClient, primarySellerPublicId])

  const productOptions = (prods) => {
    return prods?.map((p) => {
      return { value: p.asin, label: p.asin }
    })
  }

  const failedSpecUpdate = () => {
    setFailedSpec([])
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col
            className={classNames({
              'd-flex justify-content-center align-items-end mt-xl':
                brandsIsLoading,
            })}
          >
            {brandsIsLoading ? (
              <Spinner /> // used for initial load
            ) : (
              <Fragment>
                <Card className="card-plain info-area">
                  <CardHeader>
                    <CardTitle tag="h2">Creatives</CardTitle>
                    <hr className="line-primary mb-2" />
                  </CardHeader>
                  <CardBody>
                    <p className="card-description description">
                      Add creatives for Autron to use in Sponsored Brands and
                      Sponsored Display campaigns.
                    </p>
                  </CardBody>
                </Card>
                <Card className="">
                  <CardBody>
                    <Nav className="nav-tabs-info" tabs>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="#"
                          className={horizontalTabs === 'brand' ? 'active' : ''}
                          onClick={(e) => {
                            setHorizontalTabs('brand')
                            history.push('/admin/creatives/brand')
                          }}
                        >
                          Brand
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          data-toggle="tab"
                          href="#"
                          className={horizontalTabs === 'video' ? 'active' : ''}
                          onClick={(e) => {
                            setHorizontalTabs('video')
                            history.push('/admin/creatives/video')
                          }}
                        >
                          Video
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent
                      className="tab-space"
                      activeTab={horizontalTabs}
                    >
                      <TabPane tabId="brand">
                        <BrandCreatives
                          brands={brands}
                          brandUpdate={handleBrandUpdate}
                          uploadingLogo={uploadingLogo}
                          updatingLogo={updatingLogo}
                        />
                      </TabPane>
                      <TabPane tabId="video">
                        <VideoCreatives
                          brands={brands}
                          products={products}
                          failedSpec={failedSpec}
                          mediaUpdate={handleBrandUpdate}
                          uploadingMedia={uploadingMedia}
                          updatingMedia={updatingMedia}
                          failedSpecUpdate={failedSpecUpdate}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                  <CardFooter></CardFooter>
                </Card>
              </Fragment>
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Creatives
