import React from 'react'
import ReactDOM from 'react-dom'
import { App } from 'App'

import 'assets/css/nucleo-icons.css'
import 'assets/css/forma-font.css'
import 'assets/css/algebra-display-font.css'
import 'react-notification-alert/dist/animate.css'
import 'assets/scss/black-dashboard-pro-react.scss?v=1.1.0'
import 'assets/scss/app-autron-custom.scss'
import 'assets/demo/demo.css'
import initializeSentry from 'services/Sentry'
import { ThemeProvider } from 'services/providers/ThemeContext'

initializeSentry()

ReactDOM.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>,
  document.getElementById('root')
)
