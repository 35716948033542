import React from 'react'
import { Row, Col, Container, Card } from 'reactstrap'

import DashboardTrendLoader from 'components/ContentLoaders/DashboardTrendLoader'
import GeneralLogoLoader from 'components/ContentLoaders/GeneralLogoLoader'

function SkeletonInsights() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="2" md="2">
            <Card className="card-metrics card-on-card">
              <DashboardTrendLoader />
            </Card>
          </Col>
          <Col lg="2" md="2">
            <Card className="card-metrics card-on-card">
              <DashboardTrendLoader />
            </Card>
          </Col>
          <Col lg="2" md="2">
            <Card className="card-metrics card-on-card">
              <DashboardTrendLoader />
            </Card>
          </Col>
          <Col lg="2" md="2">
            <Card className="card-metrics card-on-card">
              <DashboardTrendLoader />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="6">
            <Card className="card-metrics">
              <GeneralLogoLoader />
            </Card>
          </Col>
          <Col lg="6" md="6">
            <Card className="card-metrics">
              <GeneralLogoLoader />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="6">
            <Card className="card-metrics">
              <GeneralLogoLoader />
            </Card>
          </Col>
          <Col lg="6" md="6">
            <Card className="card-metrics">
              <GeneralLogoLoader />
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SkeletonInsights
