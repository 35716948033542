import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Fragment,
} from 'react'
import classNames from 'classnames'
// reactstrap components
import {
  Button,
  UncontrolledTooltip,
  Row,
  Col,
  UncontrolledAlert,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
  CardTitle,
  Spinner,
  Label,
  Input,
  FormGroup,
} from 'reactstrap'
import ReactTable from 'components/ReactTable/ReactTable.js'
import Goal from './Goal'
import apiGoals from 'services/api/Goal'
import { useHistory, useParams, Link } from 'react-router-dom'
import useAlert from 'services/hooks/useAlert'
import { useQueryClient } from 'react-query'

const GoalsByProduct = ({
  objectives,
  strategies,
  type,
  goals,
  globalGoal,
  newProducts,
  seller,
  profileId,
  isFreeUser,
  loading,
  total,
  pageCount,
  queryParam,
  setQueryParam,
  gfilterQuery,
}) => {
  const history = useHistory()
  const params = useParams()
  const { addAlert } = useAlert()
  const queryClient = useQueryClient()

  const [currency, setCurrency] = useState('')
  const [creatingGoals, setCreatingGoals] = useState(false)
  const [goal, setGoal] = useState()
  const [showGoalForm, setShowGoalForm] = useState(!!params.public_id)
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    if (seller?.primary_marketplace_currency_code) {
      setCurrency(seller.primary_marketplace_currency_code)
    }
  }, [seller])

  useEffect(() => {
    setShowGoalForm(!!params.public_id)
  }, [params])

  const mapProducts = (data) => {
    return data.map(
      ({
        product_asin: asin,
        public_id: goal_public_id,
        product_price: price,
        product_profit: profit,
        product_public_id: public_id, // product_public_id
        product_sku: sku,
        product_your_price: your_price,
      }) => {
        return {
          asin,
          goal_public_id,
          price,
          profit,
          public_id,
          sku,
          your_price,
        }
      }
    )
  }

  const handleProcessSelectedRows = useCallback(() => {
    const selectedProducts = mapProducts(selectedRows)

    // find the 1st item on the selectedProducts on the goals list to extract defaults for bulk operation
    const firstProduct = goals.find(
      (g) => g.product_sku === selectedProducts[0].sku
    )

    if (firstProduct.type === 'product') {
      setGoal({
        ...firstProduct,
        products: selectedProducts,
        isBulk: true,
      })
    } else {
      setGoal({
        objective: globalGoal.objective,
        strategy: globalGoal.strategy,
        strategy_value: globalGoal.strategy_value,
        use_global_public_id: globalGoal.public_id,
        state: 'enabled',
        products: selectedProducts,
        isBulk: true,
      })
    }

    history.push(`/admin/goals/${globalGoal.public_id}`)
  }, [selectedRows, globalGoal, history])

  const handleBulkAction = useCallback(
    (e) => {
      if (e === 'update') {
        handleProcessSelectedRows()
      }
    },
    [handleProcessSelectedRows]
  )

  const bulkOptions = [{ value: 'update', label: 'Update goals' }]

  const createGoalsClick = async (e, product_asins) => {
    const goalData = {
      seller_selling_partner_id: seller.selling_partner_id,
      marketplace_marketplace_id: seller.primary_marketplace_id,
      profile_profile_id: profileId,
      product_asins: product_asins,
    }
    setCreatingGoals(true)
    const response = await apiGoals.createDefaultGoals(goalData)
    if (response && response.status < 300) {
      queryClient.invalidateQueries('GET/sellers/')
      addAlert('success', 'tim-icons icon-check-2', response.data.message, null)
    } else {
      if (response) {
        addAlert(
          'danger',
          'tim-icons icon-alert-circle-exc',
          response.data.message,
          null
        )
      }
    }
    setCreatingGoals(false)
  }

  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <FormGroup check>
            <Label check>
              <Input type="checkbox" {...getToggleAllRowsSelectedProps()} />
              <span className="form-check-sign">
                <span className="check"></span>
              </span>
            </Label>
          </FormGroup>
        ),
        Cell: ({ row }) => (
          <FormGroup check onClick={(e) => e.stopPropagation()}>
            <Label check>
              <Input type="checkbox" {...row.getToggleRowSelectedProps()} />
              <span className="form-check-sign">
                <span className="check"></span>
              </span>
            </Label>
          </FormGroup>
        ),
      },
      {
        Header: 'name',
        accessor: 'product_name',
        filter: 'ilike',
        gfilter: 4,
        sortable: false,
        Cell: (props) => props.value?.substring(0, 100) + '...',
      },
      {
        Header: 'sku',
        accessor: 'product_sku',
        filter: 'eq',
        gfilter: 2,
        sortable: false,
      },
      {
        Header: 'asin',
        accessor: 'product_asin',
        filter: 'eq',
        gfilter: 1,
        sortable: false,
      },
      {
        Header: 'parent asin',
        accessor: 'product_parent_asin',
        filter: 'eq',
        gfilter: 3,
        sortable: false,
      },
      {
        Header: 'product_price',
        accessor: 'product_price',
        sortable: false,
      },
      {
        Header: 'product_profit',
        accessor: 'product_profit',
        sortable: false,
      },
      {
        Header: 'product_your_price',
        accessor: 'product_your_price',
        sortable: false,
      },
      {
        Header: 'product_public_id',
        accessor: 'product_public_id',
        sortable: false,
      },
      {
        Header: 'type',
        accessor: 'use_global_public_id',
        Cell: (props) => (
          <span
            className={classNames(
              'status_cell',
              props.value ? 'status-primary' : 'status-primary-grad'
            )}
          >
            {props.value ? 'global' : 'custom'}
          </span>
        ),
      },
      {
        Header: 'objective',
        accessor: 'objective',
        Cell: (props) => objectives.find((o) => o.value === props.value)?.label,
      },
      {
        Header: 'strategy',
        accessor: 'strategy',
        Cell: (props) =>
          strategies.find((s) => s.value === props.value)?.label || null,
      },
      {
        Header: 'target',
        accessor: 'strategy_value',
        Cell: (props) => {
          const strategy = strategies.find(
            (s) => s.value === props.row.original.strategy
          )
          return strategy ? strategy.value_format(props.value, currency) : null
        },
      },
      {
        Header: 'Turbo mode',
        accessor: 'turbo_mode',
        Cell: (props) => (props.value === 'enabled' ? 'On' : 'Off'),
      },
      {
        Header: 'status',
        accessor: 'state',
        Cell: (props) => (
          <span
            className={classNames(
              'status_cell',
              props.value === 'enabled' ? 'status-success' : 'status-danger'
            )}
          >
            {props.value === 'enabled' ? 'on' : 'off'}
          </span>
        ),
      },
      {
        Header: 'actions',
        Cell: (props) => (
          <div className="actions-right">
            <Button
              onClick={() => {
                setGoal(
                  goals.find(
                    (g) => g.product_sku === props.row.original.product_sku
                  )
                )
                history.push(`/admin/goals/${props.row.original.public_id}`)
              }}
              id={`product_goal_table_edit_btn_${props.row.index}`}
              color="warning"
              size="sm"
              className={classNames('btn-icon btn-link like', {
                'btn-neutral': props.row.index < 0,
              })}
            >
              <i className="tim-icons icon-pencil" />
            </Button>
            <UncontrolledTooltip
              delay={0}
              target={`product_goal_table_edit_btn_${props.row.index}`}
            >
              Edit
            </UncontrolledTooltip>
          </div>
        ),
      },
    ],
    [currency, history]
  )

  const hiddenColumns = [
    'product_parent_asin',
    'product_sku',
    'product_price',
    'product_profit',
    'product_your_price',
    'product_public_id',
  ]

  return (
    <>
      <Row className="mb-3">
        <Col sm="12">
          {goals?.length > 0 ||
          (goals?.length === 0 && gfilterQuery) ||
          newProducts?.length ? (
            showGoalForm ? (
              <Goal
                objectives={objectives}
                strategies={strategies}
                type={'product'}
                goal={goal}
                goalGlobalPublicId={globalGoal.public_id}
                products={
                  goal?.isBulk
                    ? goal?.products
                    : mapProducts(
                        goals.filter(
                          (g) => g?.product_sku === goal?.product_sku
                        )
                      )
                }
                seller={seller}
                profileId={profileId}
                isFreeUser={isFreeUser}
                dataIsLoading={false}
              />
            ) : isFreeUser ? (
              <Fragment>
                <CardTitle tag="h4">Product Goals</CardTitle>
                <p className="card-description mb-2">
                  Upgrade to leverage Autron's Ad Engine across all your
                  products.
                </p>
                <Button
                  className="btn-round btn-just-icon mt-4"
                  color="primary"
                  tag={Link}
                  to="/admin/account/billing"
                >
                  Upgrade
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                {newProducts?.length > 0 ? (
                  <UncontrolledAlert
                    className="alert-with-icon mb-5"
                    color="primary"
                    fade={true}
                  >
                    <span data-notify="icon" className="tim-icons icon-app" />
                    <span>
                      <b>New Products Detected - </b>let's create goals for
                      these too
                      <Button
                        className="btn-simple-reverse btn-wd ml-md-5"
                        color="primary"
                        onClick={(e) => createGoalsClick(e, newProducts)}
                      >
                        {creatingGoals === true ? (
                          <Spinner size="sm" role="status"></Spinner>
                        ) : (
                          'Create Goals'
                        )}
                      </Button>
                    </span>
                  </UncontrolledAlert>
                ) : null}
                <UncontrolledDropdown className="float-right">
                  <DropdownToggle
                    caret
                    className="btn-simple btn btn-primary"
                    color="primary"
                    data-toggle="dropdown"
                    type="button"
                    disabled={!selectedRows.length}
                  >
                    {/* <i className="tim-icons icon-settings-gear-63" /> */}
                    <span
                      style={{
                        marginRight: '-20px',
                        display: 'inline',
                      }}
                    >
                      Bulk Actions
                    </span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {bulkOptions.map((opt, i) => (
                      <NavLink tag="li" key={i}>
                        <DropdownItem
                          className="nav-item"
                          onClick={() => {
                            handleBulkAction(opt.value)
                          }}
                        >
                          {opt.label}
                        </DropdownItem>
                      </NavLink>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <ReactTable
                  columns={columns}
                  data={goals}
                  loading={loading}
                  total={total}
                  pageCount={pageCount}
                  queryParam={queryParam}
                  setQueryParam={setQueryParam}
                  onRowClick={(row, e) => {
                    setGoal(
                      goals.find(
                        (g) => g.product_sku === row.original['product_sku']
                      )
                    )
                    history.push('/admin/goals/' + row.original['public_id'])
                  }}
                  setSelectedRows={setSelectedRows}
                  hiddenColumns={hiddenColumns}
                />
              </Fragment>
            )
          ) : goals?.length === 0 && !gfilterQuery ? (
            <UncontrolledAlert color="info" fade={true}>
              <span>
                Create a global goal, which will act as a default, before
                defining Product specific goals.
              </span>
            </UncontrolledAlert>
          ) : null}
        </Col>
      </Row>
    </>
  )
}

export default GoalsByProduct
